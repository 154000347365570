import React, { createContext } from "react";

export const ThemeContext = React.createContext({
  dark: false,
  toggleTheme: () => {},
});

export const PatientContext = React.createContext({
  patient: undefined,
});
