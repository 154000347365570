import React, { useContext } from "react";
import { ThemeContext } from "../../lib/main-context";
import { Bar } from "react-chartjs-2";
import theme from "../../index.scss";
import SdohChartOptions from "./SdohChartOptions";
import SdohNoStackedOptions from "./SdohNoStackedOptions";
import { parse, format } from "date-fns";
import getDaysAgo from "../../lib/getDaysAgo";
import fillInDates from "../../lib/fillInDates";

const SdohChart = ({ data, height, stacked }) => {
  const { dark } = useContext(ThemeContext);
  const colors = {
    wellness: theme.chart1,
    environmental: theme.chart2,
    "screen time": theme.chart3,
    travel: theme.chart4,
    other: theme.chart5,
  };

  let dataset = {
    wellness: [],
    environmental: [],
    "screen time": [],
    travel: [],
    other: [],
    none: [],
  };
  let sdohs = data.filter(
    ({ category, symptom_date }) =>
      category === "SDOH" && new Date(symptom_date) > getDaysAgo(7)
  );

  sdohs.forEach((sdoh) => {
    dataset[sdoh.subcategory].push(sdoh);
  });

  const dataForChart = {
    datasets: [],
  };

  const setDataForSubcategory = (subcategoryData) => {
    let DataSortByDate = {};
    subcategoryData.forEach((item) => {
      if (DataSortByDate[item.symptom_date]) {
        DataSortByDate[item.symptom_date].push(item.factor);
      } else {
        DataSortByDate[item.symptom_date] = [item.factor];
      }
    });

    let structuredData = [];
    for (let date in DataSortByDate) {
      const formattedDate = date.slice(0, 10);
      structuredData.push({
        x: formattedDate,
        y: DataSortByDate[date].length,
        detail: DataSortByDate[date],
      });
    }

    return structuredData;
  };

  for (let subcategory in dataset) {
    dataForChart.datasets.push({
      label: subcategory,
      data: setDataForSubcategory(dataset[subcategory]),
      backgroundColor: colors[subcategory],
    });
  }

  fillInDates(dataForChart.datasets, 7);

  SdohChartOptions.title.fontColor = dark ? theme.darkModeText : "#666";
  SdohChartOptions.legend = {
    labels: { fontColor: dark ? theme.darkModeText : "#666" },
  };
  SdohChartOptions.scales.xAxes[0].ticks = {
    fontColor: dark ? theme.darkModeText : "#666",
  };
  SdohChartOptions.scales.yAxes[0].ticks = {
    beginAtZero: true,
    stepSize: 1,
    fontColor: dark ? theme.darkModeText : "#666",
  };

  return (
    stacked == false ?
      <>
        <Bar
          data={dataForChart}
          height={height}
          options={{
            ...SdohNoStackedOptions,
          }}
        />
      </>
      : <>
        <Bar
          data={dataForChart}
          height={height}
          options={{
            ...SdohChartOptions,
          }}
        />
      </>
  );
};

export default SdohChart;
