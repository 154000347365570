import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import LabelledInput from "../LabelledInput";
import goalSchema from "./validations/goalSchema";
import SubmitButton from "../StyledComponents/SubmitButton";
import { updateGoals, getGoals } from "../../api/TBIRequests";

const SettingGoals = ({ setIsSuccess }) => {
  const { patient } = useContext(PatientContext);

  const [submitting, setSubmitting] = useState(false);
  const { errors, handleSubmit, register, setValue } = useForm({
    resolver: yupResolver(goalSchema),
  });

  useEffect(() => {
    setIsSuccess(undefined);
    getGoals(patient.patientId).then((result) => {
      setValue("firstGoal", result.data[0].first_goal);
      setValue("secondGoal", result.data[0].second_goal);
      setValue("thirdGoal", result.data[0].third_goal);
    });
  }, []);

  const onSubmit = (data) => {
    setSubmitting(true);
    updateGoals({
      patientId: patient.patientId,
      data,
    })
      .then((result) => {
        setIsSuccess(true);
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
        setSubmitting(false);
      });
  };

  return (
    <Form className="goalForm" onSubmit={handleSubmit(onSubmit)}>
      <Form.Label as="h6" style={{ width: "100%" }}>
        Please include a goal for your tracking:
      </Form.Label>
      <LabelledInput
        name="firstGoal"
        label="1st Goal"
        errors={errors}
        inputRef={register}
        autoFocus
      />
      <LabelledInput
        name="secondGoal"
        label="2nd Goal"
        errors={errors}
        inputRef={register}
      />
      <LabelledInput
        name="thirdGoal"
        label="3rd Goal"
        errors={errors}
        inputRef={register}
      />
      <SubmitButton disabled={submitting} type="submit">
        Save
      </SubmitButton>
    </Form>
  );
};

export default SettingGoals;
