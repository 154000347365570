import axios from "axios";
import apiUrl from "./apiConfig";

axios.defaults.withCredentials = true;

export const createTBI = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/tbi-incident/`, data, {
    params: { patientId },
  });
};

export const createStroke = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/stroke-incident1/`, data, {
    params: { patientId },
  });
};

export const updateTBIDetail = ({ data, patientId }) => {
  return axios.put(`${apiUrl}/record-builder/tbi-detail`, data, {
    params: { patientId },
  });
};

export const updateStrokeDetail = ({ data, patientId }) => {
  return axios.put(`${apiUrl}/record-builder/stroke-detail`, data, {
    params: { patientId },
  });
};

export const getTherapiesList = () => {
  return axios(`${apiUrl}/record-builder/available-therapies/`);
};

export const getTBISymptoms = () => {
  return axios(`${apiUrl}/record-builder/available-symptoms/`);
};

export const getTBIFormData = () => {
  return axios(`${apiUrl}/record-builder/tbi-form-fill/`);
};

export const postTrackingPrefs = ({ data }) => {
  return axios.post(
    `${apiUrl}/record-builder/symptom-tracking-preferences`,
    data
  );
};

//this route was combined from previously (postTopSymptoms, postTopSDOH)
export const postTrackedFactors = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/add-tracked-factors`, data, {
    params: { patientId },
  });
};

export const postMoreTrackedFactors = ({ data, patientId }) => {
  return axios.post(
    `${apiUrl}/record-builder/add-dashboard-tracked-factors`,
    data,
    {
      params: { patientId },
    }
  );
};

// Those users chose in the first place when registering
export const postFirstTrackedFactors = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/first-tracked-factors`, data, {
    params: { patientId },
  });
};

// Those users chose in the first place when registering
export const postRegisteredSdoh = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/registered-sdoh`, data, {
    params: { patientId },
  });
};
// export const postResultingFactors = ({ data, patientId }) => {
//   return axios.post(`${apiUrl}/record-builder/add-resulting-factors`, data, {
//     params: { patientId },
//   });
// };

export const postRegisterFactors = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/registered-factors`, data, {
    params: { patientId },
  });
};

export const postNewResultingFactors = ({ data, patientId }) => {
  return axios.post(
    `${apiUrl}/record-builder/add-new-resulting-factors`,
    data,
    {
      params: { patientId },
    }
  );
};

export const postPreviousTherapies = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/add-previous-therapies`, data, {
    params: { patientId },
  });
};

export const getTbiDate = (patientId) => {
  return axios(`${apiUrl}/getTbiDate`, { params: { patientId } });
};

export const postFeelingToday = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/feeling-today`, data, {
    params: { patientId },
  });
};

export const getTopFactors = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-users-top-factors`, {
    params: { patientId },
  });
};

export const getTrackedData = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-last-tracked-factors`, {
    params: { patientId },
  });
};

export const getLastTrackedFeelingToday = (patientId) => {
  return axios(`${apiUrl}/dashboard/last-tracked-feeling-today`, {
    params: { patientId },
  });
};

export const postTrackSymptom = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/symptom-tracking/`, data, {
    params: { patientId },
  });
};

export const getFirstTrackedData = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-first-tracked-factors`, {
    params: { patientId },
  });
};

export const removeTrackedFactors = ({ data, patientId }) => {
  console.log("databcbbx", data);
  return axios.delete(`${apiUrl}/dashboard/remove-tracked-factors`, {
    data: { data, params: { patientId } },
  });
};

export const getPausedList = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-paused-list`, {
    params: { patientId },
  });
};

export const postPausedList = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/post-paused-list`, data, {
    params: { patientId },
  });
};

export const postAdditionalNote = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/post-additional-note`, data, {
    params: { patientId },
  });
};

export const getAdditionalNote = (patientId) => {
  return axios.get(`${apiUrl}/dashboard/get-additional-note`, {
    params: { patientId },
  });
};

export const updateGoals = ({ data, patientId }) => {
  return axios.put(`${apiUrl}/record-builder/update-goals`, data, {
    params: { patientId },
  });
};

export const getGoals = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-goals`, { params: { patientId } });
};

export const createGoals = ({ patientId }) => {
  return axios.post(
    `${apiUrl}/record-builder/create-goals`,
    {},
    { params: { patientId } }
  );
};

export const addSymptomDetails = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/add-symptom-details`, data, {
    params: { patientId },
  });
};

export const getPatientTypes = () => {
  return axios(`${apiUrl}/record-builder/get-patient-types`);
};

export const getReferralGroups = () => {
  return axios(`${apiUrl}/record-builder/get-referral-groups`);
};

export const getProviderTypes = () => {
  return axios(`${apiUrl}/getProviderTypes`);
};

export const postHabitToday = (data, patientId) => {
  return axios.post(`${apiUrl}/dashboard/habit-today`, data, {
    params: { patientId },
  });
};

export const getHabitsToday = (patientId) => {
  return axios(`${apiUrl}/dashboard/habits`, {
    params: { patientId },
  });
};

export const addHabit = (patientId, habitName) => {
  return axios.post(
    `${apiUrl}/dashboard/add-habit`,
    { habitName: habitName }, // Send only habitName in the request body
    {
      params: { patientId }, // Send patientId as a query parameter
    }
  );
};

export const editHabit = (habitId, data, patientId) => {
  console.log('patient id::', patientId)
  return axios.put(
    `${apiUrl}/dashboard/edit-habit`,
    { habitId: habitId, habitName: data.habitName }, // Send habitId and habitName in the request body
    {
      params: { patientId },
    }
  );
};

export const postAdjustments = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/record-adjustments`, data, {
    params: { patientId },
  });
};

export const getHabitProgressData = (patientId) => {
  return axios.get(`${apiUrl}/dashboard/habit-progress`, {
    params: { patientId },
  });
};

export const softDeleteHabit = (habitId, patientId) => {
  return axios.put(`${apiUrl}/dashboard/soft-delete`, {
    habitId
  }, {
    params: { patientId },
  });
};

export const getAdjustments = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-adjustments`, {
    params: { patientId },
  });
};
