import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
import { Link } from "react-router-dom";
import ComponentWrapper from "./ComponentWrapper";
import { Row, Col } from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner";
import { getGoals } from "../../api/TBIRequests";
import EditIcon from "../../images/edit.svg";

const GoalList = ({ userType }) => {
  const { patient } = useContext(PatientContext);
  const [goals, setGoals] = useState(undefined);
  useEffect(() => {
    if (patient) {
      getGoals(patient.patientId)
        .then((result) => {
          setGoals(result.data[0]);
        })
        .catch((err) => console.log(err));
    }
  }, [patient]);

  return (
    <ComponentWrapper>
      {goals ? (
        <div>
          <Row>
            <Col md={4}>
              <h6>Goals</h6>
            </Col>
            {userType !== "provider" &&
              <Col md={{ span: 3, offset: 5 }}>
                <Link to="/accountSetting/setGoals">
                  <img src={EditIcon} alt="edit icon" className="editIcon" />
                  Edit
                </Link>
              </Col>
            }
          </Row>
          <div className="goalList">
            <Row className="goalItem">
              <Col sm={1}>1. </Col> <Col>{goals.first_goal}</Col>
            </Row>
            <Row className="goalItem">
              <Col sm={1}>2. </Col> <Col>{goals.second_goal}</Col>
            </Row>
            <Row className="goalItem">
              <Col sm={1}>3. </Col> <Col>{goals.third_goal}</Col>
            </Row>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </ComponentWrapper>
  );
};

export default GoalList;
