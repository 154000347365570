import React from "react"
import LinkButton from "./StyledComponents/LinkButton"

const PrivacyPage = ({ onClick, style }) => {
  return (
    <div style={style}>
      <h1>Privacy Policy</h1>
      <p>Last Updated: July 1, 2020</p>
      <p>
        This Privacy Policy to inform users of our policies and procedures
        regarding the collection, use, protection and disclosure of Personal
        Information received from your use of this website, located at
        https://powerofpatients.com (“Site”), as well as all related websites,
        applications, and other services provided by us (collectively, together
        with the Site, our “Service”) and in connection with our customer,
        vendor, and partner relationships. This Privacy Policy is incorporated
        into and is subject to the Power of Patients Terms of Use. This Privacy
        Policy also tells you about your rights and choices with respect to your
        Personal Information, and how you can reach us to update your contact
        information or get answers to questions you may have about our privacy
        practices.
      </p>
      <p>
        Registration with, use of, and access to the Service is subject to this
        Privacy Policy and our Terms of Use available upon request. All terms
        not defined in this Privacy Policy will have the meanings set forth in
        the Power of Patients Terms of Use.
      </p>
      <h3>PERSONAL INFORMATION WE MAY COLLECT</h3>
      <p>
        For the purpose of this Privacy Policy, “Personal Information” means any
        information relating to an identified or identifiable individual. We
        obtain Personal Information relating to you from various sources
        described below.{" "}
      </p>
      <p>
        Where applicable, we indicate whether and why you must provide us with
        your Personal Information, as well as the consequences of failing to do
        so. If you do not provide Personal Information when requested, you may
        not be able to benefit from our Service if that information is necessary
        to provide you with the service or if we are legally required to collect
        it.
      </p>
      <h3> PERSONAL INFORMATION PROVIDED BY YOU</h3>
      <h5>Registration</h5>
      <p>
        If you desire to have access to certain restricted sections of the Site
        or request to receive marketing materials, you may be required to become
        a registered user, and to submit the following types of Personal
        Information to Power of Patients your name, email address, cell phone
        number, full user name, password, and zip.
      </p>
      <h5>Customer Support</h5>
      <p>
        We may collect information through your communications with our customer
        support team or other communications that you may send us and their
        contents.
      </p>
      <h5>Other</h5>
      <p>
        We may also collect your contact details when you provide them in the
        context of our customer, vendor, and partner relationships.
      </p>
      <h5>
        Personal Information Automatically Obtained from Your Interactions with
        the Service
      </h5>
      <h5>Log Data</h5>
      <p>
        When you use our Service, our servers automatically record information
        that your browser sends whenever you visit a website (“Log Data”). This
        Log Data may include information such as your IP address, browser type
        or the domain from which you are visiting, the web-pages you visit, the
        search terms you use, and any advertisements on which you click.
      </p>
      <h5>Cookies and Similar Technologies</h5>
      <p>
        Like many websites, we also use “cookie” technology to collect
        additional website usage data and to improve the Site and our Service. A
        cookie is a small data file that we transfer to your computer’s hard
        disk. A session cookie enables certain features of the Site and our
        service and is deleted from your computer when you disconnect from or
        leave the Site. A persistent cookie remains after you close your browser
        and may be used by your browser on subsequent visits to the Site.
        Persistent cookies can be removed by following your web browser help
        file directions. Most Internet browsers automatically accept cookies.
        Power of Patients may use both session cookies and persistent cookies to
        better understand how you interact with the Site and our Service, to
        monitor aggregate usage by our users and web traffic routing on the
        Site, and to improve the Site and our Service. We may also automatically
        record certain information from your device by using various types of
        technology, including “clear gifs” or “web beacons.” This automatically
        collected information may include your IP address or other device
        address or ID, web browser and/or device type, the web pages or sites
        that you visit just before or just after you use the Service, the pages
        or other content you view or otherwise interact with on the Service, and
        the dates and times that you visit, access, or use the Service. We also
        may use these technologies to collect information regarding your
        interaction with email messages, such as whether you opened, clicked on,
        or forwarded a message, to the extent permitted under applicable law.
      </p>
      <p>
        You can instruct your browser, by editing its options, to stop accepting
        cookies or to prompt you before accepting a cookie from the websites you
        visit. Please note that if you delete, or choose not to accept, cookies
        from the Service, you may not be able to utilize the features of the
        Service to their fullest potential.
      </p>
      <h5>Third Party Web Beacons and Third-Party Buttons</h5>
      <p>
        We may display third-party content on the Service, including third-party
        advertising. Third-party content may use cookies, web beacons, or other
        mechanisms for obtaining data in connection with your viewing of the
        third-party content on the Service. Additionally, we may implement third
        party buttons, such as Facebook “share” buttons, that may function as
        web beacons even when you do not interact with the button. Information
        collected through third-party web beacons and buttons is collected
        directly by these third parties, not by Power of Patients. Please
        consult such third party’s data collection, use, and disclosure policies
        for more information.
      </p>
      <h5>Links to Other Websites</h5>
      <p>
        Our Site contains links to other websites. The fact that we link to a
        website is not an endorsement, authorization, or representation of our
        affiliation with that third party. We do not exercise control over third
        party websites. These other websites may place their own cookies or
        other files on your computer, collect data or solicit Personal
        Information from you. Other sites follow different rules regarding the
        use or disclosure of the Personal Information you submit to them. We are
        not responsible for the content, privacy and security practices, and
        policies of third-party sites or services to which links or access are
        provided through the Service. We encourage you to read the privacy
        policies or statements of the other websites you visit.
      </p>
      <h3>HOW WE MAY USE YOUR PERSONAL INFORMATION</h3>
      <p>We may use the Personal Information we obtain about you to:</p>
      <ul>
        <li>communicate with you about your trending health conditions</li>
        <li>
          communicate with you to verify your account and for informational and
          operational purposes, such as account management, customer service, or
          system maintenance, including by periodically emailing you
          service-related announcements;
        </li>
        <li>
          tailor our Service (e.g., we may use cookies and similar technologies
          to remember your preferences);
        </li>
        <li>
          publish your content, comments or messages on internal networks for
          learning purposes and social media platforms;
        </li>
        <li>aggregate your Personal Information for analytical purposes;</li>
        <li>provide customer support;</li>
        <li>
          operate, evaluate and improve our business (including by developing
          new products and services; managing our communications; determining
          the effectiveness of our advertising; analyzing how the Service is
          being accessed and used; tracking performance of the Service;
          debugging the Service; facilitating the use of our Service);
        </li>
        <li>
          send you marketing communications about products, services, offers,
          programs and promotions of Power of Patients, and affiliated
          companies;
        </li>
        <li>ensure the security of our Service;</li>
        <li>
          manage our customer, service provider and partner relationships;
        </li>
        <li>
          enforce our agreements related to our Service and our other legal
          rights; and
        </li>
        <li>
          comply with applicable legal requirements, industry standards and our
          policies.
        </li>
      </ul>
      <p>
        If you are located in the European Economic Area, we may process your
        Personal Information for the above purposes when:
      </p>
      <ul>
        <li>
          you have consented to the use of your Personal Information, For
          example, we may seek to obtain your consent to send you marketing
          communications.
        </li>
        <li>
          we need your Personal Information to provide you with services and
          products requested by you, or to respond to your inquiries,
        </li>
        <li>we have a legal obligation to use your Personal Information, or</li>
        <li>
          we have a legitimate interest in using your Personal Information. In
          particular, we have a legitimate interest in using your Personal
          Information to ensure and improve the safety, security, and
          performance of our Service, to anonymize Personal Information and
          carry out data analyses.
        </li>
      </ul>
      <h3>HOW WE SHARE YOUR PERSONAL INFORMATION</h3>
      <p>
        We may disclose the Personal Information we collect about you as
        described below or otherwise disclosed to you at the time the data is
        collected, including with:
      </p>
      <h5>Health Care Platforms</h5>
      <p>
        We may share your data with physicians, therapist, and any other health
        care provider you request us to send your reports to. Data will be
        shared in a reporting format and not individual records. Additionally,
        we will use your information for aggregated purposes to offer data
        analysis techniques for research purposes.
      </p>
      <h5>Service Providers</h5>
      <p>
        We engage certain trusted third parties to perform functions and provide
        services to us, including hosting and maintenance, error monitoring,
        debugging, performance monitoring, billing, customer relationship,
        database storage and management, and direct marketing campaigns. We may
        share your Personal Information with these third parties, but only to
        the extent necessary to perform these functions and provide such
        services. We also require these third parties to maintain the privacy
        and security of the Personal Information they process on our behalf.
      </p>
      <h5>Compliance with Laws and Law Enforcement</h5>
      <p>
        Power of Patients cooperates with government and law enforcement
        officials or private parties to enforce and comply with the law. To the
        extent permitted under applicable law, we may disclose any information
        about you to government or law enforcement officials or private parties
        as we believe is necessary or appropriate to investigate, respond to,
        and defend against claims, for legal process (including subpoenas), to
        protect the property and rights of Power of Patients or a third party,
        to protect Power of Patients against liability, for the safety of the
        public or any person, to prevent or stop any illegal, unethical,
        fraudulent, abusive, or legally actionable activity, to protect the
        security or integrity of the Service and any equipment used to make the
        Service available, or to comply with the law.
      </p>
      <h5>Business Transfers</h5>
      <p>
        Power of Patients may sell, transfer, or otherwise share some or all of
        its assets, including Personal Information, in connection with a merger,
        acquisition, reorganization, sale of assets, or similar transaction, or
        in the event of insolvency or bankruptcy. You will have the opportunity
        to opt out of any such transfer if the new entity’s planned processing
        of your information differs materially from that set forth in this
        Privacy Policy.
      </p>
      <h5>Other Third Parties</h5>
      <p>
        We may share Personal Information with our headquarters and affiliates,
        and business partners to whom it is reasonably necessary or desirable
        for us to disclose your data for the purposes described in this Privacy
        Policy. We may also make certain non-Personal Information available to
        third parties for various purposes, including for business or marketing
        purposes or to assist third parties in understanding our users’
        interest, habits, and usage patterns for certain programs, content,
        services, advertisements, promotions, and functionality available
        through the Service.
      </p>
      <h3>HOW WE PROTECT YOUR PERSONAL INFORMATION</h3>
      <p>
        Power of Patients is concerned about safeguarding the confidentiality of
        your Personal Information. We employ administrative and electronic
        measures designed to appropriately protect your Personal Information
        against accidental or unlawful destruction, accidental loss,
        unauthorized alteration, unauthorized disclosure or access, misuse, and
        any other unlawful form of processing of the Personal Information in our
        possession. Please be aware that no security measures are perfect or
        impenetrable. We cannot guarantee that information about you will not be
        accessed, viewed, disclosed, altered, or destroyed by breach of any of
        our administrative, physical, and electronic safeguards, subject to
        requirements under applicable law to ensure or warrant information
        security.
      </p>
      <p>
        We will make any legally-required disclosures of any breach of the
        security, confidentiality, or integrity of your unencrypted
        electronically stored Personal Information to you via email or
        conspicuous posting on our Site in the most expedient time possible and
        without unreasonable delay, consistent with (i) the legitimate needs of
        law enforcement or (ii) any measures necessary to determine the scope of
        the breach and restore the reasonable integrity of the data system , and
        any other disclosures that may be required under applicable law.
      </p>
      <p>
        We also take measures to delete your Personal Information or keep it in
        a form that does not permit identifying you when this information is no
        longer necessary for the purposes for which we process it, unless we are
        required by law to keep this information for a longer period. When
        determining the retention period, we take into account various criteria,
        such as the type of products and services requested by or provided to
        you, the nature and length of our relationship with you, possible
        re-enrollment with our products or services, the impact on the services
        we provide to you if we delete some information from or about you,
        mandatory retention periods provided by law and the statute of
        limitations.
      </p>
      <h3>YOUR RIGHTS AND CHOICES</h3>
      <p>
        If you decide at any time that you no longer wish to receive such
        marketing communications from us, please follow the unsubscribe
        instructions provided in any of the communications. You may also opt out
        from receiving commercial email from us by sending your request to us by
        email at help@powerofpatients.com. Please be aware that, even after you
        opt out from receiving commercial messages from us, you will continue to
        receive administrative messages from us regarding the Service.
      </p>
      <p>
        In certain jurisdictions you have the right to request access and
        receive information about the Personal Information we maintain about
        you, to update and correct inaccuracies in your Personal Information, to
        restrict or object to the processing of your Personal Information, to
        have the information blocked, anonymized or deleted, as appropriate, or
        to exercise your right to data portability to transfer your Personal
        Information to another company. Those rights may be limited in some
        circumstances by local law requirements.
      </p>
      <p>
        Where required by law, we obtain your consent for the processing of
        certain Personal Information collected by cookies or similar
        technologies, or used to send you direct marketing communications, or
        when we carry out other processing activities for which consent may be
        required. If we rely on consent for the processing of your Personal
        Information, you have the right to withdraw it at any time and free of
        charge. When you do so, this will not affect the lawfulness of the
        processing before your consent withdrawal.
      </p>
      <p>
        To update your preferences, ask us to remove your information from our
        mailing lists, delete your account or submit a request to exercise your
        rights under applicable law, please contact us at
        help@powerofpatients.com.
      </p>
      <h3>DATA TRANSFERS</h3>
      <p>
        Personal Information that we collect may be transferred to, and stored
        at, any of our affiliates, partners or service providers which may be
        inside or outside the European Economic Area (“EEA”) and Switzerland,
        including the United States. By submitting your personal data, you agree
        to such transfers. Your Personal Information may be transferred to
        countries that do not have the same data protection laws as the country
        in which you initially provided the information. When we transfer or
        disclose your Personal Information to other countries, we will protect
        that information as described in this Privacy Policy.
      </p>
      <h3>CHILDREN’S PRIVACY</h3>
      <p>
        The Site is not directed to persons under 13. If a parent or guardian
        becomes aware that his or her child has provided us with Personal
        Information without their consent, he or she should contact us at
        help@powerofpatients.com. We do not knowingly collect Personal
        Information from children under 13. If we become aware that a child
        under 13 has provided us with Personal Information, we will delete such
        information from our files.
      </p>
      <h3>UPDATES TO THIS PRIVACY POLICY</h3>
      <p>
        This Privacy Policy may be updated from time to time for any reason.
        Each version will apply to information collected while it was in place.
        We will notify you of any modifications to our Privacy Policy by posting
        the new Privacy Policy on our Site and indicating the date of the latest
        revision. You are advised to consult this Privacy Policy regularly for
        any changes.
      </p>
      <p>
        In the event that the modifications materially alter your rights or
        obligations hereunder, we will make reasonable efforts to notify you of
        the change. For example, we may send a message to your email address or
        generate a pop-up or similar notification when you access the Service
        for the first time after such material changes are made. Your continued
        use of the Service after the revised Privacy Policy has become effective
        indicates that you have read, understood and agreed to the current
        version of this Privacy Policy.
      </p>
      <h3>HOW TO CONTACT US</h3>
      <p>
        Power of Patients, LLC is the entity responsible for the processing of
        your Personal Information. If you have any questions or comments
        regarding this Privacy Policy, or if you would like to exercise your
        rights to your Personal Information, you may contact us by emailing us
        at help@powerofpatients.com.
      </p>
      <LinkButton onClick={onClick}>Go Back</LinkButton>
    </div>
  )
}

export default PrivacyPage
