import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
// a rounded bootstrap button with extra margin

const PillButton = styled(Button)`
  border-radius: 20px;
  background: #106995;// primary color
  margin: 0.1rem ${props => props.autoMargin ? "auto" : "0.25rem"};
`
export default PillButton;
