import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { Row, Col, Nav } from "react-bootstrap/";
import Sallie from "../Sallie";
import ChangeNotification from "./ChangeNotification";
import SettingGoals from "./SettingGoals";

const AccountSetting = () => {
  let { path, url } = useRouteMatch();
  const location = useLocation();
  let optionName = location.pathname.slice(16);
  const [sallieText, setSallieText] = useState("");
  const [isSuccess, setIsSuccess] = useState(undefined);

  useEffect(() => {
    if (isSuccess) {
      setSallieText("The change has saved successfully!");
    } else if (isSuccess === false) {
      setSallieText(
        "Oops! Something went wrong and we couldn't save your change. Please try agian."
      );
    } else if (optionName === "ChangeNotification" && isSuccess === undefined) {
      setSallieText(
        "Would you like to change the reminder time to track your symptom?"
      );
    } else if (optionName === "setGoals" && isSuccess === undefined) {
      setSallieText("Why do you want to track your symptoms?");
    } else {
      setSallieText(
        "Change your account settings here! Click on One of the links in the box below."
      );
    }
  }, [isSuccess, optionName]);

  return (
    <Row>
      <Col>
        <Sallie text={sallieText} />
        <Row className="d-flex justify-content-around">
          <Col md={3} className="accountSettingNav">
            <Nav variant="pills" className="flex-column">
              <Nav.Link
                eventKey={`${url}/ChangeNotification`}
                as={NavLink}
                to={`${url}/ChangeNotification`}
              >
                Notification Preference
              </Nav.Link>
              <Nav.Link
                eventKey={`${url}/setGoals`}
                as={NavLink}
                to={`${url}/setGoals`}
              >
                Setting Goals
              </Nav.Link>
            </Nav>
          </Col>
          <Col
            md={8}
            className="d-flex justify-content-center align-items-center"
          >
            <Switch>
              {/* <Route exact path={path}>
                <p>Please select option.</p>
              </Route> */}
              <Route path={`${path}/ChangeNotification`}>
                <ChangeNotification setIsSuccess={setIsSuccess} />
              </Route>
              <Route path={`${path}/setGoals`}>
                <SettingGoals setIsSuccess={setIsSuccess} />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AccountSetting;
