import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sallie from "../Sallie";
import SubmitButton from "../StyledComponents/SubmitButton";
import futureView from "../../images/FutureView.jpg";

const Register = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/BasicInfo");
  };

  return (
    <Row>
      <Col md={6} className="order-last order-md-first">
        <Sallie text="Hi, I'm Sallie! Welcome to Power of Patients!" />
        <p>
          Power of Patients is designed to empower you, the chiropractic
          patient.
        </p>
        <p>
          We want to help you tell your story, track your symptoms, reach your
          functional goals, and achieve your version of ultimate health.
        </p>

        <h6>Lets get to know you!</h6>
        <p>There are three steps to set up your account</p>

        <ol style={{ listStylePosition: "inside", paddingLeft: 0 }}>
          <li>Create an account</li>
          <li>Fill in your health history</li>
          <li>Set up your dashboard</li>
        </ol>
        <Row>
          <Col className="justify-content-center d-flex justify-content-md-start">
            <SubmitButton type="button" onClick={handleClick}>
              Start &gt;
            </SubmitButton>
          </Col>
        </Row>
      </Col>
      <Col md={6} className="order-first order-md-last">
        <div>
          <h6 className="text-center">Here&apos;s your future dashboard!</h6>
          <img className="w-100" src={futureView} alt="future view" />
        </div>
      </Col>
    </Row>
  );
};

export default Register;
