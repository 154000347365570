import React, { useEffect, useState } from "react";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { deleteMessage, getMessages, updateMessageRead } from "../api/messageRequests";
import LoadingSpinner from "./LoadingSpinner";
import SubmitButton from "./StyledComponents/SubmitButton";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useWindowSize from "../lib/useWindowSize";
import SendMessage from "./DashboardComponents/SendMessage";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from "react-router-dom";

const Messages = ({ user, messages, setMessages, loadingMessages }) => {
    const location = useLocation();
    let messagesForCurPatient = messages;
    let receiver = [];
    if (user.userType != "Chiropractic Patient") {
        messagesForCurPatient = messages?.filter((m) => m.otherUserFirstName.toLowerCase() == location.state.curPatient.firstName.toLowerCase() && m.otherUserLastName.toLowerCase() == location.state.curPatient.lastName.toLowerCase());
        receiver = user.messageRecipient?.filter((u) => u.first_name.toLowerCase() == location.state.curPatient.firstName.toLowerCase() && u.last_name.toLowerCase() == location.state.curPatient.lastName.toLowerCase())
    }

    const tenMessages = messagesForCurPatient?.slice(0, 10);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const history = useHistory();
    const [width] = useWindowSize();
    const [messageSender, setMessageSender] = useState(null)




    const setMessageAsRead = ({ messageId }) => {
        updateMessageRead({ messageId })
            .then(() => {
                setMessages((originalMessages) => {
                    // Clone the array
                    const newMessages = JSON.parse(JSON.stringify(originalMessages));
                    const messageIndex = originalMessages.findIndex((message) => message.messageId === messageId);
                    newMessages[messageIndex].hasBeenRead = true;
                    return newMessages;
                });
            })
            .catch((err) => {
                console.error(err);
                history.push("/oops");
            });
    };

    // const opendialogue = () => {
    //     user.messageRecipient ?
    //       <SendMessage
    //           messageRecipient={user.messageRecipient}
    //           messageRecipientType={user.userType === "caregiver" && user.messageRecipientType}
    //           userType={user.userType}
    //           fillColumn={true}
    //           loadMessages={loadingMessages}
    //       /> :
    //       null;
    // }

    // Underscore just to distinguish this from the function that is the actual API call
    // const _deleteMessage = ({ messageId }) => {
    //     deleteMessage({ messageId })
    //         .then(() => {
    //             setMessages((originalMessages) => {
    //                 // Clone the array
    //                 const newMessages = JSON.parse(JSON.stringify(originalMessages));
    //                 const messageIndex = newMessages.findIndex((message) => message.messageId === messageId);
    //                 // Remember splice modifies the array IN PLACE
    //                 newMessages.splice(messageIndex, 1);
    //                 return newMessages;
    //             })
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //             history.push("/oops");
    //         })
    // }


    let messagesDisplay;

    const otherUserName = message => message.otherUserFirstName + " " + message.otherUserLastName;

    // https://stackoverflow.com/questions/4833651/javascript-array-sort-and-unique
    // (Slightly modified)

    function sortUnique(arr) {
        if (arr.length === 0) return arr;
        arr = arr.sort();
        var ret = [arr[0]];
        for (var i = 1; i < arr.length; i++) { //Start loop at 1: arr[0] can never be a duplicate
            // console.log(arr[i - 1]);
            // console.log(arr[i])
            if (arr[i - 1] !== arr[i]) {
                ret.push(arr[i]);
            }
        }
        return ret;
    }

    if (width >= 768) {
        messagesDisplay = (
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Sent or Received</th>
                        <th>Other User</th>
                        <th>Message</th>
                        <th>Time</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="font-weight-normal">
                    {tenMessages && tenMessages.map((message) => (
                        <tr className={!message.hasBeenRead ? "table-info font-weight-bold" : ""}>
                            <td>{message.sentOrReceived === "sent" ? "Sent" : "Received"}</td>
                            <td>{otherUserName(message)}</td>
                            <td>{message.body}</td>
                            <td>{message.date}</td>
                            <td>
                                {!message.hasBeenRead &&
                                    <SubmitButton centered onClick={() => {
                                        setMessageAsRead({ messageId: message.messageId });
                                    }}>
                                        Mark as read
                                </SubmitButton>
                                }
                            </td>
                            <td>
                                {message.sentOrReceived === "received" && (
                                    // <SubmitButton centered onClick={() => {
                                    //     // _deleteMessage({ messageId: message.messageId });
                                    // }}>
                                    //     Send Message To HCP
                                    // </SubmitButton>
                                    <SubmitButton centered onClick={handleShow}>
                                        Send Message
                                    </SubmitButton>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    } else {
        messagesDisplay = tenMessages && tenMessages.map((message) => (
            <Row className="font-weight-normal">
                <Col className={`mb-3 border border-dark rounded${!message.hasBeenRead ? " table-info font-weight-bold" : ""}`}>
                    <h4 className="text-center">{otherUserName(message)}</h4>
                    <h4 className="text-center">{message.sentOrReceived === "sent" ? "Sent" : "Received"}</h4>
                    <h4 className="text-center">{message.date}</h4>
                    <p>{message.body}</p>
                    {!message.hasBeenRead &&
                        <SubmitButton centered onClick={() => {
                            setMessageAsRead({ messageId: message.messageId });
                        }}>
                            Mark as read
                        </SubmitButton>
                    }
                    {message.sentOrReceived === "received" && (
                        // <SubmitButton centered onClick={() => {
                        //     _deleteMessage({ messageId: message.messageId });
                        // }}>

                        <SubmitButton centered onClick={handleShow}>
                            Send Message
                        </SubmitButton>
                    )}
                </Col>
            </Row>
        ));
    }

    return (
        <ComponentWrapper style={{ padding: "60px" }}>
            <h1 className="text-center">Messages</h1>
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {user.messageRecipient ?
                                <SendMessage
                                    messageRecipient={user.userType === "Chiropractic Patient" ? user.messageRecipient : receiver}
                                    messageRecipientType={user.userType === "caregiver" && user.messageRecipientType}
                                    userType={user.userType}
                                    fillColumn={true}
                                    loadMessages={loadingMessages}
                                /> : null}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            <Row>
                <Col className={`d-flex flex-wrap ${width >= 768 ? "justify-content-start" : "justify-content-center"} text-center`}>
                    <SubmitButton onClick={() => history.push("/")}>
                        Go back to dashboard
                    </SubmitButton>
                </Col>
            </Row>
            {/* {!messageSender && sortUnique(tenMessages.map(otherUserName)).map((userName) => (
                <Row>
                    <Col className={`d-flex flex-wrap ${width >= 768 ? "justify-content-start" : "justify-content-center"} text-center`}>
                        <SubmitButton centered onClick={() => {
                            setMessageSender(userName);
                        }}
                        >
                            View messages with {userName}
                        </SubmitButton>
                    </Col>
                </Row>
            ))
            } */}
            {messagesDisplay}
            {/* {messageSender && loadingMessages && <LoadingSpinner />}
            {messageSender && !loadingMessages && (
                <>
                    <Row>
                        <Col className={`d-flex flex-wrap ${width >= 768 ? "justify-content-start" : "justify-content-center"} text-center`}>
                            <SubmitButton centered onClick={() => {
                                setMessageSender(null);
                            }}
                            >
                                View messages with another sender
                    </SubmitButton>
                        </Col>
                    </Row>

                    {messagesDisplay}
                </>
            )} */}
        </ComponentWrapper>
    )
}

export default Messages;