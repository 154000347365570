import * as yup from "yup";

const NotificationSchema = yup
  .object()
  .shape({
    emailAlert: yup.boolean().required("Email must not be blank"),
    hour: yup
      .number()
      .required("Please enter the hour")
      .max(12, "Please enter a valid time")
      .min(1, "Please enter a valid time"),
    monday: yup.boolean(),
    tuesday: yup.boolean(),
    wednesday: yup.boolean(),
    thursday: yup.boolean(),
    friday: yup.boolean(),
    saturday: yup.boolean(),
    sunday: yup.boolean(),
  })
  .test("dayOfWeekTest", null, (obj) => {
    if (obj.emailAlert) {
      if (
        !obj.monday &&
        !obj.tuesday &&
        !obj.wednesday &&
        !obj.thursday &&
        !obj.friday &&
        !obj.saturday &&
        !obj.sunday
      ) {
        return new yup.ValidationError(
          "Please check at least one checkbox for day of the week",
          null,
          "dayofweek"
        );
      } else {
        return true;
      }
    } else {
      return true;
    }
  });

export default NotificationSchema;
