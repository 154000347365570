import { addDays, parse, isEqual } from "date-fns";
import forIn from "lodash/forIn";
import getDaysAgo from "./getDaysAgo";

const fillInDays = (datasets, days) => {
  const newData = [...datasets];
  const firstDay = getDaysAgo(days - 1);
  const filledData = new Array(days).fill("").map((_, index) => {
    return addDays(firstDay, index);
  });

  datasets.forEach((dataset, index) => {
    const dateMap = {};
    filledData.forEach((date) => {
      dateMap[date] = [];
    });

    const chartData = [];

    for (let i = 0; i < days; i++) {
      const filteredData = dataset.data.filter((datapoint) => {
        const parsedDate = parse(datapoint.x, "yyyy-MM-dd", new Date());
        return isEqual(parsedDate, filledData[i]);
      });
      if (filteredData.length) {
        dateMap[filledData[i]] = filteredData;
      } else {
        dateMap[filledData[i]] = null;
      }
    }
    forIn(dateMap, (value, key) => {
      if (value === null) {
        chartData.push({ x: key, y: null });
      } else {
        value.forEach((item) => {
          chartData.push({ x: key, y: item });
        });
      }
    });
    newData[index].data = chartData;
  });
  return newData;
};

export default fillInDays;
