import React, { useState, useContext } from "react";
import { Modal, Form } from "react-bootstrap";
import { PatientContext } from "../../lib/main-context";
import SubmitButton from "../StyledComponents/SubmitButton";
import exportToPdf from "../../lib/exportToPdf";

const ModalPdf = ({
  show,
  handleClose,
  chart,
  data,
  additionalNotes,
  days,
}) => {
  const [withoutNote, setWithoutNote] = useState(false);
  const { patient } = useContext(PatientContext);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Export report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your {days} days report will be exported in PDF format including all
          the symptom notes.
        </p>
        <Form.Check
          name="withoutNote"
          type="checkbox"
          label="exclude symptom notes"
          checked={withoutNote}
          style={{ fontWeight: "400", fontSize: "small" }}
          onChange={() => setWithoutNote(!withoutNote)}
        />
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          onClick={() => {
            exportToPdf(
              chart,
              data,
              days,
              additionalNotes,
              patient,
              withoutNote
            );
            handleClose();
          }}
        >
          Export
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPdf;
