import React, { useContext } from "react";
import { ThemeContext } from "../../lib/main-context";

import Logo from "../../images/life-university-logo-green.png";
import LogoWhite from "../../images/life-university-logo-green.png";

const Brand = () => {
  const { dark } = useContext(ThemeContext);

  return (
    <div className="brand-wrapper">
      <img
        src={dark ? LogoWhite : Logo}
        className="logo"
        alt="Power of Patients brand-img"
      />
    </div>
  );
};

export default Brand;
