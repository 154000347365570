import React, { useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";
import ComponentWrapper from "./ComponentWrapper";

import FeelingGood from "../../images/feeling-good.svg";
import FeelingNeutral from "../../images/feeling-neutral.svg";
import FeelingBad from "../../images/feeling-bad.svg";
import { postFeelingToday } from "../../api/TBIRequests";

const FeelingToday = ({ setDoneSimpleTracking }) => {
  const { patient } = useContext(PatientContext);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const date = new Date();

  const submitFeeling = (num) => {
    setSubmitting(true);
    const feelingData = {
      severity: num,
      date: date,
    };
    postFeelingToday({
      patientId: patient.patientId,
      data: feelingData,
    })
      .then(() => {
        setDoneSimpleTracking(true);
        setError(false);
        setSubmitting(false);
      })
      .catch(() => {
        setError(true);
        setSubmitting(false);
      });
  };

  const handleKeyPress = (e, num) => {
    if (e.key === "Enter" || e.key === " ") {
      submitFeeling(num);
    }
  };

  const imgStyle = { cursor: "pointer" };

  return submitting ? (
    <ComponentWrapper>Submitting....</ComponentWrapper>
  ) : (
    <ComponentWrapper>
      <Row>
        <Col md={6} className="d-flex  text-center align-items-lg-center">
          {error && (
            <>
              <div>
                Sorry, something went wrong. Please try again or click{" "}
                <Link to="/help">here</Link> to request help
              </div>
            </>
          )}
          <div className="ml-2">
            How is{" "}
            {/* {patient &&
              (patient.userPatientRelationshipType === "self"
                ? "are you"
                : "is " + patient.firstName)}{" "} */}
            {patient && patient.firstName}{" "}
            doing today?
          </div>
        </Col>
        <Col xs={4} md={2}>
          {/* role, tabindex, and keypress are to preserve accessibility */}
          <div
            role="button"
            tabIndex="0"
            className="text-center"
            onKeyPress={(e) => handleKeyPress(e, -1)}
            onClick={() => submitFeeling(10)}
          >
            <img src={FeelingBad} alt="sad face" style={imgStyle} />
            <div>Bad</div>
          </div>
        </Col>
        <Col xs={4} md={2}>
          <div
            role="button"
            tabIndex="0"
            className="text-center"
            onClick={() => submitFeeling(50)}
            onKeyPress={(e) => handleKeyPress(e, 0)}
          >
            <img src={FeelingNeutral} alt="neutral face" style={imgStyle} />
            <div>Neutral</div>
          </div>
        </Col>
        <Col xs={4} md={2}>
          <div
            role="button"
            tabIndex="0"
            className="text-center"
            onClick={() => submitFeeling(100)}
            onKeyPress={(e) => handleKeyPress(e, 0)}
          >
            <img src={FeelingGood} style={imgStyle} alt="happy face" />
            <div>Good</div>
          </div>
        </Col>
      </Row>
    </ComponentWrapper>
  );
};

export default FeelingToday;
