import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getYoutubeVideos } from "../api/helpReq";
import { Row, Col, Container } from "react-bootstrap/";
import Sallie from "./Sallie";
import useWindowSize from "../lib/useWindowSize";
import SubmitButton from "./StyledComponents/SubmitButton";
import splitVideoGroup from "../lib/splitVideoGroup";

const MediaPage = () => {
  const [width] = useWindowSize();
  let SallieText =
    "Below is a selection of videos for you to educate yourself about TBI's";
  const history = useHistory();
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    getYoutubeVideos().then((res) => {
      const splitedVideos = splitVideoGroup(res.items);
      setVideos(splitedVideos);
    });
  }, []);

  const youtubeVideoFormat = (videoId, size, title, description) => {
    let width = size === "sm" ? "300" : "380";
    let height = size === "sm" ? "180" : "230";
    return (
      <>
        <iframe
          width={width}
          height={height}
          src={"https://www.youtube.com/embed/" + videoId}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <span>
          <h5>{title}</h5>
          <p
            style={{
              overflow: "hidden",
              width: width + "px",
              height: "50px",
            }}
          >
            {description}
          </p>
        </span>
      </>
    );
  };

  return (
    <>
      <Col>
        <Sallie text={SallieText} />
      </Col>
      <Col>
        <h4 className="mt-4 mb-4">Media</h4>
      </Col>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={width >= 1000 ? 5 : 6}>
            {videos.length > 0 && videos[0].length > 0 ? (
              videos[0].map((video) => {
                return youtubeVideoFormat(
                  video.id.videoId,
                  width >= 1000 ? "md" : "sm",
                  video.snippet.title,
                  video.snippet.description
                );
              })
            ) : (
              <>
                {youtubeVideoFormat(
                  "sMb3Ad8IUwU",
                  width >= 1000 ? "md" : "sm",
                  "Power of Patients VT Webinar 2 of 3",
                  "Power of Patients presents Take 2 Replay of Vision therapy series 2 of 3"
                )}

                {youtubeVideoFormat(
                  "IPsQY8CEX54",
                  width >= 1000 ? "md" : "sm",
                  "TBI & PCS",
                  "Power of Patients addresses Post Concussive Syndrome, the risks, the triggers and how to track these symptoms on their dashboard."
                )}
                {youtubeVideoFormat(
                  "1gi9RRGbybU",
                  width >= 1000 ? "md" : "sm",
                  "Power of Patients Jul15 2020",
                  "Power of Patients - Take 2 presents TBI & The Vagus Nerve"
                )}
                {youtubeVideoFormat(
                  "kK0kdgU3ws4",
                  width >= 1000 ? "md" : "sm",
                  "Three important questions a clinical trial participant needs to know about Covid-19",
                  "What is COVID-19 doing to clinical trials. Three important questions a clinical trial subject should have with their study coordinator."
                )}
              </>
            )}
          </Col>

          <Col md={width >= 1000 ? 5 : 6}>
            {videos.length > 0 && videos[1].length > 0 ? (
              videos[1].map((video) => {
                return youtubeVideoFormat(
                  video.id.videoId,
                  width >= 1000 ? "md" : "sm",
                  video.snippet.title,
                  video.snippet.description
                );
              })
            ) : (
              <>
                {youtubeVideoFormat(
                  "pqnG68YdgsI",
                  width >= 1000 ? "md" : "sm",
                  "Neuro Optometry webinar 1 of 3",
                  "Power of Patients hosted a Neuro-Optometry webinar. The first of 3 . Our guest speaker was Dr Almouradi."
                )}
                {youtubeVideoFormat(
                  "wS0zkMmYgcA",
                  width >= 1000 ? "md" : "sm",
                  "Power of Patients Dashboard Demo",
                  "Sep 23rd 2020 webinar demonstrating how to log in and use the dashboard"
                )}
                {youtubeVideoFormat(
                  "ucYTiqxpYG8",
                  width >= 1000 ? "md" : "sm",
                  "Clinical Trials 5min",
                  ""
                )}
                {youtubeVideoFormat(
                  "2nEqKcSNfJ0",
                  width >= 1000 ? "md" : "sm",
                  "Clinical Trials",
                  "This video is an introductory session about clinical trials"
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <SubmitButton onClick={() => history.push("/")}>Go Back</SubmitButton>
    </>
  );
};

export default MediaPage;
