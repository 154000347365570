/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
import { useHistory, useLocation } from "react-router-dom";

import forIn from "lodash/forIn";

import { Row, Col, Form } from "react-bootstrap";
import Sallie from "../Sallie";
import ListSelector from "../ListSelector";
import useListSelector from "../../lib/useListSelector";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";

import { postTrackedFactors, postFirstTrackedFactors } from "../../api/TBIRequests";
import customizeMessages from "../../lib/customizeMessages";

const SelectTopSymptoms = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);

  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [noSymptom, setNoSymptom] = useState(false);
  const { forwardData } = location.state || { forwardData: undefined };

  const [returnedData, setReturnedData] = useState([]);

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const createItemList = () => {
    const items = [];
    forIn(forwardData.selectedData, (value) => {
      value.forEach((item) => items.push(item));
    });
    const itemList = [
      {
        category: "data",
        items,
        maxSelectAmount: "3",
      },
    ];
    return itemList;
  };

  const processDataForSending = () => {
    // processedData is laterSymptoms formatted for sending
    return forwardData.processedData
      .filter((item) =>
        Object.values(selectedData).flat().includes(item.factor)
      )
      .map((item) => ({
        factor: item.factor,
        category: item.category,
        subcategory: item.subcategory,
      }));
  };

  useEffect(() => {
    if (!forwardData) {
      // api call, right now user must do onboarding in one full swoop
    } else {
      const itemList = createItemList();
      initialize(itemList);
      setReturnedData(itemList);
    }
    setLoading(false);
    location.state.fromPatientControl
      ? setOnboardingPercent(80)
      : setOnboardingPercent(50);
  }, [forwardData]);

  const handleSubmit = () => {
    setSubmitting(true);
    const mappedData = processDataForSending();
    if (mappedData.length === 0) {
      setSubmitting(false);
      setNoSymptom(true);
      return;
    }

    postFirstTrackedFactors({
      patientId: patient.patientId,
      data: mappedData,
    }).catch(() => history.push("/oops"));


    postTrackedFactors({
      patientId: patient.patientId,
      data: mappedData,
    })
      .then(() =>
        history.push("/selectSDOH", {
          fromPatientControl: location.state.fromPatientControl,
        })
      )
      .catch(() => history.push("/oops"));
  };

  const getPossibleMessages = () => ({
    mainHeader: {
      Stroke: "Step 4: Top Symptoms",
      tbiPatient: "Step 4: Top Symptoms",
      caregiver: "Step 4: Top Symptoms",
    },
    sallieText: {
      Stroke: "Which symptoms would you like to track on your dashboard?",
      tbiPatient: "Which symptoms would you like to track on your dashboard?",
      caregiver: "Which symptoms would you like to track on your dashboard?",
    },
    messageAfterList: {
      Stroke: "You can change your symptoms, or you can add more later.",
      tbiPatient: "You can change your symptoms, or you can add more later.",
      caregiver:
        "You can change the patient's symptoms, or you can add more later.",
    },
    noSymptomsText: {
      Stroke: "Please select at least 1 symptom to track",
      tbiPatient: "Please select at least 1 symptom to track",
      caregiver: "Please select at least 1 symptom to track",
    },
    selectSymptomsCategory: {
      Stroke:
        "From your selections below please choose up to three (3) symptoms to track on your dashboard.",
      tbiPatient:
        "From your selections below please choose up to three (3) symptoms to track on your dashboard.",
      caregiver:
        "From your selections below please choose up to three (3) symptoms to track on your dashboard.",
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
          <>
            <OnboardingStepHeader text={getMessage("mainHeader")} />{" "}
            <Sallie text={getMessage("sallieText")} />
            {returnedData.map((itemList, i) => (
              <ListSelector
                key={itemList.category}
                category={itemList.category}
                message={getMessage("selectSymptomsCategory")}
                list={itemList.items}
                index={i}
                selectedList={selectedData[itemList.category]}
                toggleFromSelectedList={toggleFromSelectedList}
                selectOne={switchSingleSelection}
                maxSelectAmount={itemList.maxSelectAmount}
                noOther
                noSuffix
              />
            ))}
            {getMessage("messageAfterList")}
            <Row>
              <Col>
                {noSymptom && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    {getMessage("noSymptomsText")}
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col>
                <SubmitButton
                  disabled={submitting}
                  type="button"
                  onClick={handleSubmit}
                >
                  Save & Continue &gt;
              </SubmitButton>
              </Col>
            </Row>
          </>
        )}
    </>
  );
};

export default SelectTopSymptoms;
