import React, { useContext } from "react";
import { ThemeContext } from "../lib/main-context";

import PillButton from "./StyledComponents/PillButton";

const ThemeChanger = () => {
  const { dark, toggleTheme } = useContext(ThemeContext);

  return (
    <div className="d-flex justify-content-end">
      <PillButton className="pillBtn" onClick={() => toggleTheme()}>
        {dark ? "Turn Dark Mode Off" : "Turn Dark Mode On"}
      </PillButton>
    </div>
  );
};

export default ThemeChanger;
