import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Sallie from "../Sallie";
import SubmitButton from "../StyledComponents/SubmitButton";
import LoadingSpinner from "../LoadingSpinner";
import SallieSVG from "../../images/sallie.svg";
import SadSallie from "../../images/Sallie-Oops.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
    checkEmailToken,
    getProviderTokenInformation,
    getInviterInfo,
    sendConfirmEmail,
    deleteEmailToken,
    creatConnection
} from "../../api/helpReq";

const EmailConfirmForExistingProvider = ({ setOnboardingPercent, user }) => {
    const location = useLocation();
    const history = useHistory();
    const url = new URLSearchParams(location.search);
    const token = url.get("token");
    // const [userValue, setUserValue] = useState("tbiPatient");
    // const [patientId, setPatientId] = useState(null);
    // const [providerEmail, setProviderEmail] = useState(null);
    const [patientEmail, setPatientEmail] = useState(null);
    const [patientFirstName, setPatientFirstName] = useState(null);
    const [patientLastName, setPatientLastName] = useState(null);
    const [caregiverEmail, setCaregiverEmail] = useState(null);
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        setOnboardingPercent(10);
        if (token) {
            const data = { token };
            // data : token : token
            // console.log('token', token)
            getInviterInfo({ data })
                .then((res) => {
                    const { data } = res;
                    // setValue("email", data.providerEmail);
                    // Assuming we are hiding the email field for providers in the JSX
                    setPatientFirstName(data.first_name)
                    setPatientLastName(data.last_name)
                    setPatientEmail(data.patient_email)
                    setCaregiverEmail(data.caregiver_email)
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    history.push("/oops");

                })
        }
    }, []);


    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // const reSendEmail = () => {
    //     const { firstName, lastName, email } = user;
    //     sendConfirmEmail({
    //         email: email,
    //         name: firstName + " " + lastName,
    //     });
    //     history.push("/Verification/EmailSent");
    // };

    const clickHandlerLogin = async () => {
        history.push("/");
    };

    const clickHandler = async () => {
        try {
            setSubmitting(true);
            // creatConnection({ data: { token: "patientljMf0R_1td" } })
            const data = { token };
            creatConnection({ data })
                .then((res) => {
                    setSubmitting(false);
                    setSuccess(true);
                })
                .catch((err) => {
                    console.error(err);
                    history.push("/oops");
                })
        } catch (err) {
            setSubmitting(false);
            history.push("/oops");
        }
    };

    let sallieMsg =
        `Hi! A patient named ${patientFirstName + " " + patientLastName} from ${patientEmail == undefined ? caregiverEmail : patientEmail} is requesting a connection.
        By connecting, you will have access to view your patient's reports & send secure messages.
        Click on the Confirm button now to set connection!`;
    let errorMsg =
        "Sorry we could not verify your email. Please click the button below to send verification email again.";

    // useEffect(() => {
    //     setOnboardingPercent(90);
    //     checkEmailToken({ token: token })
    //         .then((res) => {
    //             if (res.status !== 200) {
    //                 setError(true);
    //             }
    //             setLoading(false);
    //         })
    //         .catch((e) => {
    //             console.log("token is not matched", e);
    //             setError(true);
    //             setLoading(false);
    //         });
    // }, []);


    let img = SallieSVG;

    return (
        <>
            {/* <OnboardingStepHeader text="Patient Connecting Confirmation" style={{ textAlign: "center" }} /> */}
            <div className="d-flex justify-content-center"><h4 className="mt-4 mb-4">Patient Connecting Confirmation</h4></div>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <>
                    <Sallie text={sallieMsg} sad="true" />
                    {/* <SubmitButton disabled={submitting} onClick={reSendEmail}>
                        Resend Now
                </SubmitButton> */}
                </>
            ) : (
                        <>
                            <>
                                <Row>
                                    <Col
                                        md={2}
                                        style={{ minWidth: "125px" }}
                                        className="d-flex align-items-center justify-content-center"
                                    >
                                        <img
                                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                                            src={img}
                                            alt="Dog named Sallie"
                                        />
                                    </Col>
                                    <Col className="p-0" style={{ maxWidth: "70%" }}>
                                        <div className="sallie" style={{ fontSize: "1.3rem" }}>
                                            {sallieMsg}
                                        </div>
                                    </Col>
                                </Row>
                                {/* <SubmitButton centered={true} disabled={submitting} onClick={reSendEmail}>
                                    Resend Now
                                </SubmitButton> */}
                            </>
                            {success == false ?
                                <SubmitButton style={{ marginRight: "13%", fontSize: "1.4rem" }} disabled={submitting} onClick={clickHandler}>
                                    Confirm
                            </SubmitButton>
                                :
                                <>
                                    <h5 className="d-flex justify-content-center">set connection successfully!</h5>
                                    <SubmitButton style={{ marginRight: "13%", fontSize: "1.4rem" }} disabled={submitting} onClick={clickHandlerLogin}>
                                        Login
                            </SubmitButton>
                                </>
                            }
                        </>
                    )}
        </>
    );
};

export default EmailConfirmForExistingProvider;
