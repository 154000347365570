import React from "react";
import { useHistory } from "react-router-dom";
import ComponentWrapper from "./ComponentWrapper";
import { Tabs, Tab } from "react-bootstrap";
import LineChart from "./LineChart";
import SdohChart from "./SDOHChart";
import SubmitButton from "../StyledComponents/SubmitButton";
import lineChartIcon from "../../images/show_chart.svg";
import barChartIcon from "../../images/bar_chart.svg";

const Charts = ({ topSymptoms, data, error, retry, additionalNotes }) => {
  const history = useHistory();

  const handleSelect = (key) => {
    if (key === "30day") {
      history.push(`/charts/${encodeURIComponent(topSymptoms[0].factor)}`);
    }
    if (key === "VisionReport") {
      history.push(`/vision/${encodeURIComponent(topSymptoms[0].factor)}`);
    }
  };

  return (
    <ComponentWrapper>
      <Tabs id="chartTab" onSelect={handleSelect}>
        {error && (
          <div>
            There was an error loading your data
            <SubmitButton onClick={retry}>Click here to try again</SubmitButton>
          </div>
        )}

        <Tab
          eventKey="line"
          title={<img src={lineChartIcon} alt="line chart icon" />}
        >
          <LineChart
            topSymptoms={topSymptoms}
            data={data}
            additionalNotes={additionalNotes}
          />
        </Tab>
        <Tab
          eventKey="bar"
          title={<img src={barChartIcon} alt="bar chart icon" />}
        >
          <SdohChart data={data} />
        </Tab>
        <Tab eventKey="30day" title="30 Day View"></Tab>
        {/* <Tab eventKey="VisionReport" title="Vision Report"></Tab> */}
      </Tabs>
    </ComponentWrapper>
  );
};

export default Charts;
