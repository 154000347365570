import * as yup from "yup"

const DescribeTBISchema = yup.object().shape({
  whySeeking: yup
    .string()
    .required("whySeeking is required"),
  otherTreatments: yup.string().required("Description required"),

})

export default DescribeTBISchema
