import React, { useState, useContext } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { Modal, Button } from 'react-bootstrap';
import ComponentWrapper from "./ComponentWrapper";
import { PatientContext } from "../../lib/main-context";
import { postAdjustments } from "../../api/TBIRequests";
import { format } from 'date-fns';

const AdjustmentCalendar = ({ adjustments = [], setAdjustments }) => {
  const { patient } = useContext(PatientContext);
  const [selectedDate, setSelectedDate] = useState([null]);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingDate, setPendingDate] = useState(null);
  const [isExistingEntry, setIsExistingEntry] = useState(false);

  const handleDayClick = async (day) => {
    if (format(day, "MM-dd-yyyy") <= format(new Date(),"MM-dd-yyyy")) {
      setPendingDate(day);
      const adjustmentExists = await adjustments.some(adj => 
        format(new Date(adj.adjustment_date), "MM-dd-yyyy") === format(new Date(day), "MM-dd-yyyy")
      );
      setIsExistingEntry(adjustmentExists);
      setIsModalOpen(true);
    }
  };

  const adjustmentDates = adjustments.map(adj => new Date(adj.adjustment_date));


  const submitAdjustment = async (day) => {
    setSubmitting(true);
    const dateStr = new Date(day);
    const adjustmentData = { date: dateStr };

    try {
      await postAdjustments({
        patientId: patient.patientId,
        data: adjustmentData,
      });
      setError(false);
      setAdjustments(prevAdjustments => [
        ...prevAdjustments,
        { adjustment_date: dateStr, logged_at: new Date().toISOString() }
      ]);
    } catch {
      setError(true);
    } finally {
      setSubmitting(false);
    }

    setSelectedDate(day);
  };

  const handleModalConfirm = () => {
    setIsModalOpen(false);
    submitAdjustment(pendingDate);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setPendingDate(null);
  };

  const modifiersStyles = {
    tracked: {
      color: "white",
      backgroundColor: "green",
    },
    untracked: {
      color: "white",
      backgroundColor: "red",
    },
  };

  const disableFutureDays = (day) => {
    const today = new Date();
    return format(new Date(day),"MM-dd-yyy") > format(new Date(),"MM-dd-yyy")
  };

  return (
    <ComponentWrapper centerContents>
    {"Mark down your adjustments?"}
      <DayPicker
        onDayClick={handleDayClick}
        selectedDays={adjustmentDates}
        modifiersStyles={modifiersStyles}
        disabledDays={disableFutureDays
        }
      />
      <Modal show={isModalOpen} onHide={handleModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Adjustment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isExistingEntry ? (
            <p>An adjustment for this date already exists. Do you want to mark another adjustment for the same day?</p>
          ) : (
            <p>Do you want to mark an adjustment for this date?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCancel}>No</Button>
          <Button variant="primary" onClick={handleModalConfirm}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </ComponentWrapper>
  );
};

export default AdjustmentCalendar;
