import React from "react";
import { useHistory } from "react-router-dom";
import ComponentWrapper from "./ComponentWrapper";
import SubmitButton from "../StyledComponents/SubmitButton";

const SuggestionBox = () => {
  const history = useHistory();
  const clickHandler = () => {
    history.push("/help");
  };
  return (
    <ComponentWrapper>
      <div className="p-2">
        If you have any suggestion on how we can improve our app, please click
        the button below to share your opinion.
      </div>
      <SubmitButton onClick={clickHandler}>Go to Suggestion Box</SubmitButton>
    </ComponentWrapper>
  );
};

export default SuggestionBox;
