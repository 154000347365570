import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import theme from "../index.scss";
import Sallie from "../images/Sallie-Oops.png";
import SubmitButton from "./StyledComponents/SubmitButton";

const Oops = () => {
  const history = useHistory();
  return (
    <Row
      style={{ color: theme.teal }}
      className="d-flex justify-content-center text-center"
    >
      <Col>
        <Row>
          <Col>
            <h2>Oops!</h2>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col xs={8} md={4}>
            <img
              style={{ objectFit: "cover", maxWidth: "100%" }}
              alt="Apologetic Dog"
              src={Sallie}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Something went wrong on our end.</h4>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md={6}>
            <p style={{ color: "initial" }}>
              Sorry this page did not load correctly. Go back to the previous
              page or if the problem continues, please submit a request for help
            </p>
          </Col>
        </Row>
        <Row className="d-flex flex-column align-items-center">
          <SubmitButton onClick={history.goBack}>Go Back</SubmitButton>
          <SubmitButton onClick={() => history.push("/help")}>
            Request Help
          </SubmitButton>
        </Row>
      </Col>
    </Row>
  );
};

export default Oops;
