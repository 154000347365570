import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import ComponentWrapper from "./ComponentWrapper";
import TrashIcon from "../../images/delete.svg"; // Assume this is the icon for delete
import { getHabitsToday, postHabitToday, addHabit, softDeleteHabit } from "../../api/TBIRequests";
import { PatientContext } from "../../lib/main-context";
import LoadingSpinner from "../LoadingSpinner"; // Assuming you have a LoadingSpinner component

const HabitTracker = () => {
  const [habits, setHabits] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newHabitName, setNewHabitName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { patient } = useContext(PatientContext);

  useEffect(() => {
    if (patient) {
      fetchHabits();
    }
  }, [patient]);

  const fetchHabits = () => {
    setLoading(true);
    getHabitsToday(patient.patientId)
      .then((response) => {
        const fetchedHabits = response.data;
        setHabits(fetchedHabits);
      })
      .catch((error) => {
        console.error("Error fetching habits:", error);
        setError("Failed to load habits. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleHabitCompletion = (habitId, completed) => {
    if (patient) {
      setLoading(true);
      const data = {
        patientId: patient.patientId,
        habitId: habitId,
        completed: completed,
      };
      postHabitToday(data, patient.patientId)
        .then(() => {
          fetchHabits(); // Fetch updated habits after tracking
        })
        .catch((error) => {
          console.error("Error logging habit:", error);
          setError("Failed to log habit. Please try again later.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleAddHabit = () => {
    setNewHabitName("");
    setShowModal(true);
  };

  const handleSaveHabit = () => {
    if (patient) {
      setLoading(true);
      addHabit(patient.patientId, newHabitName)
        .then((response) => {
          setHabits((prevHabits) => [
            ...prevHabits,
            { ...response.data, habits_completed: null }
          ]);
          fetchHabits()
        })
        .catch((error) => {
          console.error("Error adding habit:", error);
          setError("Failed to add habit. Please try again later.");
        })
        .finally(() => {
          setLoading(false);
          setShowModal(false);
        });

      setNewHabitName("");
    }
  };

  const handleDeleteHabit = (habitId) => {
    if (patient) {
      setLoading(true);
      softDeleteHabit(habitId, patient.patientId)
        .then(() => {
          setHabits((prevHabits) =>
            prevHabits.filter((habit) => habit.habit_id !== habitId)
          );
        })
        .catch((error) => {
          console.error("Error deleting habit:", error);
          setError("Failed to delete habit. Please try again later.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ComponentWrapper>
      <Row>
        <Col>
          <h6>Habit Tracker</h6>
          <p>Please track your habits for today</p>
          {error && <p className="text-danger">{error}</p>}
        </Col>
      </Row>
      {habits.map((habit, index) => (
        <Row key={index} className="align-items-center">
          <Col sm={1}>
            <span>{index + 1}.</span> {/* Numbered bullet points */}
          </Col>
          <Col sm={6} className="d-flex align-items-center">
            <p style={{ marginRight: '10px', marginBottom: '0', fontWeight: 'normal' }}>
              {habit.habit_name || "Enter your habit"}
            </p>
            {habit.habit_id && (
              <img
                src={TrashIcon}
                alt="delete icon"
                className="deleteIcon"
                style={{ cursor: "pointer", marginLeft: 'auto' }}
                onClick={() => handleDeleteHabit(habit.habit_id)}
              />
            )}
          </Col>
          <Col sm={2} className="d-flex justify-content-end">
            {habit.habit_id && (
              <Button
                variant={habit.habits_completed === true ? "success" : "outline-success"}
                onClick={() => handleHabitCompletion(habit.habit_id, true)}
                disabled={habit.habits_completed !== null}
                size="sm"
              >
                Yes
              </Button>
            )}
          </Col>
          <Col sm={2} className="d-flex justify-content-start">
            {habit.habit_id && (
              <Button
                variant={habit.habits_completed === false ? "danger" : "outline-danger"}
                onClick={() => handleHabitCompletion(habit.habit_id, false)}
                disabled={habit.habits_completed !== null}
                size="sm"
              >
                No
              </Button>
            )}
          </Col>
        </Row>
      ))}
      <Row className="mt-4">
        <Col>
          {habits.length < 3 && (
            <Button variant="primary" onClick={handleAddHabit}>
              Add New Habit
            </Button>
          )}
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Habit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Habit Name</Form.Label>
              <Form.Control
                type="text"
                value={newHabitName}
                onChange={(e) => setNewHabitName(e.target.value)}
                placeholder="Enter habit name"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveHabit}>
            Save Habit
          </Button>
        </Modal.Footer>
      </Modal>
    </ComponentWrapper>
  );
};

export default HabitTracker;
