const sdohWithSetVal = (data) => {
  let sdohs = data.filter(({ category }) => category === "SDOH");
  let sdohsWithVal = [];

  sdohs.forEach((sdoh, index) => {
    const sameDaySdoh = sdohs.filter(
      ({ symptom_date }) => symptom_date === sdoh.symptom_date
    );
    let sameDaySdohCombined = [];
    sameDaySdoh.forEach((currentSdoh) =>
      sameDaySdohCombined.push(currentSdoh.factor)
    );

    //to avoid duplicates on sameday, push the combined sdoh list for the day only once.
    if (sameDaySdohCombined[0] === sdoh.factor) {
      let symptomsOnSameDay = data.filter(
        (factor) =>
          factor.symptom_date === sdoh.symptom_date &&
          factor.category === "medical"
      );
      let biggestVal = 0;
      symptomsOnSameDay.forEach((factor) => {
        if (factor.severity > biggestVal) {
          biggestVal = factor.severity;
        }
      });

      sdohsWithVal.push({
        symptom_date: sdoh.symptom_date,
        severity: biggestVal + 5,
        description: sameDaySdohCombined.join(", "),
      });
    }
  });

  return sdohsWithVal;
};

export default sdohWithSetVal;
