import React, { useState, useEffect } from "react";

import OnboardingStepHeader from "./OnboardingStepHeader";
import Sallie from "../Sallie";

const EmailSent = ({ setOnboardingPercent, user }) => {
  useEffect(() => {
    setOnboardingPercent(90);
  }, []);

  let SallieMsg = `We have sent an email to ${user.email} to make sure you own it. Please check your inbox to verify your email address within 24 hours!`;
  return (
    <>
      <OnboardingStepHeader text="Step 7-b: Email Confirmation" />
      <Sallie text={SallieMsg} />
    </>
  );
};

export default EmailSent;
