/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";

import { useHistory, useLocation } from "react-router-dom";

import { Row, Col, Form } from "react-bootstrap";

import { getTherapiesList, postPreviousTherapies } from "../../api/TBIRequests";

import useListSelector from "../../lib/useListSelector";

import SdohListSelecter from "../SdohListSelecter";
import Sallie from "../Sallie";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";
// import hasDuplicatesAcrossCategories from "../../lib/hasDuplicatesAcrossCategories";
import customizeMessages from "../../lib/customizeMessages";

const SelectTherapies = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);

  const [loading, setLoading] = useState(true);
  const [returnedData, setReturnedData] = useState(["menstrual cycle", 'xvcxzvxzv']);
  const [submitting, setSubmitting] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const messageArr = {
    "LengthOfChiropractic": "How long have you been receiving chiropractic care?",
    "FrequencyOfPast12": "Please describe the frequency of chiropractic care of the past 12 months?",
    "Alternative": "Alternative",
    "Applied": "Applied",
    "Vision": "Vision",
    "Mental Health": "Mental Health"
  };
  const categories = ["LengthOfChiropractic", "FrequencyOfPast12", "Alternative", "Applied", "Vision", "Mental Health"];

  const createItemListFromRes = (res) => {
    const itemList = categories.map((category) => {
      const items = res.data
        .filter((item) => item.category === category)
        .map((item) => item.therapies);
      return { category, items };
    });
    itemList.push({ category: "other", items: [] });
    return itemList;
  };

  useEffect(() => {
    location.state.fromPatientControl
      ? setOnboardingPercent(100)
      : setOnboardingPercent(70);
    getTherapiesList()
      .then((res) => {
        const itemList = createItemListFromRes(res);
        for (let i = 0; i < itemList.length - 1; i++) {
          itemList[i].items.push("None");
        }

        setReturnedData(itemList);
        initialize(itemList);
        setLoading(false);
      })

      .catch(() => history.push("/oops"));
    // if we include initialize in the deps we get an infinite loop
    // TODO: Extract logic of init into outer function to use dep arr correctly
  }, []);

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        therapies: item,
      }));
    });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const processedData = processDataForSending();
    if (processedData.length === 0) {
      location.state.fromPatientControl
        ? history.push("/patientControl")
        : history.push(
          "/notificationPrefs"
        );
    }
    else {
      postPreviousTherapies({
        patientId: patient.patientId,
        data: processedData,
      })
        .then(() => {
          location.state.fromPatientControl
            ? history.push("/patientControl")
            : history.push(
              "/notificationPrefs"
              // Removed due to last minute change removing 'select top sdoh' page
              // Kept around as comment incase we decide to change back
              // , { forwardData: { selectedData, processedData, dateOfTBI },}
            );
        })
        .catch(() => history.push("/oops"));
    }
  };

  const getPossibleMessages = ({ getPronoun }) => ({
    mainHeader: {
      Stroke: "Step 6: Therapies",
      tbiPatient: "Step 6: Therapies",
      caregiver: "Step 6: Therapies",
    },
    sallieText: {
      Stroke: "What therapies have you tried?",
      tbiPatient: "What therapies have you tried?",
      caregiver: "What therapies have you tried?",
    },
    // sdohLinkText: {
    //   tbiPatient: "What is a Social Determinant of Health?",
    //   caregiver: "What is a Social Determinant of Health?",
    // },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
          <>
            <OnboardingStepHeader text={getMessage("mainHeader")} />
            <Sallie text={getMessage("sallieText")} />
            {returnedData.map((itemList, i) => (
              itemList.category == "FrequencyOfPast12" || itemList.category == "LengthOfChiropractic" ?
                <SdohListSelecter
                  key={itemList.category}
                  category={itemList.category}
                  message={messageArr[itemList.category]}
                  list={itemList.items}
                  index={i}
                  maxSelectAmount={1}
                  selectedList={selectedData[itemList.category]}
                  toggleFromSelectedList={toggleFromSelectedList}
                  selectOne={switchSingleSelection}
                  addOther={addOther}
                />
                :
                <SdohListSelecter
                  key={itemList.category}
                  category={itemList.category}
                  message={messageArr[itemList.category]}
                  list={itemList.items}
                  index={i}
                  selectedList={selectedData[itemList.category]}
                  toggleFromSelectedList={toggleFromSelectedList}
                  selectOne={switchSingleSelection}
                  addOther={addOther}
                />
            ))}
            {/*             
            <ListSelector
              // key={1}
              category={"therapies"}
              message={"therapies"}
              list={returnedData[0].items}
              index={0}
              selectedList={selectedData['therapies']}
              toggleFromSelectedList={toggleFromSelectedList}
              selectOne={switchSingleSelection}
              addOther={addOther}
            /> */}
            {/* ))} */}
            <Row>
              <Col>
                {/* {hasDuplicates && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  Please only select unique symptoms
                </Form.Control.Feedback>
              )} */}
                {/* {noTrigger && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  Please select at least 1 trigger
                </Form.Control.Feedback>
              )} */}
              </Col>
              <Col>
                <SubmitButton disabled={submitting} onClick={handleSubmit}>
                  Save & Continue &gt;
              </SubmitButton>
              </Col>
            </Row>
          </>
        )}
    </>
  );
};

export default SelectTherapies;
