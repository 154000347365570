import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

const OnboardingProgress = ({ onboardingPercent }) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <h5>Create your account</h5>

      <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
        PROGRESS: {onboardingPercent}%
      </div>
      <ProgressBar
        now={onboardingPercent}
        max={100}
        variant="info"
        srOnly
        label={`Step ${onboardingPercent} / 100`}
      />
    </div>
  )
}

export default OnboardingProgress
