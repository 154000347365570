import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
// hooks
import { useHistory, useLocation } from "react-router-dom";
import useListSelector from "../../lib/useListSelector";
// components
import Sallie from "../Sallie";
import StrokeListSelecter from "../StrokeListSelecter";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";
// api
import { updateStrokeDetail } from "../../api/TBIRequests";
// lib
import customizeMessages from "../../lib/customizeMessages";
import { Form } from "react-bootstrap";

const RecentStroke2 = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [returnedData, setReturnedData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const typeStroke = [,
    "Embolic Stroke (Ischemic Stroke)",
    "Thrombotic Stroke (Ischemic Stroke)",
    "Hemorrhagic Stroke",
    "Brain Stem Stroke",
    "Cryptogenic Stroke (stroke of unknown cause)",
    "Transient Ischemic Attacks (TIAs)",
    "I don’t know/I’m not sure"]


  const priorStroke = [
    "Weakness or numbness in the face, arm or leg, usually on just one side",
    "Difficulty speaking on understanding language",
    "Decreased or blurred vision in one or both eyes",
    "Unexplained loss of balance or dizziness",
    "Severe headache with no known cause",
    "Confusion",
    "Pain in the face or legs",
    "Hiccups",
    "Feeling weak all over",
    "Chest pain",
    "Shortness of breath",
    "Rapid heartbeat"]

  const riskFactors = [
    "Have high blood pressure",
    "Have high cholesterol",
    "Have heart disease",
    "Have Diabetes",
    "Have an irregular heartbeat",
    "Smoke",
    "Have a family history of strokes "]
  const strokeInfo = [];
  const obj1 = {
    category: "typeStroke", items: typeStroke
  }
  const obj2 = {
    category: "priorStroke", items: priorStroke
  }
  const obj3 = {
    category: "riskFactors", items: riskFactors
  }
  strokeInfo.push(obj1);
  strokeInfo.push(obj2);
  strokeInfo.push(obj3);

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });
  const onSubmit = async () => {
    // join lists into strings for back end
    const assignedData = {
      typeStroke: selectedData.typeStroke[0], // it's in an array but only has one item, so we just take that item
      priorStroke: selectedData.priorStroke,
      riskFactors: selectedData.riskFactors,
    };
    if (!assignedData.typeStroke || assignedData.priorStroke.length === 0 || assignedData.riskFactors.length === 0) {
      setErrorMessage(true);
      return;
    }
    setSubmitting(true);
    try {
      const tbiDetailRes = await updateStrokeDetail({
        patientId: patient.patientId,
        data: assignedData,
      });
      history.push("/laterSymptoms", {
        date: tbiDetailRes.data.slice(0, tbiDetailRes.data.indexOf("T")),
        fromPatientControl: location.state.fromPatientControl,
      });
    } catch (err) {
      history.push("/oops");
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    location.state.fromPatientControl
      ? setOnboardingPercent(40)
      : setOnboardingPercent(30);
    setReturnedData(strokeInfo);
    initialize(strokeInfo);
    setLoading(false);
  }, []);

  const getPossibleMessages = () => ({
    mainHeader: {
      Stroke: "Step 3: Confirm Stroke Details",
      caregiver: "Step 3: Confirm Stroke Details",
    },
    sallieText: {
      Stroke: "Thanks for telling me about your stroke. Let's get a little more information.",
      caregiver: "Thanks for telling me about the patient's stroke. Let's get a little more information.",
    },
    typeStroke: {
      Stroke: "What type of stroke did you have?",
      caregiver: "What type of stroke did the patient has?",
    },
    priorStroke: {
      Stroke: "Please tell us about any of the symptoms, if any, you may have experienced prior to having your stroke?",
      caregiver: "Please tell us about any of the symptoms, if any, the patient may has experienced prior to having stroke?",
    },
    riskFactors: {
      Stroke: "Do you relate to any of the following risk factors of stroke? ",
      caregiver: "Does the patient relate to any of the following risk factors of stroke? ",
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  // The keys here must match the categories specified on the backend
  const options = {
    typeStroke: {
      message: "typeStroke",
      responseNumber: 1,
    },
    priorStroke: {
      message: "priorStroke",
    },
    riskFactors: {
      message: "riskFactors",
    },
  };


  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
          <div className="d-block">
            <OnboardingStepHeader text={getMessage("mainHeader")} />
            <Sallie text={getMessage("sallieText")} />

            {returnedData.map((itemList, i) => {
              const { category } = itemList;
              return (
                <StrokeListSelecter
                  key={category}
                  category={category}
                  message={getMessage(options[category].message)}
                  list={itemList.items}
                  index={i}
                  maxSelectAmount={options[category].responseNumber}
                  selectedList={selectedData[category]}
                  toggleFromSelectedList={toggleFromSelectedList}
                  selectOne={switchSingleSelection}
                  addOther={addOther}
                />
              );
            })}
            {errorMessage && (
              <Form.Control.Feedback
                style={{ display: "block" }}
                type="invalid"
              >
                You must select one of each type of response.
              </Form.Control.Feedback>
            )}
            <SubmitButton disabled={submitting} onClick={onSubmit} type="submit">
              Save & continue &gt;
          </SubmitButton>
          </div>
        )}
    </>
  );
};

export default RecentStroke2;
