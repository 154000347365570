import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../lib/main-context";
import { useHistory } from "react-router-dom";

import "react-day-picker/lib/style.css";
import { Row, Col, Alert } from "react-bootstrap";
//components
import Sallie from "./Sallie";
import GoalList from "./DashboardComponents/GoalList";
import FeelingToday from "./DashboardComponents/FeelingToday";
import Calendar from "./DashboardComponents/Calendar";
import AdjustmentCalendar from "./DashboardComponents/AdjustmentCalendar";

import SymptomTracker from "./DashboardComponents/SymptomTracker";
import BarChart from "./DashboardComponents/BarChart";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import AdditionalNote from "./DashboardComponents/AdditionalNote";
import SDOHSelector from "./DashboardComponents/SDOHSelector";
import SuggestionBox from "./DashboardComponents/SuggestionBox";
import ThemeChanger from "./ThemeChanger";
import AddSymptoms from "./DashboardComponents/AddSymptoms";
import Charts from "./DashboardComponents/Charts";
import LoadingSpinner from "./LoadingSpinner";
import CurrentPatient from "./DashboardComponents/CurrentPatient";
import { ThemeContext } from "../lib/main-context";

//lib
import customizeMessages from "../lib/customizeMessages";
import useWindowSize from "../lib/useWindowSize";
import getLoggedDataToday from "../lib/getLoggedDataToday";
//api
import { getAdditionalNote } from "../api/TBIRequests";
import InviteProvider from "./DashboardComponents/InviteProvider";
import SendMessage from "./DashboardComponents/SendMessage";
import SubmitButton from "./StyledComponents/SubmitButton";
import theme from "../index.scss";
import emailAlert from "../images/email-notification.svg";
import HabitToday from "./DashboardComponents/Habit";
import HabitProgressChart from "./DashboardComponents/HabitProgressChart";

const Dashboard = ({
  user,
  loadTrackedData,
  loadingTrackedData,
  trackedData,
  errorLoadingTrackedData,
  topSymptoms,
  setTopSymptoms,
  loadingTopSymptoms,
  loadingFeelingToday,
  doneSimpleTracking,
  setDoneSimpleTracking,
  loadingTopSDOH,
  topSDOH,
  errorLoadingTopSDOH,
  loadSDOH,
  messages,
  loadMessages,
  loadingHabitToday,
  doneSimpleHabitTracking,
  setDoneSimpleHabitTracking,
  adjustments,
  setAdjustments,
}) => {
  const { patient } = useContext(PatientContext);
  const [width] = useWindowSize();
  const history = useHistory();
  const { dark } = useContext(ThemeContext);

  // tied to calendar components
  const [date, setDate] = useState(new Date());

  // start with the 0 index of the user's top symptoms and update as needed
  const [currentTrackingSymptom, setCurrentTrackingSymptom] = useState(0);
  // initialized to one because we dont use it until at least one symptom
  // has been tracked
  const [currentTrackingStep, setCurrentTrackingStep] = useState(0);
  const [additionalNotes, setAdditionalNotes] = useState([]);
  const [showPopUp, setShowPopUp] = useState(true);

  const unreadMessagesPresent =
    messages && messages.some((message) => !message.hasBeenRead);

  const handleDayClick = (day, { disabled }) => {
    if (disabled) {
      return;
    }
    setDate(day);
  };

  const handleAdjustmentDayClick = (day, { disabled }) => {
    if (disabled) {
      return;
    }
    setDate(day);
  };

  useEffect(() => {
    if (patient) {
      getAdditionalNote(patient.patientId)
        .then(({ data }) => {
          setAdditionalNotes(data);
        })
        .catch((e) => console.log("getAdditionalNotesError", e));
    }
  }, [patient]);

  useEffect(() => {
    if (topSymptoms && topSymptoms.length > 0 && trackedData) {
      const loggedSymptomToday = getLoggedDataToday(
        trackedData,
        "medical",
        date
      );
      if (loggedSymptomToday.length === 0) {
        setCurrentTrackingSymptom(topSymptoms[0]);
        setCurrentTrackingStep(0);
      } else {
        saveTrackingStep();
      }
    }
  }, [history, topSymptoms, trackedData, date]);

  function getUnloggedSymptomsWithNextSymptomIndex() {
    const loggedSymptomToday = getLoggedDataToday(trackedData, "medical", date);
    let nextSymptomIndex = null;
    const unLoggedSymptoms = topSymptoms.filter((topSymptom, index) => {
      const isTopSymptomInLoggedSymptoms = !loggedSymptomToday.some(
        (loggedSymptom) => {
          return loggedSymptom.factor === topSymptom.factor;
        }
      );
      if (isTopSymptomInLoggedSymptoms && !nextSymptomIndex) {
        nextSymptomIndex = index;
      }
      return isTopSymptomInLoggedSymptoms;
    });
    return { unLoggedSymptoms, nextSymptomIndex };
  }

  const finishTrackingStep = () => {
    const loggedSDOHToday = getLoggedDataToday(trackedData, "SDOH", date);
    if (loggedSDOHToday.length || currentTrackingSymptom === "SDOH") {
      setCurrentTrackingSymptom("done");
    } else {
      setCurrentTrackingSymptom("SDOH");
    }
  };

  const skipTrackingStep = () => {
    const { unLoggedSymptoms } = getUnloggedSymptomsWithNextSymptomIndex();
    if (currentTrackingStep + 1 >= topSymptoms.length) {
      finishTrackingStep();
    } else {
      const currentSymptomIndexInUnloggedSymptoms = unLoggedSymptoms.findIndex(
        (symptom) => {
          return symptom.factor === currentTrackingSymptom.factor;
        }
      );

      if (
        currentSymptomIndexInUnloggedSymptoms + 1 >=
        unLoggedSymptoms.length
      ) {
        finishTrackingStep();
      } else {
        const nextSymptom =
          unLoggedSymptoms[currentSymptomIndexInUnloggedSymptoms + 1];
        const nextSymptomIndex = topSymptoms.findIndex((symptom) => {
          return symptom.factor === nextSymptom.factor;
        });
        setCurrentTrackingSymptom(nextSymptom);
        setCurrentTrackingStep(nextSymptomIndex);
      }
    }
  };

  const saveTrackingStep = () => {
    const { unLoggedSymptoms, nextSymptomIndex } =
      getUnloggedSymptomsWithNextSymptomIndex();
    const nextSymptom = unLoggedSymptoms[0];

    if (!nextSymptom || nextSymptomIndex === null) {
      finishTrackingStep();
    } else {
      setCurrentTrackingSymptom(nextSymptom);
      setCurrentTrackingStep(nextSymptomIndex);
    }
  };

  const ConditionalBarChart =
    loadingTrackedData || loadingTopSymptoms ? (
      <LoadingSpinner />
    ) : (
      <BarChart
        topSymptoms={topSymptoms}
        data={trackedData}
        error={errorLoadingTrackedData}
        retry={loadTrackedData}
        additionalNotes={additionalNotes}
      />
    );

  const ConditionalHabitTrackerChart =

    (<HabitProgressChart />
    )

  const ConditionalFeelingToday = loadingFeelingToday ? (
    <LoadingSpinner />
  ) : (
    !doneSimpleTracking && (
      <FeelingToday setDoneSimpleTracking={setDoneSimpleTracking} />
    )
  );

  // const ConditionalHabitToday = (
  //   <HabitToday />
  //   // )
  // );

  const ConditionalSymptomTracker =
    loadingTopSymptoms || loadingTrackedData || loadingTopSDOH ? (
      <LoadingSpinner />
    ) : (
      <>
        {currentTrackingSymptom === "SDOH" && (
          <ComponentWrapper>
            <SDOHSelector
              topSDOH={topSDOH}
              errorLoadingTopSDOH={errorLoadingTopSDOH}
              loadSDOH={loadSDOH}
              date={date}
              skipTrackingStep={skipTrackingStep}
              saveTrackingStep={saveTrackingStep}
              loadTrackedData={loadTrackedData}
            />
          </ComponentWrapper>
        )}
        {currentTrackingSymptom !== "SDOH" &&
          currentTrackingSymptom !== "done" && (
            <ComponentWrapper>
              <SymptomTracker
                date={date}
                user={user}
                symptom={currentTrackingSymptom}
                trackedData={trackedData}
                skipTrackingStep={skipTrackingStep}
                saveTrackingStep={saveTrackingStep}
                loadTrackedData={loadTrackedData}
              />
            </ComponentWrapper>
          )}
        {currentTrackingSymptom === "done" && (
          <ComponentWrapper>
            <AdditionalNote date={date} />
          </ComponentWrapper>
        )}
      </>
    );

  const ConditionalCombinedChart =
    loadingTrackedData || loadingTopSymptoms ? (
      <LoadingSpinner />
    ) : (
      <Charts
        topSymptoms={topSymptoms}
        data={trackedData}
        error={errorLoadingTrackedData}
        retry={loadTrackedData}
        additionalNotes={additionalNotes}
      />
    );

  const ConditionalCurrentPatient = ({ fillColumn }) =>
    user.userType === "caregiver" || user.userType === "provider" ? (
      <CurrentPatient fillColumn={fillColumn} />
    ) : null;
  const ConditionalInviteProvider = ({ fillColumn }) =>
    // user.userType === "caregiver" ? <InviteProvider fillColumn={fillColumn} /> : null;
    user.userType != "provider" ? (
      <InviteProvider fillColumn={fillColumn} user={user} />
    ) : null;

  // const ConditionalSendMessage = ({ fillColumn }) =>
  //   user.messageRecipient.length != 0 ? (
  //     <SendMessage
  //       messageRecipient={user.messageRecipient}
  //       // messageRecipientType={user.userType === "caregiver" && user.messageRecipientType}
  //       messageRecipientType={user.messageRecipientType}
  //       userType={user.userType}
  //       fillColumn={fillColumn}
  //       loadMessages={loadMessages}
  //     />
  //   ) : null;
  const { userType, messageRecipient } = user;

  const ConditionalSendMessage = () =>
    messageRecipient?.length !== 0 ? (
      <>
        <div className="d-flex justify-content-between pt-2 pb-2 pl-1 pr-1">
          <h6
            className="pt-2 pb-2 pl-3 pr-3"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "20px",
              backgroundColor: dark
                ? theme.darkModePrimary
                : unreadMessagesPresent
                  ? "#3EB1A6"
                  : "#ef5866",
              color: dark ? theme.darkModeText : "white",
              textAlign: "center",
              overflowWrap: "break-word",
              cursor: "pointer",
            }}
            onClick={() =>
              history.push("/messages", {
                curPatient: patient,
              })
            }
          >
            {unreadMessagesPresent ? (
              <div>
                New Messages
                <img
                  style={{
                    maxHeight: "30px",
                    maxWidth: "30px",
                    display: "inline-block",
                    paddingLeft: 10,
                  }}
                  src={emailAlert}
                  alt="email alert"
                />
              </div>
            ) : (
              "SEND or READ Messages "
            )}
          </h6>
        </div>
      </>
    ) : (
      <>
        {unreadMessagesPresent && "You have unread messages."}
        <div
          color={unreadMessagesPresent && "info"}
          onClick={() => history.push("/messages")}
          centered
        >
          View messages
        </div>
      </>
    );

  // console.log(user);

  const ConditionalSendMessagePatient = () =>
    messageRecipient?.length !== 0 ? (
      <>
        <div className="d-flex justify-content-between">
          <h6
            className="pt-2 pb-2 pl-3 pr-3"
            style={{
              width: "75%",
              height: "100%",
              borderRadius: "20px",
              backgroundColor: dark
                ? theme.darkModePrimary
                : unreadMessagesPresent
                  ? "#3EB1A6"
                  : "#ef5866",
              color: dark ? theme.darkModeText : "white",
              textAlign: "center",
              overflowWrap: "break-word",
              cursor: "pointer",
            }}
            onClick={() =>
              history.push("/messages", {
                curPatient: patient,
              })
            }
          >
            {unreadMessagesPresent ? (
              <div>
                New Messages
                <img
                  style={{
                    maxHeight: "30px",
                    maxWidth: "30px",
                    display: "inline-block",
                    paddingLeft: 10,
                  }}
                  src={emailAlert}
                  alt="email alert"
                />
              </div>
            ) : (
              "SEND or READ Messages "
            )}
          </h6>
        </div>
      </>
    ) : (
      <>
        {unreadMessagesPresent && "You have unread messages."}
        <div className="d-flex justify-content-center">
          <h6
            className="pt-2 pb-2 pl-3 pr-3"
            style={{
              width: "75%",
              height: "100%",
              borderRadius: "20px",
              backgroundColor: dark
                ? theme.darkModePrimary
                : unreadMessagesPresent
                  ? "#3EB1A6"
                  : "#ef5866",
              color: dark ? theme.darkModeText : "white",
              textAlign: "center",
              overflowWrap: "break-word",
              cursor: "pointer",
            }}
            onClick={() =>
              history.push("/messages", {
                curPatient: patient,
              })
            }
          >
            View messages
          </h6>
        </div>
      </>
    );

  // const ConditionalHabitTracker = ({ fillColumn }) =>
  //   user.userType != "provider" ? (
  //     <>
  //       <SendMessage
  //         messageRecipient={user.messageRecipient}
  //         // messageRecipientType={user.userType === "caregiver" && user.messageRecipientType}
  //         messageRecipientType={user.messageRecipientType}
  //         userType={user.userType}
  //         fillColumn={fillColumn}
  //         loadMessages={loadMessages}
  //       />
  //       <SendMessage
  //         messageRecipient={user.messageRecipient}
  //         // messageRecipientType={user.userType === "caregiver" && user.messageRecipientType}
  //         messageRecipientType={user.messageRecipientType}
  //         userType={user.userType}
  //         fillColumn={fillColumn}
  //         loadMessages={loadMessages}
  //       />{" "}
  //     </>
  //   ) : null;

  const VerifyEmailMessage =
    user.isVerified === false && user.userType !== "provider" && showPopUp ? (
      <Alert
        variant="warning"
        onClose={() => {
          setShowPopUp(false);
        }}
        dismissible
      >
        <Alert.Heading>Verify your email address</Alert.Heading>
        <p>OOPS! Looks like you need to verify your email address. </p>
        <p>
          Please go back to your inbox and look for an email
          "lynne@powerofpatients.com". It was sent to {user.email}. You may need
          to check your SPAM box too. Thank you!
        </p>
        {/* Please verify your email address to access all of dashboard's
            features. We have sent an email to {user.email}. */}
      </Alert>
    ) : null;

  const ConditionalAddSymptoms =
    topSymptoms && topSymptoms.length > 0 && user.userType !== "provider" ? (
      <AddSymptoms topSymptoms={topSymptoms} setTopSymptoms={setTopSymptoms} />
    ) : null;

  const getPossibleMessages = () => ({
    sallieText: {
      Stroke:
        "Welcome to your dashboard. For this to work properly you need to track your symptoms. Begin tracking below.",
      tbiPatient:
        "Welcome to your dashboard. For this to work properly you need to track your symptoms. Begin tracking below.",
      caregiver:
        "Welcome to your dashboard. For this to work properly you need to track " +
        `${patient && patient.firstName}'s` +
        " symptoms. Begin tracking below.",
      provider: "Welcome to your dashboard.",
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  if (width >= 768) {
    return (
      <>
        {VerifyEmailMessage}
        <Row>
          <Col md={8}>
            <Sallie text={getMessage("sallieText")} />
          </Col>
          <Col>
            <ThemeChanger />
          </Col>
        </Row>
        {/* End top row used just for Sallie */}
        <Col>
          <Row className="d-flex align-items-center justify-content-between">
            {/* {user && (user.userType === "caregiver" || user.userType === "provider") && ( */}
            <Col md={3}>
              {ConditionalCurrentPatient({ fillColumn: true })}
              {ConditionalInviteProvider({ fillColumn: true })}
            </Col>
            {/* )} */}
            {/* {user && (user.userType === "caregiver" || user.userType === "provider") && ( */}
            {user.userType != "Chiropractic Patient" &&
              ConditionalSendMessage()}
            {user.userType != "provider" && ConditionalSendMessagePatient()}
            {/* )} */}
          </Row>
        </Col>
        <Row>
          {/* One shorter left col and one larger right col */}
          <Col md={4} centerContents>
            {user.userType != "provider" && (
              <Row>
                <Col>
                  <AdjustmentCalendar
                    adjustments={adjustments}
                    setAdjustments={setAdjustments}
                  ></AdjustmentCalendar>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <HabitToday />
              </Col>
            </Row>

            <Row>
              <Col>
                <GoalList userType={user.userType} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Calendar
                  selectedDays={date}
                  onDayClick={handleDayClick}

                // modifiers={modifiers}
                />
              </Col>
            </Row>
            <Row>
              <Col>{ConditionalBarChart}</Col>
            </Row>
            <Row>
              <Col>{ConditionalHabitTrackerChart}</Col>
            </Row>
          </Col>
          <Col md={8}>
            {" "}
            {user.userType !== "provider" && ConditionalFeelingToday}
            {user.userType !== "provider" && ConditionalSymptomTracker}
            {ConditionalAddSymptoms}
            {ConditionalCombinedChart}
            <SuggestionBox />
          </Col>
        </Row>
      </>
    );
  }
  return (
    <>
      <Col>
        <ThemeChanger />
      </Col>
      {VerifyEmailMessage}
      <Col>
        <Sallie text={getMessage("sallieText")} />
      </Col>
      {/* End top row used just for Sallie */}

      <Row>
        <Col>
          <Row className="d-flex align-items-center justify-content-center">
            {/* {user.userType !== "provider" && ConditionalHabitToday} */}
            <Col md={6}>{ConditionalCurrentPatient({ fillColumn: true })}</Col>
            <Col md={6}>{ConditionalInviteProvider({ fillColumn: true })}</Col>
          </Row>
          <Row>
            <Col md={6}>
              {user.userType != "Chiropractic Patient" &&
                ConditionalSendMessage()}
              {user.userType != "provider" && ConditionalSendMessagePatient()}
            </Col>
          </Row>
          {user.userType !== "provider" && ConditionalFeelingToday}
          <AdjustmentCalendar
            adjustments={adjustments}
            setAdjustments={setAdjustments}
          ></AdjustmentCalendar>
          {user.userType !== "provider" && ConditionalSymptomTracker}
          <GoalList />
          <Calendar
            selectedDays={date}
            onDayClick={handleDayClick}

          // modifiers={modifiers}
          />
          {ConditionalAddSymptoms}
          {ConditionalBarChart}
          {ConditionalCombinedChart}
          <SuggestionBox />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
