import React, { useState, useEffect, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
import { useHistory } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import ComponentWrapper from "./ComponentWrapper";
import SubmitButton from "../StyledComponents/SubmitButton";
import useListSelector from "../../lib/useListSelector";
import ListSelector from "../ListSelector";
import UlSymptomList from "../StyledComponents/UlSymptomList";
import MedicalListSelector from "../../Components/MedicalListSelector";

import {
  getFirstTrackedData,
  postMoreTrackedFactors,
  getTBISymptoms,
  addSymptomDetails,
  getPausedList,
  postPausedList,
  removeTrackedFactors,
} from "../../api/TBIRequests";

const AddSymptoms = ({ topSymptoms, setTopSymptoms }) => {
  const history = useHistory();
  const { patient } = useContext(PatientContext);

  const [isAdding, setIsAdding] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [returnedData, setReturnedData] = useState([]);
  const [topSymptomList, setTopSymptomList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [restSymptoms, setRestSymptoms] = useState([]);
  const [noSymptom, setNoSymptom] = useState(false);
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [headacheParts, setHeadacheParts] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [pauseList, setPauseList] = useState([]);
  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });
  const messages = {
    cognitive: "Cognitive conditions: ",
    sleep: "Sleep conditions: ",
    physical: "Physical conditions: ",
    emotional: "Emotional conditions: ",
    speech: "Speech pathology Conditions: ",
    vision: "Vision conditions: ",
  };

  const tabs = {
    cognitive: "Cognitive",
    sleep: "Sleep",
    physical: "Physical",
    emotional: "Emotional",
    speech: "Speech",
    vision: "Vision",
  };

  const colors = {
    COGNITIVE: "#edebf3",
    SLEEP: "#ffdadb",
    EMOTIONAL: "#fff3c4",
    "MUSCLE/JOINT": "#ffdcaf",
    DIGESTIVE: "#cef3c4",
    INTERNAL: "#c1fdf7",
    PSYCOLOGICAL: "#FDE6E0",
    NEUROLOGICAL: "#EAEAEF",
    REPRODUCTIVE: "#E9EBFE",
    CANCER: "#DCE2F1",
    GENERAL: "#E3EDCD",
    ENDOCRINE: "#E6CEAC",
    URINARY: "#FFF2E2",
    PULMONARY: "#FAF9DE",
    SKIN: "#e9d857",
    "INFECTIOUS DISEASES": "#C7EDCC",

  };
  const colorsSelected = {
    COGNITIVE: "#edebf3",
    SLEEP: "#ffdadb",
    EMOTIONAL: "#fff3c4",
    "MUSCLE/JOINT": "#ffdcaf",
    DIGESTIVE: "#cef3c4",
    INTERNAL: "#c1fdf7",
    PSYCOLOGICAL: "#FDE6E0",
    NEUROLOGICAL: "#EAEAEF",
    REPRODUCTIVE: "#E9EBFE",
    CANCER: "#DCE2F1",
    GENERAL: "#E3EDCD",
    ENDOCRINE: "#E6CEAC",
    URINARY: "#FFF2E2",
    PULMONARY: "#FAF9DE",
    SKIN: "#e9d857",
    "INFECTIOUS DISEASES": "#C7EDCC",
  };

  // const colors = {
  //   cognitive: "#edebf3",
  //   sleep: "#ffdadb",
  //   physical: "#ffdcaf",
  //   emotional: "#fff3c4",
  //   speech: "#cef3c4",
  //   vision: "#c1fdf7",
  // };
  // const colorsSelected = {
  //   cognitive: "#4e3789",
  //   sleep: "#ffdadb",
  //   physical: "#ffdcaf",
  //   emotional: "#fff3c4",
  //   speech: "#cef3c4",
  //   vision: "#c1fdf7",
  // };


  useEffect(() => {
    // if (topSymptoms) {
    // let topList = topSymptoms.map((symptom) => symptom.factor);
    getPausedList(patient.patientId)
      .then(({ data }) => {
        const li = [];
        data.forEach((i) => {
          if (!li.includes(i.factor)) {
            li.push(i.factor);
          }
        })
        setPauseList(li);
      })
    // .catch(
    //   history.push("/oops"),
    // )
  }, []);

  useEffect(() => {
    // if (topSymptoms) {
    // let topList = topSymptoms.map((symptom) => symptom.factor);
    // setTimeout(() => {
    postPausedList({
      patientId: patient.patientId,
      data: [pauseList],
    })
      .then((added) => {
        const newParsedFactors = [...pauseList, ...added.data];
        // setPauseList(newParsedFactors);
      })
    // }, 10000);


  }, [pauseList]);

  // postTrackedFactors({
  //   patientId: patient.patientId,
  //   data: [{ factor: selectedSymptom }],
  // }).then((addedTopSymptoms) => {
  //   const newTopSymptoms = [...topSymptoms, ...addedTopSymptoms.data];
  //   setTopSymptoms(newTopSymptoms);
  //   setIsAdded(true);
  // });



  const changeErrorMsg = (errorCase) => {
    const errorMsgList = {
      isOverLimit: "You are already tracking 10 symptoms",
      noSymptom: "Please select a TBI symptom",
      needHeadAcheLocation:
        "Please select the location of the headaches caused by close work",
    };
    setErrorMsg(errorMsgList[errorCase]);
    setShowErrorMsg(true);
  };

  const getUntrackingSymptomsObj = (list) => {
    const unTrackingList = [];
    list.forEach((symptom) => {
      if (
        !topSymptomList.includes(symptom.factor) &&
        symptom.category !== "SDOH"
      ) {
        unTrackingList.push(symptom);
      }
    });
    return unTrackingList;
  };

  const getUntrackingSymptoms = (list) => {
    const unTrackingList = [];
    list.forEach((symptom) => {
      if (
        !topSymptomList.includes(symptom.factor) &&
        symptom.category !== "SDOH"
      ) {
        unTrackingList.push(symptom.factor);
      }
    });
    return unTrackingList;
  };


  const createItemList = (list) => {
    const itemList = [
      {
        category: "data",
        items: list,
        maxSelectAmount: "1",
      },
    ];
    return itemList;
  };

  const categories = [
    "COGNITIVE",
    "SLEEP",
    "EMOTIONAL",
    "MUSCLE/JOINT",
    "DIGESTIVE",
    "INTERNAL",
    "PSYCOLOGICAL",
    "NEUROLOGICAL",
    "REPRODUCTIVE",
    "CANCER",
    "GENERAL",
    "ENDOCRINE",
    "URINARY",
    "PULMONARY",
    "SKIN",
    "INFECTIOUS DISEASES",
  ];

  const createItemListFromRes = (data) => {
    return categories.map((category) => {
      const items = data
        .filter((item) => item.subcategory.toUpperCase() === category)
        .map((item) => item.factor);
      items.push("None");
      return { category, items };
    });
  };


  const buildMoreSymptoms = async () => {
    try {
      getFirstTrackedData(patient.patientId).then(({ data }) => {
        const untrackingList = getUntrackingSymptomsObj(data);
        setRestSymptoms(untrackingList);
      });
      getTBISymptoms().then(({ data }) => {
        // const availableSymptoms = getUntrackingSymptoms(data);
        // const itemList = createItemList(availableSymptoms);
        // initialize(itemList);
        // setReturnedData(itemList);
        const availableSymptoms = getUntrackingSymptomsObj(data);
        const itemList = createItemListFromRes(availableSymptoms);
        // const itemList = createItemList(availableSymptoms);
        initialize(itemList);
        setReturnedData(itemList);
      });
      setIsAdding(true);
    } catch {
      history.push("/oops");
    }
  };

  const handleSubmit = () => {
    const selectedSymptomArr = [];
    const sArr = Object.entries(selectedData);
    for (let i = 0; i < sArr.length; i++) {
      const subcategory = sArr[i][0];
      for (let j = 0; j < sArr[i][1].length; j++) {
        const curArr = [subcategory, sArr[i][1][j]]
        selectedSymptomArr.push(curArr);
      }
    }

    /*
    sArr
      Array (6)
      0 ["cognitive", ["Dyslexia", "Can't find the right words", "Dry eye"]] (2)
      1 ["sleep", []] (2)
      2 ["emotional", []] (2)
      3 ["physical", []] (2)
      4 ["speech", []] (2)
      5 ["vision", ["Bothered by light"]] (

      selectedSymptomArr
      Array (4)
      0 ["cognitive", "Dyslexia"] (2)
      1 ["cognitive", "Can't find the right words"] (2)
      2 ["cognitive", "Dry eye"] (2)
      3 ["vision", "Bothered by light"]
    */
    if (selectedSymptomArr.length == 0) {
      changeErrorMsg("noSymptom");
      return;
    } else if (selectedSymptomArr.length + topSymptoms.length > 10) {
      changeErrorMsg("isOverLimit");
      return;
    }
    setSubmitting(true);

    // selectedData
    // const selectedSymptom = Object.values(selectedData)[0][0];
    // if (!selectedSymptom) {
    //   changeErrorMsg("noSymptom");
    //   return;
    // } else if (topSymptoms.length >= 10) {
    //   changeErrorMsg("isOverLimit");
    //   return;
    // }
    // setSubmitting(true);

    try {
      selectedSymptomArr.forEach((selectedSymptom) => {
        if (
          selectedSymptom[1] ===
          "Headaches caused by close work: computers, reading, gaming"
        ) {
          if (headacheParts.length > 0) {
            addSymptomDetails({
              patientId: patient.patientId,
              data: {
                symptom:
                  "Headaches caused by close work: computers, reading, gaming",
                detail: headacheParts,
              },
            });
          } else {
            setSubmitting(false);
            changeErrorMsg("needHeadAcheLocation");
            return;
          }
        }
      })

      postMoreTrackedFactors({
        patientId: patient.patientId,
        data: selectedSymptomArr,
      }).then((addedTopSymptoms) => {
        const newTopSymptoms = [...topSymptoms, ...addedTopSymptoms.data];
        setTopSymptoms(newTopSymptoms);
        setIsAdded(true);
      });
    } catch (error) {
      history.push("/oops");
    }
  };

  useEffect(() => {
    if (topSymptoms) {
      let topList = topSymptoms.map((symptom) => symptom.factor);
      setTopSymptomList(topList);
    }
  }, [topSymptoms]);

  return (
    <ComponentWrapper>
      {isAdded ? (
        <div className="p-2">New symptom is added to your list to track.</div>
      ) : isAdding ? (
        <>
          <Row >
            <Col sm={6}>
              <UlSymptomList
                symptoms={topSymptoms}
                setPauseList={setPauseList}
                setTopSymptoms={setTopSymptoms}
                pauseList={pauseList}
                removeTrackedFactors={removeTrackedFactors}
                title="Currently Tracking Symptoms"
              />
            </Col>

            <Col sm={6}>
              <UlSymptomList
                symptoms={pauseList}
                title="Symptoms paused"
              />
            </Col>

            <Col sm={12}>
              {/* <UlSymptomList
                // className="d-flex justify-content-around"
                className="justify-content-center"
                symptoms={restSymptoms}
                title="Symptoms not tracking yet"
              /> */}
              <h6 style={{ textAlign: "center" }}>Symptoms not tracking yet</h6>
            </Col>
          </Row>


          <Tab.Container id="left-tabs-example" defaultActiveKey="COGNITIVE">
            <Row style={{ marginTop: "0.5rem", fontWeight: "normal", fontSize: "15px" }}>
              <Col sm={3} style={{ paddingRight: '0px' }}>
                <Nav fill justify variant="tabs" className="flex-column">
                  {returnedData.map((itemList, i) => (
                    <Nav.Item
                    >
                      <Nav.Link eventKey={itemList.category}
                        style={{
                          background: `${colors[itemList.category.toUpperCase()]}`,
                          border: '1px solid #106995',

                        }}> {itemList.category}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={5} style={{ paddingRight: '0px' }}>
                <Tab.Content>
                  {returnedData.map((itemList, i) => (
                    <Tab.Pane eventKey={itemList.category}
                    >
                      <MedicalListSelector
                        addSymptoms={true}
                        key={itemList.category}
                        category={itemList.category}
                        message={itemList.category}
                        list={itemList.items}
                        index={i}
                        selectedList={selectedData[itemList.category]}
                        toggleFromSelectedList={toggleFromSelectedList}
                        selectOne={switchSingleSelection}
                        addOther={addOther}
                        setHeadacheParts={setHeadacheParts}
                        headacheParts={headacheParts}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
              <Col sm={4}>
                <div className="justify-content-left" style={{
                  height: "40rem",
                  overflow: 'scroll',
                  color: 'black',
                  border: '1px solid #106995',
                  textAlign: "left",
                  background: "#fff",
                  padding: "1rem",
                  borderRadius: "5px",
                  margin: ".25rem 0",
                }}>
                  {Object.entries(selectedData).flatMap(([category, itemList]) => {
                    return itemList.map((item) => (
                      <div style={{ margin: '0.5rem 0' }}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>icon/action/check_ccircle_24px</title>
                          <defs>
                            <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="path-1"></path>
                          </defs>
                          <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <mask id="mask-2" fill="white">
                              <use xlinkHref="#path-1"></use>
                            </mask>
                            <use id="-↳Color" fill={colorsSelected[category.toUpperCase()]} xlinkHref="#path-1"></use>
                          </g>
                        </svg>
                        {" "}
                        {item}
                      </div>));
                  })}
                </div>
              </Col>
            </Row>
          </Tab.Container>

          {/* {returnedData.map((itemList, i) => (
            <ListSelector
              key={itemList.category}
              category={itemList.category}
              message={
                "These are the other symptoms you can select. Please click on the ones you want to track."
              }
              list={itemList.items}
              index={i}
              selectedList={selectedData[itemList.category]}
              toggleFromSelectedList={toggleFromSelectedList}
              selectOne={switchSingleSelection}
              maxSelectAmount={itemList.maxSelectAmount}
              noOther
              noSuffix
              setHeadacheParts={setHeadacheParts}
              headacheParts={headacheParts}
            />
          ))} */}
          <Row>
            {showErrorMsg && (
              <Form.Control.Feedback
                style={{ display: "block" }}
                type="invalid"
              >
                {errorMsg}
              </Form.Control.Feedback>
            )}
          </Row>
          <SubmitButton disabled={submitting} onClick={handleSubmit}>
            Save & Add
          </SubmitButton>
        </>
      ) : (
        <Row className="d-flex align-items-center justify-content-center">
          <Col xs={9} md={7}>
            <div className="ml-2">Would you like to add more symptoms?</div>
          </Col>
          <Col xs={2} md={3}>
            <SubmitButton onClick={buildMoreSymptoms}>Yes</SubmitButton>
          </Col>
        </Row>
      )}
    </ComponentWrapper>
  );
};

export default AddSymptoms;
