/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
import { useHistory, useLocation } from "react-router-dom";
import format from "date-fns/format";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Form } from "react-bootstrap";
import newbody from '../../images/newbody.png';
// impoNrt brain3 from '../../images/brain3.jpg';

import {
  getTBISymptoms,
  // postResultingFactors,
  postRegisterFactors,
  postNewResultingFactors,
  getTbiDate,
  addSymptomDetails,
} from "../../api/TBIRequests";
import useListSelector from "../../lib/useListSelector";

import MedicalListSelector from "../MedicalListSelector";
import Sallie from "../Sallie";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";
import hasDuplicatesAcrossCategories from "../../lib/hasDuplicatesAcrossCategories";
import customizeMessages from "../../lib/customizeMessages";

const SelectLaterSymptoms = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);

  const [loading, setLoading] = useState(true);
  const [returnedData, setReturnedData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [dateOfTBI, setDateOfTBI] = useState(undefined);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [headacheParts, setHeadacheParts] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  useEffect(() => {
    //when user didn't finish onboarding step, manually get tbiDate to continue
    if (location.state == undefined) {
      // getTbiDate(patient.patientId)
      //   .then((res) => {
      //     let TbiDate = format(
      //       new Date(res.data.tbi_incident_date),
      //       "MM-dd-yyyy"
      //     );
      //     setDateOfTBI(TbiDate);
      //   })
      //   .catch(() => {
      //     //user haven't finish step 2,3
      //     history.push("/oops");
      //   });
    } else {
      // setDateOfTBI(format(new Date(location.state.date), "MM-dd-yyyy"));
    }
  }, []);

  const getPossibleMessages = ({ getPronoun, isOtherGender }) => ({
    mainHeader: {
      Stroke: "Step 3: Medical Symptoms",
      tbiPatient: "Step 3: Medical Symptoms",
      caregiver: "Step 3: Medical Symptoms",
    },
    sallieText: {
      // Stroke: "The brain is interconnected, your stroke may be in one location" +
      //   " but it can cause issues in other areas. Lets gather your" +
      //   " sympotoms so we can internalize some data.",
      tbiPatient:
        "Your body is interconnected. Symptoms in one area often have a root cause in another body part.It is important to go through every body section below.",
      // caregiver:
      //   `The brain is interconnected, ${getPronoun("he")} stroke may be in one location` +
      //   ` but it can cause issues in other areas. Lets gather ${getPronoun("he")}` +
      //   " sympotoms so we can internalize some data.",
      // tbiPatient: "What are your current TBI symptoms?",
      // caregiver: "What are the patient's current TBI symptoms?",
    },

    // instructions: {
    //   tbiPatient:
    //     "Please select all of your current symptoms that you are having. If " +
    //     "there are none, please feel free to skip that section.",
    //   caregiver:
    //     `Please select all of the patient's current symptoms that ` +
    //     `${getPronoun("he")} ${isOtherGender ? "are" : "is"
    //     } having. If there are none, please ` +
    //     `feel free to skip that section.`,
    // },
    duplicatesText: {
      Stroke: "Please only select unique symptoms",
      tbiPatient: "Please only select unique symptoms",
      caregiver: "Please only select unique symptoms",
    },
    noSymptomsText: {
      Stroke: "Please select TBI symptoms you have experienced",
      tbiPatient: "Please select TBI symptoms you have experienced",
      caregiver: "Please select TBI symptoms the patient has experienced",
    },
    selectHeadacheLocationText: {
      Stroke:
        "Please select the location of the headaches caused by close work",
      tbiPatient:
        "Please select the location of the headaches caused by close work",
      caregiver:
        "Please select the location of the headaches caused by close work",
    },
  });
  const getMessage = customizeMessages({ user, getPossibleMessages });

  const changeErrorMsg = (errorCase) => {
    const errorMsgList = {
      hasDuplicates: getMessage("duplicatesText"),
      noSymptom: getMessage("noSymptomsText"),
      needHeadAcheLocation: getMessage("selectHeadacheLocationText"),
    };
    setErrorMsg(errorMsgList[errorCase]);
    setShowErrorMsg(true);
  };

  const categories = [
    "COGNITIVE",
    "SLEEP",
    "EMOTIONAL",
    "MUSCLE/JOINT",
    "DIGESTIVE",
    "INTERNAL",
    "PSYCOLOGICAL",
    "NEUROLOGICAL",
    "REPRODUCTIVE",
    "CANCER",
    "GENERAL",
    "ENDOCRINE",
    "URINARY",
    "PULMONARY",
    "SKIN",
    "INFECTIOUS DISEASES",
  ];
  categories.sort()

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        factor: item,
        category: "medical",
        subcategory: category,
        datetimeOfSymptom: dateOfTBI,
        hadSymptom: true,
      }));
    });
  };

  const createItemListFromRes = (res) => {
    return categories.map((category) => {
      const items = res.data
        .filter((item) => item.subcategory.toUpperCase() === category)
        .map((item) => item.factor);
      items.push("None");
      return { category, items };
    });
  };

  useEffect(() => {
    location.state.fromPatientControl
      ? setOnboardingPercent(60)
      : setOnboardingPercent(40);
    getTBISymptoms()
      .then((res) => {
        const itemList = createItemListFromRes(res);
        setReturnedData(itemList);
        initialize(itemList);
        setLoading(false);
      })
      .catch(() => {
        history.push("/oops");
      });
    // if we include initialize in the deps we get an infinite loop
    // TODO: Extract logic of init into outer function to use dep arr correctly
  }, []);

  useEffect(() => {
    for (let category in selectedData) {
      if (selectedData[category].includes("None")) {
        const selectedArrayWithoutNone = [...selectedData[category]]
        selectedArrayWithoutNone.splice(selectedArrayWithoutNone.indexOf("None"));
        selectedArrayWithoutNone.forEach((item) => {
          toggleFromSelectedList(item, selectedArrayWithoutNone, category);
        })
      }
    }
  }, [selectedData]);

  const handleSubmit = () => {
    let processedData = processDataForSending();
    // Remember Array.splice is in place, no need to assign anything
    Object.entries(selectedData).forEach(([category, itemList]) => {
      const noneIndex = itemList.indexOf("None");
      if (noneIndex !== -1) {
        selectedData[category].splice(noneIndex);
      }
    })
    // if the user added the same symptom to multiple categories, stop them!
    if (processedData.length === 0) {
      changeErrorMsg("noSymptom");
      return;
    }
    // TODO maybe make this an in-place splice like you did with selectedData, using Array.find instead of indexOf?
    processedData = processedData.filter((item) => item.factor !== "None");
    if (hasDuplicatesAcrossCategories(processedData)) {
      changeErrorMsg("hasDuplicates");
      return;
    }
    setSubmitting(true);
    try {
      // if (
      //   selectedData.vision.includes(
      //     "Headaches caused by close work: computers, reading, gaming"
      //   )
      // ) {
      //   if (headacheParts.length > 0) {
      //     addSymptomDetails({
      //       patientId: patient.patientId,
      //       data: {
      //         symptom:
      //           "Headaches caused by close work: computers, reading, gaming",
      //         detail: headacheParts,
      //       },
      //     });
      //   } else {
      //     setSubmitting(false);
      //     changeErrorMsg("needHeadAcheLocation");
      //     return;
      //   }
      // }

      // postNewResultingFactors({
      //   patientId: patient.patientId,
      //   data: processedData,
      // });

      postRegisterFactors({
        patientId: patient.patientId,
        data: processedData,
      });

      postNewResultingFactors({
        patientId: patient.patientId,
        data: processedData,
      }).then(() => {
        history.push("/selectTopSymptoms", {
          forwardData: { selectedData, processedData, dateOfTBI },
          fromPatientControl: location.state.fromPatientControl,
        });
      });
    } catch (err) {
      console.log("some error in SelectLaterSymptoms handleSubmit");
      console.log(err);
      history.push("/oops");
    }
  };

  // const messages = {
  //   cognitive: "Cognitive conditions: ",
  //   sleep: "Sleep conditions: ",
  //   physical: "Physical conditions: ",
  //   emotional: "Emotional conditions: ",
  //   speech: "Speech pathology Conditions: ",
  //   vision: "Vision conditions: ",
  // };

  // const messages = [
  //   "COGINTIVE symptoms",
  //   "SLEEP",
  //   "EMOTIONAL",
  //   "MUSCLE/JOINT",
  //   "DIGESTIVE",
  //   "INTERNAL",
  //   "PSYCOLOGICAL",
  //   "NEUROLOGICAL",
  //   "REPRODUCTIVE",
  //   "CANCER",
  //   "GENERAL",
  //   "ENDOCRINE",
  //   "URINARY",
  //   "PULMONARY",
  //   "SKIN",
  //   "INFECTIOUS DISEASES",
  // ];

  const tabs = {
    cognitive: "Cognitive Conditions",
    sleep: "Sleep Conditions",
    physical: "Physical Conditions",
    emotional: "Emotional Conditions",
    speech: "Speech Conditions",
    vision: "Vision Conditions",
  };

  const colors = {
    COGNITIVE: "#edebf3",
    SLEEP: "#ffdadb",
    EMOTIONAL: "#fff3c4",
    "MUSCLE/JOINT": "#ffdcaf",
    DIGESTIVE: "#cef3c4",
    INTERNAL: "#c1fdf7",
    PSYCOLOGICAL: "#FDE6E0",
    NEUROLOGICAL: "#EAEAEF",
    REPRODUCTIVE: "#E9EBFE",
    CANCER: "#DCE2F1",
    GENERAL: "#E3EDCD",
    ENDOCRINE: "#E6CEAC",
    URINARY: "#FFF2E2",
    PULMONARY: "#FAF9DE",
    SKIN: "#e9d857",
    "INFECTIOUS DISEASES": "#C7EDCC",

  };
  const colorsSelected = {
    COGNITIVE: "#edebf3",
    SLEEP: "#ffdadb",
    EMOTIONAL: "#fff3c4",
    "MUSCLE/JOINT": "#ffdcaf",
    DIGESTIVE: "#cef3c4",
    INTERNAL: "#c1fdf7",
    PSYCOLOGICAL: "#FDE6E0",
    NEUROLOGICAL: "#EAEAEF",
    REPRODUCTIVE: "#E9EBFE",
    CANCER: "#DCE2F1",
    GENERAL: "#E3EDCD",
    ENDOCRINE: "#E6CEAC",
    URINARY: "#FFF2E2",
    PULMONARY: "#FAF9DE",
    SKIN: "#e9d857",
    "INFECTIOUS DISEASES": "#C7EDCC",
  };


  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
          <>
            <Row className="justify-content-center">
              <Col
                xs={10}
                md={8}
              >
                <OnboardingStepHeader text={getMessage("mainHeader")} />
                <Sallie text={getMessage("sallieText")} style={{ textAlign: "left" }} />
                <Row>
                  <Col sm={12}>
                    <img src={newbody} style={{ display: 'inlineBlock', height: "100%", width: "100%" }}></img>
                  </Col>
                  {/* <Col sm={6}>    position: absolute;
    left: -86%;
    top: 52%;
                    <img src={brain3} style={{ display: 'inlineBlock', height: "18rem", width: "100%", marginBottom: "2rem", marginTop: "2rem" }}></img>
                  </Col> */}
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-between" style={{ marginTop: "1rem" }}>
              <Col
                sm={8}
              >
                <h5 style={{ textAlign: "center" }}>Go through each categories below to see the list of symtoms</h5>
              </Col>
              <Col
                sm={4}
              >
                <h5 style={{ textAlign: "center" }} >Your list of symptoms</h5>
              </Col>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="COGNITIVE">
              <Row style={{ marginTop: "0.5rem" }}>
                <Col sm={3} >
                  <Nav fill justify variant="tabs" className="flex-column">
                    {returnedData.map((itemList, i) => (
                      <Nav.Item
                      >
                        <Nav.Link eventKey={itemList.category}
                          style={{
                            background: `${colors[itemList.category]}`,
                            border: '1px solid #106995',
                          }}> {itemList.category}</Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
                <Col sm={5}>
                  <Tab.Content>
                    {returnedData.map((itemList, i) => (
                      <Tab.Pane eventKey={itemList.category}
                      >
                        <MedicalListSelector
                          key={itemList.category}
                          category={itemList.category}
                          message={itemList.category}
                          list={itemList.items.sort()}
                          index={i}
                          selectedList={selectedData[itemList.category]}
                          toggleFromSelectedList={toggleFromSelectedList}
                          selectOne={switchSingleSelection}
                          addOther={addOther}
                          setHeadacheParts={setHeadacheParts}
                          headacheParts={headacheParts}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
                <Col sm={4}>
                  <div className="justify-content-left" style={{
                    height: "40rem",
                    overflow: 'scroll',
                    color: 'black',
                    border: '1px solid #106995',
                    textAlign: "left",
                    background: "#fff",
                    padding: "2rem",
                    borderRadius: "5px",
                    margin: ".25rem 0",
                  }}>
                    {Object.entries(selectedData).flatMap(([category, itemList]) => {
                      return itemList.map((item) => (
                        <div style={{ margin: '0.5rem 0' }}>
                          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <title>icon/action/check_ccircle_24px</title>
                            <defs>
                              <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="path-1"></path>
                            </defs>
                            <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1"></use>
                              </mask>
                              <use id="-↳Color" fill={colorsSelected[category]} xlinkHref="#path-1"></use>
                            </g>
                          </svg>
                          {" "}
                          {item}
                        </div>));
                    })}
                  </div>
                </Col>
              </Row>
            </Tab.Container>

            {/* {returnedData.map((itemList, i) => (
              <MedicalListSelector
                key={itemList.category}
                category={itemList.category}
                message={messages[itemList.category]}
                list={itemList.items}
                index={i}
                selectedList={selectedData[itemList.category]}
                toggleFromSelectedList={toggleFromSelectedList}
                selectOne={switchSingleSelection}
                addOther={addOther}
                setHeadacheParts={setHeadacheParts}
                headacheParts={headacheParts}
              />
            ))} */}
            <Row>
              <Col>
                {showErrorMsg && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    {errorMsg}
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col xs lg="4">
                <SubmitButton
                  disabled={submitting}
                  type="button"
                  onClick={handleSubmit}
                >
                  Save & Continue &gt;
              </SubmitButton>
              </Col>
            </Row>
          </>
        )}
    </>
  );
};

export default SelectLaterSymptoms;
