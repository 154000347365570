import React, { useState, useEffect, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
// Hooks
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
// Bootstrap
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
// Validation Rules
import PatientSchema from "./validations/registerPatientValidation";

import { getPatientList, registerPatient } from "../../api/auth";
import { createGoals, getPatientTypes } from "../../api/TBIRequests";
// Components
import Sallie from "../Sallie";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";

const PatientInfo = ({ setOnboardingPercent, setPatientList, user, setPatient }) => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    location.state.fromPatientControl
      ? setOnboardingPercent(20)
      : setOnboardingPercent(15);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [otherRelationshipType, setOtherRelationshipType] = useState(false);
  const [otherStatus, setOtherStatus] = useState(false);
  const [patientTypes, setPatientTypes] = useState(null);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(PatientSchema),
  });

  useEffect(() => {
    getPatientTypes().then((res) => {
      const { data } = res;
      setPatientTypes(data);
    });
  }, [])

  const onChange = (e) => {
    if (e.target.name === "status") {
      setOtherStatus(e.target.value === "other");
    } else if (e.target.name === "relationshipType") {
      setOtherRelationshipType(e.target.value === "other");
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      // const responseData = await registerPatient(data);
      // const responsePatientData = responseData.data.find(
      //   (patient) => patient.firstName === data.firstName
      // );
      //
      // // See note in App.js for setPatient
      // setPatientList(responseData.data);
      // updatePatient(responseData.data, responsePatientData.patientId);
      // await createGoals({ patientId: responsePatientData.patientId });
      const responsePatientData = await registerPatient(data);
      setPatient(responsePatientData.data);
      await createGoals({ patientId: responsePatientData.data.patientId });
      const getPatientListResponse = await getPatientList();
      setPatientList(getPatientListResponse.data);
      if (data.status == "Stroke") {
        history.push("/RecentStroke1", {
          fromPatientControl: false,
        });
      } else {
        history.push("/RecentTBI1", {
          fromPatientControl: location.state.fromPatientControl,
        });
      }

    } catch (err) {
      setIsLoading(false);
      console.error(err);
      history.push("/oops");
    }
  };

  const SallieMsg = "We need the patient's information too!";

  // Taken from AuthenticatedRoute, patient is still null but user should be set
  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/signIn",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <>
      <OnboardingStepHeader text="Step 1-B: Patient Information" />
      <Sallie text={SallieMsg} />
      <Form
        autoComplete="on"
        action="/post"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Row>
          <Col md={6}>
            <LabelledInput
              name="firstName"
              required
              label="First Name"
              autoComplete="given-name"
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <LabelledInput
              name="lastName"
              autoComplete="family-name"
              label="Last Name"
              required
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <Form.Group controlId="gender">
              <Form.Label>
                Gender<span className="required">*</span>
              </Form.Label>
              <Form.Control
                name="gender"
                as="select"
                ref={register}
                isInvalid={errors.gender}
              >
                <option value=""></option>
                <option value="female">Female</option>
                <option value="male">Male</option>
                <option value="other">Other</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.gender && errors.gender.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <LabelledInput
              name="dob"
              type="DOB"
              label="Date of Birth (MM/DD/YYYY)"
              required
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <LabelledInput
              name="externalId"
              label="ID"
              inputRef={register}
              errors={errors}
            />
          </Col>

          <h6>
            Who are you tracking for? What is your relationship with the
            patient?
          </h6>
          <Col md={12}>
            <Form.Group controlId="relationshipType">
              {[
                { label: "Parent", value: "parent" },
                { label: "Spouse", value: "spouse" },
                { label: "Friend", value: "friend" },
                { label: "Child", value: "child" },
                { label: "Other", value: "other" },
              ].map((relationship, index) => (
                <>
                  <Form.Check
                    inline
                    label={relationship.label}
                    value={relationship.value}
                    type="radio"
                    name="relationshipType"
                    id={`inline-radio-relationship-type-${relationship.value}`}
                    onChange={onChange}
                    key={index}
                    ref={register}
                  />
                </>
              ))}
              {errors.relationshipType && (
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors.relationshipType.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {otherRelationshipType && (
              <>
                <Form.Group controlId="otherRelationship">
                  <h6>Relationship:</h6>
                  <Form.Control
                    ref={register}
                    type="text"
                    name="otherRelationship"
                    isInvalid={errors.otherRelationship}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.otherRelationship &&
                      errors.otherRelationship.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )}
          </Col>
          {patientTypes && (
            <>
              <h6>Please share with us the status of the patient:</h6>

              <Col md={12}>
                <Form.Group controlId="status" style={{ lineHeight: 1.75 }}>
                  {[
                    { label: "Acute TBI Patient", value: "acuteTbiPatient" },
                    { label: "Chronic TBI Patient", value: "chronicTbiPatient" },
                  ]
                    .concat(patientTypes.map((patientType) => ({ label: patientType.message, value: patientType.value })))
                    .concat([{ label: "Other", value: "other" }])
                    .filter((patientType) => !["otherType", "tbiPatient", "caregiver", "provider"].includes(patientType.value))
                    .map((status, index) => (
                      <>
                        <Form.Check
                          inline
                          label={status.label}
                          value={status.value}
                          type="radio"
                          name="status"
                          id={`inline-radio-status-${status.value}`}
                          onChange={onChange}
                          key={index}
                          ref={register}
                        />
                      </>
                    ))}
                  {errors.status && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.status && errors.status.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                {otherStatus && (
                  <>
                    <Form.Group controlId="otherStatus">
                      <h6>Patient status:</h6>
                      <Form.Control
                        ref={register}
                        type="text"
                        name="otherStatus"
                        isInvalid={errors.otherStatus}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.otherStatus && errors.otherStatus.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}
              </Col>
            </>
          )}
        </Form.Row>
        <Col md={12}>
          <SubmitButton disabled={isLoading} type="submit">
            Confirm
          </SubmitButton>
        </Col>
      </Form>
    </>
  );
};

export default PatientInfo;
