import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import theme from "../variables.scss";
import SallieSVG from "../images/sallie.svg";
import SadSallie from "../images/Sallie-Oops.png";

// Sallie takes up one row of whatever container she is in. 2 cols are for the img, and 10 are for the text. Text box shrinks to fit smaller messaged
const Sallie = ({ text, sad }) => {
  let img = SallieSVG;
  if (sad) {
    img = SadSallie;
  }
  return (
    <Row>
      <Col
        md={2}
        style={{ minWidth: "125px" }}
        className="d-flex align-items-center justify-content-center"
      >
        <img
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          src={img}
          alt="Dog named Sallie"
        />
      </Col>
      <Col className="p-0" style={{ maxWidth: "fit-content" }}>
        <div className="sallie">
          <h6>{text}</h6>
        </div>
      </Col>
    </Row>
  );
};

export default Sallie;
