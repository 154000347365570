import * as yup from "yup";
// import verifier from "email-verify";
import postalCodes from "postal-codes-js";
// let infoCodes = verifier.infoCodes;
let today = new Date();

const SignupSchema = ({isProviderForm}) => {
    return yup
        .object()
        .shape({
            firstName: yup.string().required("First name required"),
            lastName: yup.string().required("Last name required"),
            // phoneNumber: yup.string(),
            ...(!isProviderForm && { email: yup.string().email("Enter valid email").required("Email required")}),
            dob: yup
                .date()
                .typeError("Enter valid date")
                .min(new Date(1900, 1, 1), "Enter valid date")
                .max(
                    new Date(today.getFullYear() - 13, today.getMonth(), today.getDate()),
                    "You must be 13 or older"
                )
                .required(),
            password: yup
                .string()
                .required("Password is required")
                .min(8, "Password must be at least 8 characters"),
            // .matches(
            //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!% *#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
            // )
            passwordConfirmation: yup
                .string()
                .required("Password Confirmation is required")
                .oneOf([yup.ref("password"), null], "Passwords must match"),
            agreedToTerms: yup.bool().oneOf([true], "Must Accept Terms and Conditions"),
            postalCode: yup.string().required("Must provide postal code"),
            ...(!isProviderForm && {userType: yup.string().required("Registration type is required")}),
            ...(!isProviderForm && { otherType: yup.string().when("type", {
                is: "otherType",
                then: yup.string().required("Other registration type is required"),
            })}),
            gender: yup.string().required("Gender is required"),
            veteran: yup.string().required("Veteran is required"),
            ethnicity: yup.string().required("Ethnicity is required"),
            race: yup.string().required("Race is required"),
            // providerType: yup.string().required("ajkdlajsdf"),
            ...(isProviderForm && { providerType: yup.string().required("Provider type is required")}),
        })
        .test("zipcodeValidation", null, (obj) => {
            let validationResult = postalCodes.validate(obj.country, obj.postalCode);
            if (validationResult === true) {
                return true;
            } else {
                return new yup.ValidationError(validationResult, null, "postalCode");
            }
        });
}

export default SignupSchema;
