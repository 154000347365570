import React, { useContext, useEffect, useState } from "react";
import { PatientContext } from "../../lib/main-context";
import { Button, Col, Form, Row } from "react-bootstrap";

import ComponentWrapper from "./ComponentWrapper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import sendMessageSchema from "./validations/sendMessageSchema";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import Button2 from "../StyledComponents/Button2";
import { sendMessage } from "../../api/messageRequests";
import { useHistory } from "react-router-dom";

// messageRecipientType only has meaning for providers
const SendMessage = ({ fillColumn, messageRecipient, messageRecipientType, userType, loadMessages }) => {
  const history = useHistory();
  const { patient } = useContext(PatientContext);
  const [patientMessageRecipient, setPatientMessageRecipient] = useState("");
  // const [providerCaregiverMessageRecipient, setProviderCaregiverMessageRecipient] = useState("");
  const [name, setName] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(sendMessageSchema),
  });

  // useEffect(() => {
  //   if (userType !== "provider" && userType !== "caregiver") {
  //     history.push("/oops");
  //   }
  // }, [])
  const onSubmit = (data) => {
    setSubmitDisabled(true);
    // (userType != "provider") ?
    data.recipientId = patientMessageRecipient
    // : data.recipientId = messageRecipient.cargiver_or_patientid;// it's cargiver_or_patientid_or_providerId (return providerId for caregiver)

    sendMessage(data)
      .then(() => {
        setMessageSent(true);
        setInputVisible(false);
        loadMessages();
      })
      .catch((err) => {
        console.error(err);
      })
      //   .catch((e) => {
      //     if (e.response.status === 409) {
      //       setError("providerEmail", {
      //           type: "alreadyHasProvider",
      //           message: "You or your patient already has a provider. You can only register one provider per caregiver" +
      //             "and one provider per patient.",
      //       });
      //     }
      //   })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  let recipientString;

  if (userType === "provider") {
    recipientString = "caregiver";
  } else {
    recipientString = messageRecipientType;
  }

  return (
    <ComponentWrapper fillColumn={fillColumn}>
      <Row className="p-2">
        <Col>
          <span className="text-center">
            You have a connected user and can send messages to them.
          </span>
        </Col>
      </Row>
      <Row className={`p-2 ${fillColumn && !inputVisible ? " h-100" : ""}`}>
        <Col className="d-flex flex-column flex-wrap justify-content-around text-center">
          {/* <Col > */}
          {(
            // userType != "provider" && 
            !patientMessageRecipient) ?
            <div
            // className="d-flex justify-content-center"
            >
              {messageRecipient.map((recipient) => {
                return <Button2
                  onClick={() => {
                    setInputVisible(true);
                    setMessageSent(false);
                    setPatientMessageRecipient(recipient.provider_id ? recipient.provider_id : recipient.cargiver_or_patientid);
                    setName(recipient.first_name + ' ' + recipient.last_name);
                  }}
                >
                  Send message to {recipient.first_name + " " + recipient.last_name}
                </Button2>
              })}
            </div>

            :
            // (userType == "provider") ?


            //   messageRecipient.map((recipient) => {
            //     return <Button2
            //       onClick={() => {
            //         setInputVisible(true);
            //         setMessageSent(false);
            //         setPatientMessageRecipient(recipient.provider_id ? recipient.provider_id : recipient.cargiver_or_patientid);
            //         setName(recipient.first_name + ' ' + recipient.last_name);
            //       }}
            //     >
            //       Send message to {recipient.first_name + " " + recipient.last_name}
            //     </Button2>
            //   })
            // <SubmitButton
            //   onClick={() => {
            //     setInputVisible(!inputVisible);
            //     setMessageSent(false);
            //     setName(messageRecipient.first_name + ' ' + messageRecipient.last_name);
            //   }}

            // >
            //   Send message to {messageRecipient.first_name + " " + messageRecipient.last_name}
            // </SubmitButton>
            // :
            null

          }

          {patientMessageRecipient ? <Button2
            onClick={() => {
              setPatientMessageRecipient("");
              setInputVisible(false);
            }}
          >
            Back to selection
          </Button2>
            : null}


        </Col>
      </Row>
      {inputVisible &&
        <Row className="p-2">
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="p-2">
                <Col>
                  <LabelledInput
                    required
                    label="Message"
                    name="body"
                    errors={errors}
                    inputRef={register}
                    inputAs="textarea"
                    rows={3}
                  />
                  <SubmitButton
                    type="submit"
                    disabled={submitDisabled}
                  >
                    Send
                    {/* message to {userType === "provider" ? "caregiver" : "HCP"} */}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      }
      {messageSent &&
        <Row className="p-2">
          <Col className="text-center">
            An message has been sent to {name}.
          </Col>
        </Row>
      }
    </ComponentWrapper>
  );
};

export default SendMessage;
