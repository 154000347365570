import React, { useState, useEffect, useContext } from "react";
import { ThemeContext, PatientContext } from "../lib/main-context";
import { useParams, useHistory } from "react-router-dom";
import { Line, Bar } from "react-chartjs-2";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import theme from "../index.scss";
import LineChartOptions from "./DashboardComponents/LineChartOptions";
import SubmitButton from "./StyledComponents/SubmitButton";
import BarChartOptions from "./DashboardComponents/BarChartOptions";
import fillInDays from "../lib/fillInDates";
import getDaysAgo from "../lib/getDaysAgo";
import { parse } from "date-fns";
import { Row } from "react-bootstrap";
import sdohWithSetVal from "../lib/sdohWithSetVal";
import { getAdditionalNote } from "../api/TBIRequests";
import ModalPdf from "./DashboardComponents/ModalPdf";

const CHART_TYPES = {
  LINE: "line",
  BAR: "bar",
};

const ExpandedChart = ({ error, topSymptoms, data, retry }) => {
  const [type, setType] = useState(CHART_TYPES.LINE);
  const [additionalNotes, setAdditionalNotes] = useState([]);
  const [showBarModal, setShowBarModal] = useState(false);
  const [showLineModal, setShowLineModal] = useState(false);
  const params = useParams();
  const history = useHistory();
  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);

  const selectedSymptom = decodeURIComponent(params.symptom);

  const colors = [
    theme.chart1,
    theme.chart2,
    theme.chart3,
    theme.chart4,
    theme.chart5,
    theme.chart6,
    theme.chart7,
    theme.chart8,
    theme.chart9,
    theme.chart10,
  ];

  const handleBarModalClose = () => setShowBarModal(false);
  const handleBarModalShow = () => setShowBarModal(true);
  const handleLineModalClose = () => setShowLineModal(false);
  const handleLineModalShow = () => setShowLineModal(true);
  useEffect(() => {
    getAdditionalNote(patient.patientId)
      .then(({ data }) => {
        setAdditionalNotes(data);
      })
      .catch((e) => console.log("getAdditionalNotesError", e));
  }, []);

  let datasetArr = [];
  topSymptoms.forEach((symptom) => {
    let dataset = data.filter(({ factor }) => factor === symptom.factor);
    datasetArr.push(dataset);
  });
  let sdohList = sdohWithSetVal(data);
  datasetArr.push(sdohList);

  const filterDataBy30Days = (dataset) => {
    const datasetConvertedToChartData = dataset.map((item) => {
      return {
        // CURRENT BUG, Must remove time stamp or labels do not work correctly
        x: item.symptom_date.slice(0, 10),
        y: item.severity,
        desc: item.description,
      };
    });
    const filteredBy30Days = datasetConvertedToChartData.filter(
      (item) => parse(item.x, "yyyy-MM-dd", new Date()) > getDaysAgo(30)
    );
    return filteredBy30Days;
  };

  const newData = {
    datasets: [],
  };
  let { datasets } = newData;

  datasetArr.forEach((dataset, index) => {
    if (index < datasetArr.length - 1) {
      datasets.push({
        hidden: topSymptoms[index].factor !== selectedSymptom,
        fill: type !== "line",
        label: topSymptoms[index].factor,
        backgroundColor: colors[index],
        borderColor: colors[index],
        borderWidth: 1,
        hoverBackgroundColor: colors[index],
        hoverBorderColor: "white",
        data: filterDataBy30Days(dataset),
      });
    } else {
      datasets.push({
        label: "SDOH",
        data: filterDataBy30Days(dataset),
        pointStyle: "triangle",
        type: "scatter",
        backgroundColor: theme.primary,
        borderWidth: 1,
      });
    }
  });

  const ChartDataset = fillInDays(datasets, 30);

  newData.datasets = ChartDataset;
  newData.notes = additionalNotes;

  const extendedChartOption = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "Month in Review",
      fontFamily: "quicksand",
      fontStyle: "bold",
      fontColor: dark ? theme.darkModeText : "#666",
    },
    legend: {
      labels: {
        fontColor: dark ? theme.darkModeText : "#666",
        usePointStyle: true,
        boxWidth: 8,
      },
    },
  };

  if (type === CHART_TYPES.LINE) {
    return (
      <>
        {error && (
          <ComponentWrapper>
            There was an error loading your data
            <SubmitButton onClick={retry}>Click here to try again</SubmitButton>
          </ComponentWrapper>
        )}
        <ComponentWrapper>
          <Row className="d-flex justify-content-around">
            <SubmitButton onClick={() => setType("bar")}>
              Change to bar chart
            </SubmitButton>

            <SubmitButton
              onClick={() => {
                if (dark) {
                  alert("Please turn off dark mode to export the chart");
                } else {
                  handleLineModalShow();
                }
              }}
            >
              Export as PDF
            </SubmitButton>
          </Row>
        </ComponentWrapper>
        <ModalPdf
          show={showLineModal}
          handleClose={handleLineModalClose}
          chart="30day-lineChart"
          data={data}
          additionalNotes={additionalNotes}
          days={30}
        />
        <ComponentWrapper>
          <div className="30day-lineChart">
            <Line
              data={newData}
              width={100}
              height={300}
              options={{
                ...LineChartOptions,
                ...extendedChartOption,
              }}
            />
          </div>
        </ComponentWrapper>
        <SubmitButton onClick={() => history.push("/")}>Go Back</SubmitButton>
      </>
    );
  } else if (type === CHART_TYPES.BAR) {
    return (
      <>
        {error && (
          <ComponentWrapper>
            There was an error loading your data
            <SubmitButton onClick={retry}>Click here to try again</SubmitButton>
          </ComponentWrapper>
        )}
        <ComponentWrapper>
          <Row className="d-flex justify-content-around">
            <SubmitButton onClick={() => setType("line")}>
              Change to line chart
            </SubmitButton>

            <SubmitButton
              onClick={() => {
                if (dark) {
                  alert("Please turn off dark mode to export the chart");
                } else {
                  handleBarModalShow();
                }
              }}
            >
              Export as PDF
            </SubmitButton>
          </Row>
        </ComponentWrapper>
        <ModalPdf
          show={showBarModal}
          handleClose={handleBarModalClose}
          chart="30day-barChart"
          data={data}
          additionalNotes={additionalNotes}
          days={30}
        />
        <ComponentWrapper>
          <div className="30day-barChart">
            <Bar
              data={newData}
              width={100}
              height={300}
              options={{
                ...BarChartOptions,
                ...extendedChartOption,
              }}
            />
          </div>
        </ComponentWrapper>
        <SubmitButton onClick={() => history.push("/")}>Go Back</SubmitButton>
      </>
    );
  }
};

export default ExpandedChart;
