import styled from "styled-components"
import React, { useState, useContext } from "react"
import { PatientContext } from "../../lib/main-context";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Form } from "react-bootstrap";

const Container = styled.div`
  // width: 280px;
  margin: 10px;
  h6 {
    text-align: center;
  }
  ul {
    font-weight: normal;
    font-size: 15px;
    margin: 10px;
  }
`

const UlSymptomList = ({ symptoms, title, setPauseList, pauseList, removeTrackedFactors, setTopSymptoms }) => {

  const { patient } = useContext(PatientContext);
  const addPauseFactor = (s) => {
    let newParsedFactors = [];
    if (pauseList) {
      if (!pauseList.includes(s)) {
        newParsedFactors = [...pauseList, s];
      } else {
        newParsedFactors = [...pauseList];
      }
    } else {
      newParsedFactors = [s];
    }
    setPauseList(newParsedFactors);
    // setTimeout(() => {
    // }, 5000);
  }

  const removeTracking = (s) => {
    // symptoms
    let newTopSymptoms = [];
    symptoms.forEach((sy) => {
      if (sy.factor != s) {
        newTopSymptoms.push(sy);
      }
    })
    setTopSymptoms(newTopSymptoms);
    removeTrackedFactors({
      patientId: patient.patientId,
      data: [s],
    }).then(() => {
      console.log("remove");
    })
  }

  return (
    <Container>
      <h6>{title}</h6>
      <ul>
        {symptoms.map((symptom, i) => {
          if (symptom.factor) {
            return <li key={symptom.factor}>{symptom.factor} <button onClick={() => {
              addPauseFactor(symptom.factor)
              removeTracking(symptom.factor)
            }}> pause </button></li>
          } else {
            return <li key={symptom}>{symptom}</li>
          }
        })}
      </ul>
    </Container>
  )
}

export default UlSymptomList
