import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"

import { Row, Col, Form } from "react-bootstrap"
import Sallie from "../Sallie"
import LabelledInput from "../LabelledInput"
import DisabledButton from "../StyledComponents/DisabledButton"
import { ResetPw1Schema } from "./validation"
import { sendResetPwEmail } from "../../api/helpReq"

const ResetPassword1 = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [sallieMsg, setSallieMsg] = useState(
    "Let me help you reset your password! Please type in your email address you used when you register."
  )
  const { register, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(ResetPw1Schema)
  })
  const { touched } = formState

  const onSubmit = data => {
    setIsLoading(true)
    sendResetPwEmail(data)
    setSallieMsg(
      `If you are registered with this email. An Email with instructions on how to reset your password has been sent to ${data.email}.`
    )
  }

  return (
    <>
      <Sallie text={sallieMsg} />
      <Row className="d-flex justify-content-center">
        <Col xs={10} md={6}>
          <Form
            autoComplete="on"
            method="POST"
            className="mt-2"
            style={{ margin: "0 auto" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <LabelledInput
              labelAs="h6"
              name="email"
              autoComplete="email"
              inputRef={register}
              errors={errors}
              touched={touched}
            />
            <DisabledButton
              disabled={isLoading}
              className="w-100"
              type="submit"
              variant="outline-primary"
            >
              Continue
            </DisabledButton>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default ResetPassword1
