/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";

import { useHistory, useLocation } from "react-router-dom";

import { Row, Col, Form } from "react-bootstrap";

import { getTBISymptoms, postNewResultingFactors, postRegisteredSdoh } from "../../api/TBIRequests";

import useListSelector from "../../lib/useListSelector";

import Sallie from "../Sallie";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";
// import hasDuplicatesAcrossCategories from "../../lib/hasDuplicatesAcrossCategories";
import customizeMessages from "../../lib/customizeMessages";
import SdohListSelecter from "../../Components/SdohListSelecter"

const SelectSocialDeterminants = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);

  const [loading, setLoading] = useState(true);
  const [returnedData, setReturnedData] = useState([]);
  // const [hasDuplicates, setHasDuplicates] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [noTrigger, setNoTrigger] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });


  const categories = ["wellness", "travel", "screen time", "environmental", "Dietary", "situational"];

  const createItemListFromRes = (res) => {
    const itemList = categories.map((category) => {
      const items = res.data
        .filter((item) => item.subcategory === category)
        .map((item) => item.factor);
      return { category, items };
    });
    itemList.push({ category: "other", items: [] });
    return itemList;
  };

  useEffect(() => {
    location.state.fromPatientControl
      ? setOnboardingPercent(100)
      : setOnboardingPercent(60);
    getTBISymptoms()
      .then((res) => {
        const itemList = createItemListFromRes(res);
        for (let i = 0; i < itemList.length - 1; i++) {
          itemList[i].items.push("None");
        }
        setReturnedData(itemList);
        initialize(itemList);
        setLoading(false);
      })

      .catch(() => history.push("/oops"));
    // if we include initialize in the deps we get an infinite loop
    // TODO: Extract logic of init into outer function to use dep arr correctly
  }, []);

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        factor: item,
        category: "SDOH",
        subcategory: category,
      }));
    });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const processedData = processDataForSending();
    if (processedData.length === 0) {
      setSubmitting(false);
      setNoTrigger(true);
      return;
    } else {
      // if (hasDuplicatesAcrossCategories(processedData)) {
      //   setHasDuplicates(true);
      //   setSubmitting(false);
      //   return;
      // }

      postRegisteredSdoh({
        patientId: patient.patientId,
        data: processedData,
      });

      postNewResultingFactors({
        patientId: patient.patientId,
        data: processedData,
      })
        .then(() => {
          location.state.fromPatientControl
            ? history.push("/patientControl")
            : history.push(
              "/selectTherapies", {
              fromPatientControl: location.state.fromPatientControl,
            }
              // Removed due to last minute change removing 'select top sdoh' page
              // Kept around as comment incase we decide to change back
              // , { forwardData: { selectedData, processedData, dateOfTBI },}
            );
        })
        .catch(() => history.push("/oops"));
    }
  };

  const getPossibleMessages = ({ getPronoun }) => ({
    mainHeader: {
      Stroke: "Step 5: Triggers for Social Determinants of Health",
      tbiPatient: "Step 5: Triggers for Social Determinants of Health",
      caregiver: "Step 5: Triggers for Social Determinants of Health",
    },
    sallieText: {
      Stroke:
        "Below are some known triggers that may cause your symptoms to worsen. Check all of the triggers below that seem to bother you more since your injury. If there are none, please feel free to skip that section.",
      tbiPatient:
        "Below are some known triggers that may cause your symptoms to worsen. Check all of the triggers below that seem to bother you more since your injury. If there are none, please feel free to skip that section.",
      caregiver: `Please note that this next section is important. We need to know what they were exposed to because this can help us to determine if there is a pattern from these triggers that may be causing their symptoms to worsen. Check all of the triggers below that seem to bother the patient since ${getPronoun(
        "his"
      )} injury. If there are none, please feel free to skip that section.`,
    },
    sdohLinkText: {
      Stroke: "What is a Social Determinant of Health?",
      tbiPatient: "What is a Social Determinant of Health?",
      caregiver: "What is a Social Determinant of Health?",
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  const listSelectorMessages = {
    wellness: "Health triggers: ",
    travel: "Travel triggers: ",
    "screen time": "Screen time triggers: ",
    environmental: "Environmental triggers: ",
    Dietary: "Dietary triggers: ",
    situational: "Situational triggers: ",
    other: "Other triggers: ",
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
          <>
            <OnboardingStepHeader text={getMessage("mainHeader")} />

            <Sallie text={getMessage("sallieText")} />
            <a
              href="https://www.healthypeople.gov/2020/topics-objectives/topic/social-determinants-of-health"
              target="_blank"
              rel="noopener noreferrer"
            >
              <em>{getMessage("sdohLinkText")}</em>
            </a>
            {returnedData.map((itemList, i) => (

              <SdohListSelecter
                key={itemList.category}
                category={itemList.category}
                message={listSelectorMessages[itemList.category]}
                list={itemList.items}
                index={i}
                selectedList={selectedData[itemList.category]}
                toggleFromSelectedList={toggleFromSelectedList}
                selectOne={switchSingleSelection}
                addOther={addOther}
              />
            ))}
            <Row>
              <Col>
                {/* {hasDuplicates && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    Please only select unique symptoms
                  </Form.Control.Feedback>
                )} */}
                {noTrigger && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    Please select at least 1 trigger
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col>
                <SubmitButton disabled={submitting} onClick={handleSubmit}>
                  Save & Continue &gt;
              </SubmitButton>
              </Col>
            </Row>
          </>
        )}
    </>
  );
};

export default SelectSocialDeterminants;
