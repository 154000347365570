import * as yup from "yup";
const today = new Date();

const PatientSchema = yup
  .object()
  .shape({
    firstName: yup.string().required("First name required"),
    lastName: yup.string().required("Last name required"),
    dob: yup
      .date()
      .typeError("Enter valid date")
      .min(new Date(1900, 1, 1), "Enter valid date")
      .max(today)
      .required(),
    gender: yup.string().required("Gender is required"),
    relationshipType: yup.string().required("Type of relationship is required"),
    otherRelationship: yup.string().when("relationshipType", {
        is: "other",
        then: yup.string().required("Type of relationship is required")
    }),
    status: yup.string().required("Patient status is required"),
    otherStatus: yup.string().when("status", {
        is: "other",
        then: yup.string().required("Patient status is required")
    })
  });

export default PatientSchema;
