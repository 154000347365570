import React, { useContext, useState } from "react";
import { PatientContext } from "../../lib/main-context";
import { Button, Col, Form, Row } from "react-bootstrap";

import ComponentWrapper from "./ComponentWrapper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import inviteProviderSchema from "./validations/inviteProviderSchema";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import {
  sendProviderEmail,
  sendProviderEmail2,
  sendConnectExistingProviderEmail2,
  sendConnectExistingProviderEmail,
  checkProviderEmailExisting,
} from "../../api/helpReq";
import theme from "../../index.scss";
import { ThemeContext } from "../../lib/main-context";


const InviteProvider = ({ fillColumn, user }) => {
  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);
  const [inputVisible, setInputVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [existingPatientCaregiverAccount, setExistingPatientCaregiverAccount] =
    useState(false);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(inviteProviderSchema),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    if (user.userType == "caregiver") {
      checkProviderEmailExisting({ data, patientId: patient.patientId })
        .then((res) => {
          if (res.data.exist == false) {
            sendProviderEmail({ data, patientId: patient.patientId })
              .then(() => {
                setEmailSent(true);
                setInputVisible(false);
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  setError("providerEmail", {
                    type: "alreadyHasProvider",
                    message:
                      "You already has a provider. You can only register one provider per caregiver",
                  });
                }
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          } else {
            sendConnectExistingProviderEmail({
              data,
              patientId: patient.patientId,
            })
              .then(() => {
                setEmailSent(true);
                setInputVisible(false);
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  setError("providerEmail", {
                    type: "alreadyHasProvider",
                    message:
                      "You already has a provider. You can only register one provider per caregiver",
                  });
                }
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          }
        })
        .catch((e) => {
          console.error("Error checkProviderEmailExisting");
        });
    } else {
      // patient，stroke
      checkProviderEmailExisting({ data, patientId: patient.patientId })
        .then((res) => {
          if (res.data.exist == "false") {
            sendProviderEmail2({ data, patientId: patient.patientId })
              .then(() => {
                setEmailSent(true);
                setInputVisible(false);
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  setError("providerEmail", {
                    type: "alreadyHasProvider",
                    message:
                      "You already has a provider. You can only register one provider per caregiver",
                  });
                }
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          } else if (res.data.exist == "true") {
            sendConnectExistingProviderEmail2({
              data,
              patientId: patient.patientId,
            })
              .then(() => {
                setEmailSent(true);
                setInputVisible(false);
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  setError("providerEmail", {
                    type: "alreadyHasProvider",
                    message:
                      "You already has a provider. You can only register one provider per caregiver",
                  });
                }
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          } else if (res.data.exist == "emailfalse") {
            //existing patient or caregiver account.
            setSubmitDisabled(false);
            setExistingPatientCaregiverAccount(true);
          }
        })
        .catch((e) => {
          console.error("Error checkProviderEmailExisting");
        });
    }
  };

  return (
      <>
      <div className="d-flex justify-content-center">
        <h6
          id="invite-provider-button"
          className="pt-2 pb-2"
          style={{
            width: "75%",
            borderRadius: "20px",
            backgroundColor: dark ? theme.darkModePrimary : "#f7bc12",
            color: dark ? theme.darkModeText : "white",
            textAlign: "center",
            overflowWrap: "break-word",
            cursor: "pointer",
          }}
          onClick={() => setInputVisible(!inputVisible)}
        >
            Invite Health Care Provider[HCP]
          </h6>
          </div>
      {inputVisible && (
        <Row className="p-2 d-flex justify-content-center">
          <Col className="d-flex justify-content-center">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="p-2 justify-content-center">
                <Col>
                  <LabelledInput
                    required
                    label="Provider email"
                    name="providerEmail"
                    errors={errors}
                    inputRef={register}
                  />
                  <SubmitButton type="submit" disabled={submitDisabled}>
                    Send invitation email
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
      {emailSent && (
        <Row className="p-2">
          <Col className="d-flex justify-content-center text-center">
            An invitation has been sent to the provider and the provided email
            address.
          </Col>
        </Row>
      )}
      {existingPatientCaregiverAccount && (
        <Row className="p-2">
          <Col className="text-center">
            It's an existing patient/caregiver account, please change another
            email or contact your provider.
          </Col>
        </Row>
      )}</>
  );
};

export default InviteProvider;
