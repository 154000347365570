import React from "react";
// bootstrap
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// routing
import { Link, useLocation } from "react-router-dom";
// custom
import OnboardingProgress from "./OnboardingProgress";

const authenticatedOptions = (
  <>
    <Nav.Link eventKey="/" as={Link} to="/">
      Home
    </Nav.Link>
    {/* <Nav.Link eventKey="/media" as={Link} to="/media">
      Media
    </Nav.Link> */}
  </>
);

const userDropDownItems = (
  <>
    <NavDropdown.Item as={Link} to="/signOut">
      Log Out
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to="/accountSetting">
      Account Setting
    </NavDropdown.Item>
  </>
);
const unauthenticatedOptions = (
  <>
    <Nav.Link eventKey="/register" as={Link} to="/register">
      Register
    </Nav.Link>
    <Nav.Link eventKey="/signIn" as={Link} to="/signIn">
      Sign In
    </Nav.Link>
  </>
);

const alwaysOptions = (
  <>
    <Nav.Link eventKey="/help" as={Link} to="/help">
      Help
    </Nav.Link>
  </>
);

const Header = ({ user, routeGroup, onboardingPercent }) => {
  // used to keep the current link active
  const location = useLocation();

  if (routeGroup === "onboarding" || routeGroup === "laterSymptoms")
    return (
      <>
        <Navbar
          style={{ marginBottom: "50px" }}
          id="nav-border"
          collapseOnSelect
          expand="md"
        >
          <Nav style={{ width: "100%" }}>
            <Nav.Item
              onboardingPercent={onboardingPercent}
              as={OnboardingProgress}
            />
          </Nav>
        </Navbar>
        <div className="d-flex justify-content-end">
          <Nav.Link
            style={{ marginTop: "-40px" }}
            eventKey="/help"
            as={Link}
            to="/help"
          >
            Help
          </Nav.Link>
        </div>
      </>
    );
  return (
    <>
      <Navbar
        style={{ marginBottom: "50px" }}
        id="nav-border"
        collapseOnSelect
        expand="md"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" style={{ width: "100%" }}>
          {routeGroup !== "onboarding" && (
            <>
              <Nav
                activeKey={location.pathname.slice(
                  location.pathname.lastIndexOf("/")
                )}
                className="mr-auto"
                style={{ fontWeight: "bold", fontSize: "120%" }}
              >
                {user ? authenticatedOptions : unauthenticatedOptions}
                {alwaysOptions}
              </Nav>
              <Nav
                activeKey={location.pathname.slice(
                  location.pathname.lastIndexOf("/")
                )}
                className="ml-auto"
                style={{ fontWeight: "bold", fontSize: "120%" }}
              >
                {user ? (
                  <NavDropdown
                    id="user-selection"
                    title={`${user.firstName} ${user.lastName.slice(0, 1)}.`}
                  >
                    {userDropDownItems}
                  </NavDropdown>
                ) : (
                    ""
                  )}
              </Nav>
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
export default Header;
