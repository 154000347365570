import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
// hooks
import { useHistory, useLocation } from "react-router-dom";
import useListSelector from "../../lib/useListSelector";
// components
import Sallie from "../Sallie";
import ListSelector from "../ListSelector";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";
// api
import { updateTBIDetail, getTBIFormData } from "../../api/TBIRequests";
// lib
import customizeMessages from "../../lib/customizeMessages";
import {Form} from "react-bootstrap";

const RecentTBI2 = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [returnedData, setReturnedData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const onSubmit = async () => {
    // join lists into strings for back end
    const assignedData = {
      injuryFrom: selectedData.tbiFrom[0], // it's in an array but only has one item, so we just take that item
      headHitLocation: selectedData.headHitLocation,
      immediateSymptomsResulting: selectedData.experiencedTheseSigns,
    };
    if (!assignedData.injuryFrom || assignedData.headHitLocation.length === 0 || assignedData.immediateSymptomsResulting.length === 0) {
      setErrorMessage(true);
      return;
    }
    setSubmitting(true);
    try {
      const tbiDetailRes = await updateTBIDetail({
        patientId: patient.patientId,
        data: assignedData,
      });
      history.push("/laterSymptoms", {
        date: tbiDetailRes.data.slice(0, tbiDetailRes.data.indexOf("T")),
        fromPatientControl: location.state.fromPatientControl,
      });
    } catch (err) {
      history.push("/oops");
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    location.state.fromPatientControl
      ? setOnboardingPercent(40)
      : setOnboardingPercent(30);
    getTBIFormData()
      .then((res) => {
        const { data } = res;
        setReturnedData(data);
        initialize(data);
      })
      .catch((err) => {
        console.log(err);
        history.push("/oops");
      })
      .finally(() => setLoading(false));
  }, []);

  const getPossibleMessages = () => ({
    mainHeader: {
      tbiPatient: "Step 3: Confirm injury details",
      caregiver: "Step 3: Confirm injury details",
    },
    sallieText: {
      tbiPatient:
        "Thanks for telling me about your injury. Let's get a little more information.",
      caregiver:
        "Thanks for telling me about the patient's injury. Let's get a little more information.",
    },
    tbiFromText: {
      tbiPatient: "Your injury was from a: ",
      caregiver: "The patient's injury was from a: ",
    },
    headHitLocationText: {
      tbiPatient: "You were hit in the head: ",
      caregiver: "The patient was hit in the head: ",
    },
    experiencedTheseSignsText: {
      tbiPatient: "You experienced these signs: ",
      caregiver: "The patient experienced these signs: ",
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  // The keys here must match the categories specified on the backend
  const options = {
    tbiFrom: {
      message: "tbiFromText",
      responseNumber: 1,
    },
    headHitLocation: {
      message: "headHitLocationText",
    },
    experiencedTheseSigns: {
      message: "experiencedTheseSignsText",
    },
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <div className="d-block">
          <OnboardingStepHeader text={getMessage("mainHeader")} />
          <Sallie text={getMessage("sallieText")} />

          {returnedData.map((itemList, i) => {
            const { category } = itemList;
            return (
              <ListSelector
                key={category}
                category={category}
                message={getMessage(options[category].message)}
                list={itemList.items}
                index={i}
                maxSelectAmount={options[category].responseNumber}
                selectedList={selectedData[category]}
                toggleFromSelectedList={toggleFromSelectedList}
                selectOne={switchSingleSelection}
                addOther={addOther}
              />
            );
          })}
          {errorMessage && (
              <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
              >
                You must select one of each type of response.
              </Form.Control.Feedback>
          )}
          <SubmitButton disabled={submitting} onClick={onSubmit} type="submit">
            Save & continue &gt;
          </SubmitButton>
        </div>
      )}
    </>
  );
};

export default RecentTBI2;
