import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import NotificationSchema from "../Onboarding/validations/notificationSchema";
import { Form } from "react-bootstrap/";
import SubmitButton from "../StyledComponents/SubmitButton";
import TimeInput from "../TimeInput";
import stringifyDayOfWeek from "../../lib/stringifyDayOfWeek";
import { format } from "date-fns";
import { getTrackingPrefs, updateTrackingPrefs } from "../../api/auth";

const ChangeNotification = ({ setIsSuccess }) => {
  const [submitting, setSubmitting] = useState(false);
  const [isEmailAlert, setIsEmailAlert] = useState(false);
  const [isNoData, setIsNoData] = useState(false);
  const [oldTz, setOldTz] = useState(undefined);
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(NotificationSchema),
  });

  useEffect(() => {
    setIsSuccess(undefined);
    getTrackingPrefs().then((result) => {
      if (result.data) {
        const time = result.data.time_alert.slice(0, 2);
        const isAM = time < 12;
        const dayOfWeek = result.data.day_of_week.split(",");
        setOldTz(result.data.timezone);
        setValue("emailAlert", result.data.email_alert);
        setValue("hour", isAM ? time : time - 12);
        setValue("partOfDay", isAM ? "AM" : "PM");
        setValue("monday", dayOfWeek[1] === "true" ? true : false);
        setValue("tuesday", dayOfWeek[2] === "true" ? true : false);
        setValue("wednesday", dayOfWeek[3] === "true" ? true : false);
        setValue("thursday", dayOfWeek[4] === "true" ? true : false);
        setValue("friday", dayOfWeek[5] === "true" ? true : false);
        setValue("saturday", dayOfWeek[6] === "true" ? true : false);
        setValue("sunday", dayOfWeek[0] === "true" ? true : false);
        if (result.data.email_alert) {
          setIsEmailAlert(true);
        }
      } else {
        setIsNoData(true);
      }
    });
  }, []);

  const getNewTimezone = (oldTimezone) => {
    const currTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currTz) {
      return currTz;
    } else {
      return oldTimezone;
    }
  };

  const disableDayOfWeek = (e) => {
    if (e.target.checked) {
      setIsEmailAlert(true);
    } else {
      setIsEmailAlert(false);
    }
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    let notificationData = { emailAlert: data.emailAlert };

    const time = format(
   new Date(
        0,
        0,
        0,
        data.partOfDay === "AM" ? data.hour : data.hour + 12,
        0
     ),
     "HH:mm"
    );
    notificationData.timeAlert = time;
    const timezone = getNewTimezone(oldTz);
    const dayOfWeek = stringifyDayOfWeek(data);
    notificationData.dayOfWeek = dayOfWeek;
    notificationData.tz = timezone;

    updateTrackingPrefs(notificationData)
      .then(() => {
        setIsSuccess(true);
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
        setSubmitting(false);
      });
  };

  if (isNoData) {
    return (
      <h6>
        There is no notification data saved, please go back and finish
        onboarding
      </h6>
    );
  } else {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="email">
          <Form.Check
            name="emailAlert"
            ref={register}
            type="checkbox"
            label="Email"
            onChange={disableDayOfWeek}
            disabled={isNoData}
          />
        </Form.Group>
        <TimeInput
          errors={errors}
          register={register}
          label="Set your reminder time"
          disableStatus={!isEmailAlert}
        />

        <SubmitButton disabled={submitting} type="submit">
          Save
        </SubmitButton>
      </Form>
    );
  }
};

export default ChangeNotification;
