import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
import { Form, Col, Row } from "react-bootstrap";
// hooks
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";
// components
import Sallie from "../Sallie";
import LabelledInput from "../LabelledInput";
import DescribeTBISchema from "./validations/describeTBISchema";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";
// api
import { createTBI } from "../../api/TBIRequests";
import customizeMessages from "../../lib/customizeMessages";

const RecentTBI1 = ({ user, setOnboardingPercent }) => {
  const { patient } = useContext(PatientContext);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(DescribeTBISchema),
  });
  useEffect(() => {
    location.state.fromPatientControl
      ? setOnboardingPercent(30)
      : setOnboardingPercent(20);
    setLoading(false);
  }, []);

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      // data.dateOfTBI = format(new Date(data.dateOfTBI), "MM-dd-yyyy");
      await createTBI({ patientId: patient.patientId, data });
      setSubmitting(false);
      history.push("/laterSymptoms", {
        fromPatientControl: location.state.fromPatientControl,
      });
    } catch (err) {
      console.log(err);
      history.push("/oops");
    }
  };

  const getPossibleMessages = ({ getPronoun }) => ({
    mainHeader: {
      tbiPatient: "Step 2: Primary motivation for care?",
      caregiver: "Step 2: Describe the patient's injury",
    },
    sallieText: {
      tbiPatient: "Tell me more",
      caregiver: "Tell me about the patient's most recent TBI",
    },
    dateOfTbiLabel: {
      tbiPatient: "When was your most recent TBI?",
      caregiver: "When was the patient's most recent TBI?",
    },
    addTbis: {
      tbiPatient:
        "You will have the option to add other " +
        "TBI&apos;s after you set up your account.",
      caregiver:
        "You will have the option to add other " +
        "TBI&apos;s after you set up your account.",
    },
    describeEventLabel: {
      tbiPatient: "Why are you seeking chiropractic care?",
    },
    moredetails: {
      tbiPatient: "Are there more details you would like to share?",
    },
    otherTreatments: {
      tbiPatient: "What other treatments have you tried?",
    },
    // describeEventExtraInfo: {
    //   tbiPatient: "Include as much information as you can.",
    //   caregiver: "Include as much information as you can.",
    // },
    describeEventPlaceholder: {
      tbiPatient:
        "Example: I was on a ladder fixing my gutter when I fell " +
        "headfirst onto the ground. I sat up and experienced...",
      caregiver:
        `Example: The patient was on a ladder fixing ` +
        `${getPronoun("his")} gutter ` +
        `when ${getPronoun("he")} fell headfirst onto ` +
        `the ground. The patient sat up and experienced...`,
    },
    totalTbiLabel: {
      tbiPatient: "How many TBIs have you had?",
      caregiver: "How many TBIs has the patient had?",
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
          <>
            <div className="d-block">
              <OnboardingStepHeader text={getMessage("mainHeader")} />

              <Sallie text={getMessage("sallieText")} />
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-block">
                <Form.Row>

                  {/* hidden until we can implement, change to block when done */}
                  <Col className="d-none align-items-end">
                    <div className="mb-3 ml-3">{getMessage("addTbis")}</div>
                  </Col>
                  {/* end hidden */}
                </Form.Row>
                <LabelledInput
                  inputAs="textarea"
                  required
                  labelAs="h6"
                  name="whySeeking"
                  type="text"
                  inputRef={register}
                  label={getMessage("describeEventLabel")}
                  errors={errors}
                  rows={3}
                />

                <LabelledInput
                  inputAs="textarea"
                  required
                  labelAs="h6"
                  name="otherTreatments"
                  type="text"
                  inputRef={register}
                  label={getMessage("otherTreatments")}
                  errors={errors}
                  rows={3}
                />

                <LabelledInput
                  inputAs="textarea"
                  labelAs="h6"
                  name="moredetails"
                  type="text"
                  inputRef={register}
                  label={getMessage("moredetails")}
                  errors={errors}
                  rows={3}
                />

                <Row>
                  <SubmitButton type="submit" disabled={submitting}>
                    Save & Continue &gt;
                </SubmitButton>
                </Row>
              </div>
            </Form>
          </>
        )}
    </>
  );
};

export default RecentTBI1;
