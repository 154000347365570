import React, { useState, useEffect, useContext } from "react";
import { ThemeContext, PatientContext } from "../../lib/main-context";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";
// import ListSelector from "../ListSelector";
import SdohListSelecter from "../SdohListSelecter";
import useListSelector from "../../lib/useListSelector";
import SubmitButton from "../StyledComponents/SubmitButton";
import { postTrackSymptom } from "../../api/TBIRequests";
import ComponentWrapper from "./ComponentWrapper";
import theme from "../../index.scss";
/* eslint-disable react-hooks/exhaustive-deps */

const SDOHSelector = ({
  topSDOH,
  loadSDOH,
  errorLoadingTopSDOH,
  date,
  skipTrackingStep,
  saveTrackingStep,
  loadTrackedData,
}) => {
  const history = useHistory();
  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);
  const [returnedData, setReturnedData] = useState([]);
  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const categories = ["wellness", "travel", "screen time", "environmental"];

  useEffect(() => {
    const itemList = categories.map((category) => {
      const items = topSDOH
        .filter((item) => item.subcategory === category)
        .map((item) => item.factor);
      return {
        category,
        items,
      };
    });
    itemList.push({ category: "none", items: ["None"] });
    setReturnedData(itemList);
    initialize(itemList);
  }, [topSDOH]);

  useEffect(() => {
    if (selectedData.none && selectedData.none.length > 0) {
      for (let category in selectedData) {
        if (selectedData[category].length > 0 && category !== "none") {
          selectedData[category].forEach((item) => {
            toggleFromSelectedList(item, selectedData[category], category);
          });
        }
      }
    }
  }, [selectedData]);

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        symptom: {
          factor: item,
          category: "SDOH",
          subcategory: category,
        },
        datetimeOfSymptom: format(date, "MM-dd-yyyy"),
        hadSymptom: true,
      }));
    });
  };

  const handleSubmit = () => {
    const data = processDataForSending();
    postTrackSymptom({
      patientId: patient.patientId,
      data,
    })
      .then(() => {
        saveTrackingStep();
        loadTrackedData();
      })
      .catch(() => history.push("/oops"));
  };

  const messages = {
    wellness: "Wellness triggers: ",
    travel: "Travel triggers: ",
    "screen time": "Screen time triggers: ",
    environmental: "Environmental triggers: ",
    none: "None: ",
  };

  return (
    <>
      {errorLoadingTopSDOH ? (
        <ComponentWrapper>
          There was an error loading your data
          <SubmitButton onClick={loadSDOH}>
            Click here to try again
          </SubmitButton>
        </ComponentWrapper>
      ) : (
          <>
            <h6 className="d-flex justify-content-between">
              <span>
                Track{" "}
                {patient.userPatientRelationshipType === "self"
                  ? "your"
                  : patient.firstName + "'s"}{" "}
              symptoms
            </span>
              <span>{format(date, "LLL d, yyyy")}</span>
            </h6>
            <div
              className="p-3 m-3"
              style={{
                borderRadius: "5px",
                boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.75)",
              }}
            >
              <div className="d-flex justify-content-center">
                <h6
                  className="pt-2 pb-2 pl-3 pr-3"
                  style={{
                    width: "50%",
                    borderRadius: "20px",
                    backgroundColor: dark ? theme.darkModePrimary : theme.primary,
                    color: dark ? theme.darkModeText : "white",
                    textAlign: "center",
                    overflowWrap: "break-word",
                  }}
                >
                  Social Determinants of Health
              </h6>
              </div>
            Use the categories below to select social determinants.
            {returnedData.map((itemList, i) => (
                <SdohListSelecter
                  key={itemList.category}
                  category={itemList.category}
                  message={messages[itemList.category]}
                  list={itemList.items}
                  index={i}
                  selectedList={selectedData[itemList.category]}
                  toggleFromSelectedList={toggleFromSelectedList}
                  selectOne={switchSingleSelection}
                  addOther={addOther}
                  noOther={itemList.category === "none"}
                  noSuffix={itemList.category === "none"}
                />
              ))}
              <div
                style={{ flexGrow: "1", alignItems: "flex-end" }}
                className="d-flex justify-content-between"
              >
                <SubmitButton
                  onClick={skipTrackingStep}
                  style={{ height: "max-content" }}
                  type="button"
                >
                  Skip
              </SubmitButton>
                <SubmitButton
                  style={{ height: "max-content" }}
                  onClick={handleSubmit}
                >
                  Save
              </SubmitButton>
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default SDOHSelector;
