import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  matchPath,
} from "react-router-dom";
import { ThemeContext, PatientContext } from "./lib/main-context";
// bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

// componentsbrand
import { format } from 'date-fns';
import Dashboard from "./Components/Dashboard";
import HeaderWithBrand from "./Components/Header/HeaderWithBrand";
import PopBrand from "./Components/Header/PopBrand";
import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import PageNotFound from "./Components/PageNotFound";
import HelpPage from "./Components/HelpPage";
import MediaPage from "./Components/MediaPage";
import PatientControl from "./Components/DashboardComponents/PatientControl";

// Auth routes
import SignOut from "./Components/SignOut";
import SignIn from "./Components/SignIn/SignIn";
import ResetPassword1 from "./Components/SignIn/ResetPassword1";
import ResetPassword2 from "./Components/SignIn/ResetPassword2";
import AccountSetting from "./Components/AccountSetting/AccountSetting";

// Onboarding routes
import BasicInfo from "./Components/Onboarding/BasicInfo";
import PatientInfo from "./Components/Onboarding/PatientInfo";
import RecentTBI1 from "./Components/Onboarding/RecentTBI1";
import RecentTBI2 from "./Components/Onboarding/RecentTBI2";
import RecentStroke1 from "./Components/Onboarding/RecentStroke1";
import RecentStroke2 from "./Components/Onboarding/RecentStroke2";
import SelectLaterSymptoms from "./Components/Onboarding/SelectLaterSymptoms";
import SelectTopSymptoms from "./Components/Onboarding/SelectTopSymptoms";
import SelectTherapies from "./Components/Onboarding/SelectTherapies";
import Register from "./Components/Onboarding/Register";
import SelectSocialDeterminants from "./Components/Onboarding/SelectSocialDeterminants";
import SelectTopSDOH from "./Components/Onboarding/SelectTopSDOH";
import NotificationPrefs from "./Components/Onboarding/NotificationPrefs";
import StartTracking from "./Components/Onboarding/StartTracking";
import EmailSent from "./Components/Onboarding/EmailSent";
import EmailConfirm from "./Components/Onboarding/EmailConfirm";
import EmailConfirmForExistingProvider from "./Components/Onboarding/EmailConfirmForExistingProvider";

// auth
import {
  getMe,
  getPatient,
  getPatient2,
  getPatientList,
  getPatientList2,
} from "./api/auth";
import Warning from "./Components/DashboardComponents/Warning";
import Oops from "./Components/Oops";
import ExpandedChart from "./Components/ExpandedChart";
import VisionChart from "./Components/VisionChart";
import {
  getTrackedData,
  getTopFactors,
  getLastTrackedFeelingToday,
  getTBISymptoms,
  getAdjustments,
} from "./api/TBIRequests";
import Messages from "./Components/Messages";
import { getMessages } from "./api/messageRequests";

const App = () => {
  const history = useHistory();

  const [user, setUser] = useState(null);
  // When setPatient sets the first patient for a new user, use setPatient(<user data>[0]).
  // TODO otherwise we need a way to select which patient will have data displayed and thus is in patient state
  const [patient, setPatient] = useState(null);
  const [patientList, setPatientList] = useState(null);
  const [caregiverList, setCaregiverList] = useState(null);
  const [routeGroup, setRouteGroup] = useState(null);
  const [onboardingPercent, setOnboardingPercent] = useState(null);

  // used to allow the APIs to respond before loading the routes
  // on app load we get the user using a cookie
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  // Patients are gotten after the user is loaded or changes
  const [isLoadingPatient, setIsLoadingPatient] = useState(true);

  // return data for data tracked by user
  const [trackedData, setTrackedData] = useState(null);
  const [loadingTrackedData, setLoadingTrackedData] = useState(true);
  const [errorLoadingTrackedData, setErrorLoadingTrackedData] = useState(false);

  // symptoms user chose to track
  const [topSymptoms, setTopSymptoms] = useState(null);
  const [loadingTopSymptoms, setLoadingTopSymptoms] = useState(true);

  // 'how are you feeling today?' states
  const [doneSimpleTracking, setDoneSimpleTracking] = useState(false);
  const [loadingFeelingToday, setLoadingFeelingToday] = useState(true);

  // 'how are you feeling today?' states
  const [doneSimpleHabitTracking, setDoneSimpleHabitTracking] = useState(false);
  const [loadingHabitToday, setLoadingHabitToday] = useState(true);

  const [topSDOH, setTopSDOH] = useState(null);
  const [loadingTopSDOH, setLoadingTopSDOH] = useState(true);
  const [errorLoadingTopSDOH, setErrorLoadingTopSDOH] = useState(false);

  const [messages, setMessages] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false);

  //dark theme controller
  const [dark, setDark] = useState(false);

  const location = useLocation();

  const [adjustments, setAdjustments] = useState([]);

  useLayoutEffect(() => {
    const lastTheme = window.localStorage.getItem("POP-darkTheme");
    if (lastTheme === "true") {
      setDark(true);
      document.body.classList.add("dark-mode");
    } else {
      setDark(false);
    }
  }, [dark]);

  const toggleTheme = () => {
    if (dark) {
      document.body.classList.remove("dark-mode");
    } else {
      document.body.classList.add("dark-mode");
    }
    setDark(!dark);
    localStorage.setItem("POP-darkTheme", !dark);
  };

  // What we want is for user data to reloaded whenever you switch to an onboarding page
  // from a non-onboarding page or a dashboard page from a non-dashboard page
  // so you have to group strings for these routes in a JS object, with an "other" key
  // for pages like media and help where you don't need to reload user data

  // But this is much better done with pathname routes. Leave that as a TODO

  const routeGroups = {
    onboarding: [
      "/register",
      "/BasicInfo",
      "/RegisterPatient",
      "/Verification/EmailSent",
      "/Verification/EmailConfirm",
      "/RecentTBI1",
      "/RecentTBI2",
      "/RecentStroke1",
      "/RecentStroke2",
      "/selectTopSymptoms",
      "/selectSDOH",
      "/selectTopSDOH",
      "/selectTherapies",
      "/notificationPrefs",
      "/startTracking",
    ],
    laterSymptoms: ["/laterSymptoms"], //unique layout
    dashboard: ["/", "/charts/:symptom", "/patientControl"],
    other: [
      // "/laterSymptoms",
      "/warning",
      "/help",
      "/media",
      "/oops",
      "/signOut",
      "/signIn",
      "/resetPassword1",
      "/resetPassword2",
      "*",
    ],
  };

  useEffect(() => {
    const path = location.pathname;
    const newRoutegroup = Object.keys(routeGroups).find((key) => {
      // matchPath returns object, coerced to boolean for find method
      return matchPath(path, {
        path: routeGroups[key],
        exact: true,
      });
    });
    if (routeGroup !== newRoutegroup) setRouteGroup(newRoutegroup);
  }, [location]);

  // auto sign in user
  useEffect(() => {
    if (routeGroup === "other") {
      return;
    }
    setIsLoadingUser(true);
    getMe()
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        setIsLoadingPatient(false);
        if (err.response && err.response.status === 401) {
          //setting darkmode to false when user is not logged in
          document.body.classList.remove("dark-mode");
          localStorage.setItem("POP-darkTheme", false);
          setDark(false);
        } else {
          history.push("/oops");
        }
      })
      .finally(() => setIsLoadingUser(false));
  }, [routeGroup]);

  useEffect(() => {
    if (user) {
      setIsLoadingPatient(true);
      if (user.userType === "caregiver") {
        getPatientList()
          .then((res) => {
            setPatientList(res.data);
          })
          .catch(() => {
            history.push("/oops");
          });
      } else if (user.userType === "provider") {
        getPatientList()
          .then((res) => {
            if (res.data.length != 0) {
              setCaregiverList(res.data);
            }
          })
          .catch(() => {
            history.push("/oops");
          });

        getPatientList2()
          .then((res) => {
            if (res.data.length != 0) {
              setPatientList(res.data);
            }
          })
          .catch(() => {
            history.push("/oops");
          });
      }

      //   if(!patientList){
      //     getPatientList2()
      //     .then((res) => {
      //       console.log('res', res)
      //       if(res.data.length != 0){
      //         setPatientList(res.data);
      //       }
      //     })
      //     .catch(() => {
      //       history.push("/oops");
      //     });
      //     console.log('PatientList2',patientList);
      //   }
      // }

      // else if (user.userType === "provider") {
      //   getPatient2()
      //     .then((res) => {
      //       setPatient(res.data);
      //     })
      //     .catch(() => {
      //       history.push("/oops");
      //     });
      //   if (!patient) {
      //     getPatient()
      //       .then((res) => {
      //         setPatient(res.data);
      //       })
      //       .catch(() => {
      //         history.push("/oops");
      //       });
      //   }

      // }
      else {
        getPatient()
          .then((res) => {
            setPatient(res.data);
          })
          .catch(() => {
            history.push("/oops");
          });
      }
    }
    setIsLoadingPatient(false);
  }, [user]);

  // used to 'retry' within dashboard buttons if data fails to load
  const loadTrackedData = useCallback(() => {
    setLoadingTrackedData(true);

    getTrackedData(patient.patientId)
      .then(({ data }) => {
        setTrackedData(data);
        setLoadingTrackedData(false);
        setErrorLoadingTrackedData(false);
      })
      .catch(() => {
        setErrorLoadingTrackedData(true);
      });
  }, [patient]);
  // each time there is a new patient, we need to get new data
  useEffect(() => {
    if (patient) {
      loadTrackedData();
    }
  }, [patient, loadTrackedData]);

  const loadTopSymptoms = useCallback(() => {
    setLoadingTopSymptoms(true);
    if (patient) {
      getTopFactors(patient.patientId)
        .then(({ data }) => {
          setTopSymptoms(data);
          setLoadingTopSymptoms(false);
        })
        .catch(() => {
          history.push("/oops");
          setLoadingTopSymptoms(false);
        });
    }
  }, [history, patient]);

  // run once per patient change by default
  useEffect(() => {
    loadTopSymptoms();
  }, [patient, loadTopSymptoms]);

  useEffect(() => {
    if (patient) {
      // no catch because it's ok if we don't get this data
      // it'll just show the 'feeling today' again, which is fine.
      getLastTrackedFeelingToday(patient.patientId).then(({ data }) => {
        if (data.length === 0) {
          setDoneSimpleTracking(false);
          setLoadingFeelingToday(false);
          return;
        } else {
          const lastEntry = format(new Date(data.symptom_date), "MM-dd-yyyy");
          const today = format(new Date(), "MM-dd-yyyy");
          if (lastEntry === today) setDoneSimpleTracking(true);
          else setDoneSimpleTracking(false);
        }
        setLoadingFeelingToday(false);
      });
    }
  }, [patient]);

  // useEffect(() => {
  //   const fetchHabitData = async () => {
  //     if (patient) {
  //       try {
  //         const { data } = await getLastTrackedHabitToday(patient.patientId);
  //         if (data.length === 0) {
  //           setDoneSimpleHabitTracking(false);
  //         } else {
  //           const lastEntry = format(new Date(data.habits_tracked_date), "MM-dd-yyyy");
  //           const today = format(new Date(), "MM-dd-yyyy");
  //           console.log(lastEntry);
  //           console.log(today, "---------today-------");

  //           if (lastEntry === today) {
  //             console.log("setDoneSimpleHabitTracking(true)");
  //             setDoneSimpleHabitTracking(true);
  //           } else {
  //             console.log("setDoneSimpleHabitTracking(false)");
  //             setDoneSimpleHabitTracking(false);
  //           }
  //         }
  //       } catch (error) {
  //         // Handle error if needed
  //         console.error(error);
  //       } finally {
  //         setLoadingHabitToday(false);
  //       }
  //     }
  //   };

  //   fetchHabitData();
  // }, [doneSimpleHabitTracking, loadingHabitToday, patient]);

  useEffect(() => {
    const fetchAdjustments = async () => {
      if (patient) {
        try {
          const { data } = await getAdjustments(patient.patientId);
          setAdjustments(data);
        } catch (error) {
          console.error("Error fetching adjustments:", error);
        }
      }
    };

    fetchAdjustments();
  }, [patient]);

  const loadSDOH = useCallback(() => {
    setErrorLoadingTopSDOH(false);
    setLoadingTopSDOH(true);
    if (patient) {
      getTBISymptoms()
        .then(({ data }) => {
          setTopSDOH(data);
        })
        .catch(() => setErrorLoadingTopSDOH(true))
        .finally(() => setLoadingTopSDOH(false));
    }
  }, [patient]);

  useEffect(() => {
    loadSDOH();
  }, [patient, loadSDOH]);

  const loadMessages = useCallback(() => {
    setLoadingMessages(true);
    // if (user && user.userType !== "tbiPatient" && patient) {
    if (user && patient) {
      getMessages()
        .then((res) => {
          setMessages(res.data);
        })
        .catch((err) => {
          console.error(err);
          history.push("/oops");
        })
        .finally(() => {
          setLoadingMessages(false);
        });
    }
  }, [user, patient]);

  useEffect(() => {
    loadMessages();
  }, [user, patient]);

  const resetApp = () => {
    setUser(null);
    setPatient(null);
    setTrackedData(null);
    setErrorLoadingTrackedData(false);
    setTopSymptoms(null);
    setTopSDOH(null);
  };

  return (
    <Container style={{ marginBottom: "5rem" }}>
      <ThemeContext.Provider
        value={{
          dark,
          toggleTheme,
        }}
      >
        <PatientContext.Provider value={{ patient }}>
          <HeaderWithBrand
            user={user}
            onboardingPercent={onboardingPercent}
            routeGroup={routeGroup}
          />
          <Row className="justify-content-center">
            <Col
              xs={routeGroup === "onboarding" ? 10 : 12}
              md={routeGroup === "onboarding" ? 8 : 12}
            >
              {isLoadingUser ||
                (isLoadingPatient && (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ))}
              {!isLoadingUser && !isLoadingPatient && (
                <Switch>
                  <AuthenticatedRoute user={user} exact path="/">
                    <Dashboard
                      loadingTopSymptoms={loadingTopSymptoms}
                      topSymptoms={topSymptoms}
                      setTopSymptoms={setTopSymptoms}
                      trackedData={trackedData}
                      loadingTrackedData={loadingTrackedData}
                      errorLoadingTrackedData={errorLoadingTrackedData}
                      loadTrackedData={loadTrackedData}
                      user={user}
                      loadingFeelingToday={loadingFeelingToday}
                      doneSimpleTracking={doneSimpleTracking}
                      setDoneSimpleTracking={setDoneSimpleTracking}
                      loadingTopSDOH={loadingTopSDOH}
                      topSDOH={topSDOH}
                      errorLoadingTopSDOH={errorLoadingTopSDOH}
                      loadSDOH={loadSDOH}
                      messages={messages}
                      loadMessages={loadMessages}
                      loadingHabitToday={loadingHabitToday}
                      doneSimpleHabitTracking={doneSimpleHabitTracking}
                      setDoneSimpleHabitTracking={setDoneSimpleHabitTracking}
                      adjustments={adjustments}
                      setAdjustments={setAdjustments}
                    />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute exact path="/warning" user={user}>
                    <Warning user={user} />
                  </AuthenticatedRoute>

                  <Route exact path="/help">
                    <HelpPage />
                  </Route>

                  <Route exact path="/media">
                    <MediaPage />
                  </Route>

                  <Route exact path="/oops">
                    <Oops />
                  </Route>

                  <AuthenticatedRoute user={user} path="/charts/:symptom">
                    {loadingTopSymptoms || loadingTrackedData ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <ExpandedChart
                        topSymptoms={topSymptoms}
                        error={errorLoadingTrackedData}
                        retry={loadTrackedData}
                        data={trackedData}
                      />
                    )}
                  </AuthenticatedRoute>
                  {/* <AuthenticatedRoute user={user} path="/vision/:symptom">
                    {loadingTopSymptoms || loadingTrackedData ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                        <VisionChart
                          topSymptoms={topSymptoms}
                          error={errorLoadingTrackedData}
                          retry={loadTrackedData}
                          data={trackedData}
                        />
                      )}
                  </AuthenticatedRoute> */}

                  {/* May have to change this to plain Route if bugs arise */}
                  <AuthenticatedRoute user={user} path="/messages">
                    <Messages
                      user={user}
                      messages={messages}
                      setMessages={setMessages}
                      loadingMessages={loadingMessages}
                    />
                  </AuthenticatedRoute>

                  {/* Auth Routes--------- */}
                  <AuthenticatedRoute user={user} exact path="/signOut">
                    <SignOut resetApp={resetApp} />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute user={user} path="/accountSetting">
                    <AccountSetting />
                  </AuthenticatedRoute>

                  <Route exact path="/signIn">
                    <SignIn setUser={setUser} user={user} />
                  </Route>

                  <AuthenticatedRoute user={user} exact path="/patientControl">
                    <PatientControl
                      user={user}
                      patientList={patientList}
                      caregiverList={caregiverList}
                      setPatient={setPatient}
                    />
                  </AuthenticatedRoute>

                  <Route exact path="/resetPassword1">
                    <ResetPassword1 />
                  </Route>

                  <Route path="/resetPassword2">
                    <ResetPassword2 />
                  </Route>
                  {/* END AUTH */}

                  {/* ONBOARDING START */}
                  <Route exact path="/register">
                    <Register setOnboardingPercent={setOnboardingPercent} />
                  </Route>

                  <Route user={user} exact path="/BasicInfo">
                    <BasicInfo
                      user={user}
                      setPatient={setPatient}
                      setPatientList={setPatientList}
                      setUser={setUser}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </Route>

                  {/* Patient info now checks for user like AuthenticatedRoute but not for patient */}
                  <Route exact path="/RegisterPatient">
                    <PatientInfo
                      setPatientList={setPatientList}
                      setOnboardingPercent={setOnboardingPercent}
                      user={user}
                      setPatient={setPatient}
                    />
                  </Route>

                  <Route user={user} exact path="/Verification/EmailSent">
                    <EmailSent
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </Route>

                  <Route
                    user={user}
                    path="/Verification/EmailConfirmForExistingProvider"
                  >
                    <EmailConfirmForExistingProvider
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </Route>

                  <AuthenticatedRoute
                    user={user}
                    path="/Verification/EmailConfirm"
                  >
                    <EmailConfirm
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute user={user} exact path="/RecentStroke1">
                    <RecentStroke1
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute user={user} exact path="/RecentStroke2">
                    <RecentStroke2
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute user={user} exact path="/RecentTBI1">
                    <RecentTBI1
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute user={user} exact path="/RecentTBI2">
                    <RecentTBI2
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute user={user} exact path="/laterSymptoms">
                    <SelectLaterSymptoms
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    user={user}
                    exact
                    path="/selectTopSymptoms"
                  >
                    <SelectTopSymptoms
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute user={user} exact path="/selectSDOH">
                    <SelectSocialDeterminants
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute user={user} exact path="/selectTopSDOH">
                    <SelectTopSDOH
                      patient={patient}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute user={user} exact path="/selectTherapies">
                    <SelectTherapies
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute
                    user={user}
                    exact
                    path="/notificationPrefs"
                  >
                    <NotificationPrefs
                      user={user}
                      setOnboardingPercent={setOnboardingPercent}
                    />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute user={user} exact path="/startTracking">
                    <StartTracking setUser={setUser} user={user} />
                  </AuthenticatedRoute>
                  {/* END ONBOARDING   */}
                  {/* 404 */}
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              )}
            </Col>
          </Row>
          <PopBrand />
        </PatientContext.Provider>
      </ThemeContext.Provider>
    </Container>
  );
};

export default App;
