import apiUrl from "./apiConfig";
import axios from "axios";

export const registerUser = (data) => {
  return axios.post(`${apiUrl}/user/register`, data);
};

export const signIn = (data) => {
  return axios.post(`${apiUrl}/user/login`, data);
};

export const getMe = () => {
  return axios(`${apiUrl}/user/me`);
};

export const registerPatient = (data) => {
  return axios.post(`${apiUrl}/patient/register`, data);
};

export const signOut = () => {
  return axios.post(`${apiUrl}/user/logout`);
};

export const resetPassword = (data) => {
  return axios.put(`${apiUrl}/user/resetPassword`, data);
};

export const getTrackingPrefs = () => {
  return axios(`${apiUrl}/user/get-tracking-preferences`);
};

export const updateTrackingPrefs = (data) => {
  return axios.put(`${apiUrl}/user/update-tracking-preferences`, data);
};

export const getPatient = () => {
  return axios(`${apiUrl}/patient/get-patient`);
}
export const getPatient2 = () => {
  return axios(`${apiUrl}/patient/get-patient2`);
}

export const getPatientList = () => {
  return axios(`${apiUrl}/patient/get-patient-list`);
};
export const getPatientList2 = () => {
  return axios(`${apiUrl}/patient/get-patient-list2`);
};
