import React, { useContext, useState } from "react";
import { PatientContext } from "../../lib/main-context";
import { useHistory } from "react-router-dom";

import ComponentWrapper from "./ComponentWrapper";
import SubmitButton from "../StyledComponents/SubmitButton";

const CurrentPatient = ({ fillColumn }) => {
  const { patient } = useContext(PatientContext);
  const history = useHistory();

  return (
    <ComponentWrapper centerContents fillColumn={fillColumn}>
      <span>
        {"You are currently viewing "}
        <span style={{ fontSize: "1.75rem" }}>{patient && patient.firstName.toUpperCase()}</span>
            .
        </span>
      <SubmitButton
        onClick={() => history.push("/patientControl")}
        centered
      >
        Switch patient
          </SubmitButton>
    </ComponentWrapper>
  );
};

export default CurrentPatient;
