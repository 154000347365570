import React from "react";
import DayPicker from "react-day-picker";
import ComponentWrapper from "./ComponentWrapper";
import format from "date-fns/format";

const Calendar = ({ selectedDays, onDayClick, modifiers }) => {
  const modifiersStyles = {
    tracked: {
      color: "white",
      backgroundColor: "green",
    },
    untracked: {
      color: "white",
      backgroundColor: "red",
    },
  };
  const disableFutureDays = (day) => {
    const today = new Date();
    return format(new Date(day),"MM-dd-yyy") > format(new Date(),"MM-dd-yyy")
  };
  return (
    <ComponentWrapper>
      <DayPicker
        modifiers={modifiers}
        onDayClick={onDayClick}
        selectedDays={selectedDays}
        modifiersStyles={modifiersStyles}
        disabledDays={disableFutureDays}
      />
    </ComponentWrapper>
  );
};

export default Calendar;
