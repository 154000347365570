import React, { useContext, useState } from "react";
import { ThemeContext } from "../../lib/main-context";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import ComponentWrapper from "./ComponentWrapper";
import theme from "../../index.scss";
import BarChartOptions from "./BarChartOptions";
import SubmitButton from "../StyledComponents/SubmitButton";
import { parse } from "date-fns";
import ModalPdf from "./ModalPdf";

import sdohWithSetVal from "../../lib/sdohWithSetVal";
import fillInDates from "../../lib/fillInDates";
import getDaysAgo from "../../lib/getDaysAgo";

const options = BarChartOptions;

const BarChart = ({ topSymptoms, data, additionalNotes }) => {
  const [show, setShow] = useState(false);

  const history = useHistory();
  const { dark } = useContext(ThemeContext);

  const colors = [
    theme.chart1,
    theme.chart2,
    theme.chart3,
    theme.chart4,
    theme.chart5,
    theme.chart6,
    theme.chart7,
    theme.chart8,
    theme.chart9,
    theme.chart10,
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let datasetArr = [];

  topSymptoms.forEach((symptom) => {
    let dataset = data.filter(({ factor }) => factor === symptom.factor);
    datasetArr.push(dataset);
  });
  let sdohList = sdohWithSetVal(data);
  datasetArr.push(sdohList);

  const mapData = (dataset) => {
    return dataset
      .map((item) => {
        // const date = format(new Date(item.symptom_date), "MM-dd-yyyy");
        const date = item.symptom_date.slice(0, 10);
        return {
          // CURRENT BUG, Must remove time stamp or labels do not work correctly
          x: date,
          y: item.severity,
          desc: item.description,
        };
      })
      .filter(
        (item) => parse(item.x, "yyyy-MM-dd", new Date()) > getDaysAgo(3)
      );
  };

  const newData = {
    datasets: [],
  };
  let { datasets } = newData;

  datasetArr.forEach((dataset, index) => {
    if (index < datasetArr.length - 1) {
      datasets.push({
        label: topSymptoms[index].factor,
        backgroundColor: colors[index],
        hoverBackgroundColor: colors[index],
        data: mapData(dataset),
      });
    } else {
      datasets.push({
        label: "SDOH",
        data: mapData(dataset),
        pointStyle: "triangle",
        type: "scatter",
        backgroundColor: theme.primary,
        borderWidth: 1,
      });
    }
  });

  // datasets = averageDataPoints(datasets)

  datasets = fillInDates(datasets, 3);

  newData.notes = additionalNotes;

  const handleClick = (e) => {
    try {
      // set the selected symptom to the index of the clicked dataset
      // eslint-disable-next-line no-underscore-dangle
      const symptomIndex = e[0]._datasetIndex;
      // setSelectedSymptom(topSymptoms[symptomIndex])
      history.push(
        `/charts/${encodeURIComponent(topSymptoms[symptomIndex].factor)}`
      );
    } catch {
      // if there is an error, it's because the user did not click on
      // an element of the chart, no need to do anything.
    }
  };

  options.scales.xAxes[0].ticks = {
    fontColor: dark ? theme.darkModeText : "#666",
  };
  options.scales.yAxes[0].ticks = {
    beginAtZero: true,
    fontColor: dark ? theme.darkModeText : "#666",
  };

  let chartHeight = topSymptoms.length >= 6 ? 400 : 300;
  return (
    <>
      <ComponentWrapper>
        <div className="barChart">
          <Bar
            getDatasetAtEvent={handleClick}
            data={newData}
            width={100}
            height={chartHeight}
            options={{
              ...options,
              maintainAspectRatio: false,
              title: {
                display: true,
                text: "3 Day Review",
                fontFamily: "quicksand",
                fontStyle: "bold",
                fontColor: dark ? theme.darkModeText : "#666",
              },
              legend: {
                labels: {
                  fontColor: dark ? theme.darkModeText : "#666",
                  usePointStyle: true,
                  boxWidth: 8,
                },
              },
            }}
          />
        </div>
        <SubmitButton
          onClick={() => {
            if (dark) {
              alert("Please turn off dark mode to export the chart");
            } else {
              handleShow();
            }
          }}
        >
          Export as PDF
        </SubmitButton>
        <ModalPdf
          show={show}
          handleClose={handleClose}
          chart="barChart"
          data={data}
          additionalNotes={additionalNotes}
          days={3}
        />
      </ComponentWrapper>
    </>
  );
};

export default BarChart;
