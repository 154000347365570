/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from "react";
import { PatientContext } from "../lib/main-context";
import { Route, Redirect, useHistory } from "react-router-dom";

const AuthenticatedRoute = ({ user, children, ...rest }) => {
  const { patient } = useContext(PatientContext);
  const history = useHistory();
  useEffect(() => {
    // when no patient is selected, redirect to patientControl Page.
    // using Redirect component from react-router-dom is creating unlimited loop.
    if (user && user.userType === "caregiver" && !patient || user && user.userType === "provider" && !patient)
      history.push("/patientControl");
  }, []);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/signIn",
                state: { from: location },
              }}
            />
          );
        }
        // else if (user && user.userType === "caregiver" && !patient) {
        //   // This is reachable for caregivers who stopped onboarding at Step 1-B (RegisterPatient)
        //   // OR when they are auto-signed in
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/patientControl",
        //         state: { from: location },
        //       }}
        //     />
        //   );
        // }
        return children;
      }}
    />
  );
};

export default AuthenticatedRoute;
