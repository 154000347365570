const customizeMessages = ({ user, getPossibleMessages }) => {
  const pronouns = {
    he: {
      male: "he",
      female: "she",
      other: "they"
    },
    him: {
      male: "him",
      female: "her",
      other: "them"
    },
    his: {
      male: "his",
      female: "her",
      other: "their"
    },
  };
  const getPronoun = (malePronoun) => {
    return pronouns[malePronoun][user.gender];
  };
  const isOtherGender = user.gender === "other"
  const messages = getPossibleMessages({ getPronoun, isOtherGender })
  const getMessage = (stringName) => {
    if (user.userType != "Stroke" && user.userType != "tbiPatient" && user.userType !== "provider" && user.userType !== "caregiver") {
      return messages[stringName]["tbiPatient"];
    } else {
      return messages[stringName][user.userType];
    }
  }
  return getMessage;
};

export default customizeMessages;