import React, { useContext, useState, useEffect } from "react";
import { ThemeContext, PatientContext } from "../../lib/main-context";
import { Bar } from "react-chartjs-2";
import ComponentWrapper from "./ComponentWrapper";
import theme from "../../index.scss";
import LoadingSpinner from "../LoadingSpinner";
import { getHabitProgressData } from "../../api/TBIRequests"; // API request to fetch habit data
import { format } from "date-fns";

const HabitStackedChart = () => {
    const [habitData, setHabitData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { dark } = useContext(ThemeContext);
    const { patient } = useContext(PatientContext);

    useEffect(() => {
        if (patient) {
            // Fetch habit data from the API using patientId
            getHabitProgressData(patient.patientId)
                .then((response) => {
                    setHabitData(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching habit progress data:", error);
                    setError("Failed to load habit progress data. Please try again later.");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [patient]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <p className="text-danger">{error}</p>;
    }

    const colors = [theme.chart1, theme.chart2, theme.chart3];

    // Prepare data for chart
    const chartData = {
        labels: [], // Dates will go here
        datasets: [],
    };

    habitData.forEach((habit) => {
        const date = format(new Date(habit.habits_tracked_date), "do MMM");
        const habitIndex = chartData.labels.indexOf(date);

        if (habitIndex === -1) {
            chartData.labels.push(date);
        }

        const datasetIndex = chartData.datasets.findIndex(dataset => dataset.label === habit.habit_name);

        if (datasetIndex === -1) {
            chartData.datasets.push({
                label: habit.habit_name,
                backgroundColor: colors[chartData.datasets.length % colors.length],
                data: Array(chartData.labels.length).fill(0),
            });
        }

        chartData.datasets.forEach((dataset) => {
            if (dataset.label === habit.habit_name) {
                const index = chartData.labels.indexOf(date);
                dataset.data[index] = 1; // Set data point to 1 to indicate the habit was tracked
            }
        });
    });

    const chartOptions = {
        title: {
            display: true,
            text: "Habit Tracking Progress (Last 7 Days)",
            fontFamily: "quicksand",
            fontStyle: "bold",
            fontColor: dark ? theme.darkModeText : "#666",
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        offsetGridLines: true,
                    },
                    offset: true,
                    type: "category",
                    stacked: true,
                    ticks: {
                        fontColor: dark ? theme.darkModeText : "#666",
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1,
                        fontColor: dark ? theme.darkModeText : "#666",
                    },
                    stacked: true,
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (item, data) => {
                    let pointData = data.datasets[item.datasetIndex].data[item.index];
                    return pointData === 1 ? data.datasets[item.datasetIndex].label : '';
                },
            },
        },
        legend: {
            labels: {
                fontColor: dark ? theme.darkModeText : "#666",
            },
        },
    };

    return (
        <ComponentWrapper>
            <div className="barChart">
                <Bar
                    data={chartData}
                    height={300} // Increase the height of the chart here
                    options={{
                        ...chartOptions,
                        maintainAspectRatio: false,
                    }}
                />
            </div>
        </ComponentWrapper>
    );
};

export default HabitStackedChart;
