import React, { useState, useEffect, useContext } from "react";
import { ThemeContext, PatientContext } from "../../lib/main-context";
import { format, compareDesc, parse } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";

import Form from "react-bootstrap/Form";

import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";

import theme from "../../index.scss";
import { postTrackSymptom } from "../../api/TBIRequests";

const SymptomTracker = ({
  date,
  symptom,
  trackedData,
  skipTrackingStep,
  saveTrackingStep,
  loadTrackedData,
}) => {
  const history = useHistory();

  const [showNotes, setShowNotes] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showImprove, setShowImprove] = useState(false);
  const [showBigImprove, setShowBigImprove] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      severitySymptom: 0,
    },
  });
  const dataset = trackedData.filter((item) => item.factor === symptom.factor);

  let prevIntensity;
  // getting weird bug where sometimes dataset is an empty array
  if (dataset && dataset.length > 0) {
    for (let i = dataset.length - 1; i >= 0; i--) {
      let isPrevDate = compareDesc(
        new Date(dataset[i].symptom_date),
        new Date(date)
      );

      if (isPrevDate === 1 && !prevIntensity) {
        prevIntensity = dataset[i].severity;
      }
    }
  } else {
    prevIntensity = null;
  }

  useEffect(() => {
    if (prevIntensity) {
      let currIntensity = parseInt(watch("severitySymptom"), 10);
      setShowNotes(false);
      setShowWarning(false);
      setShowImprove(false);
      setShowBigImprove(false);
      if (
        currIntensity >= prevIntensity + 10 &&
        currIntensity < prevIntensity + 30
      ) {
        setShowNotes(true);
      } else if (currIntensity >= prevIntensity + 30) {
        setShowWarning(true);
      } else if (currIntensity + 30 <= prevIntensity && currIntensity !== 0) {
        setShowBigImprove(true);
      } else if (currIntensity + 10 <= prevIntensity && currIntensity !== 0) {
        setShowImprove(true);
      }
    }
  }, [prevIntensity, watch]);

  const onSubmit = (data) => {
    setSubmitting(true);
    if (
      (showNotes || showImprove || showBigImprove || showWarning) &&
      data.describeSymptom === ""
    ) {
      setError("describeSymptom", {
        type: "err",
        message: "Please enter a note",
      });
      setSubmitting(false);
      return;
    }

    const picked = {
      factor: symptom.factor,
      category: symptom.category,
      subcategory: symptom.subcategory,
    };
    const processedData = [
      {
        ...data,
        symptom: picked,
        datetimeOfSymptom: format(date, "yyyy-MM-dd"),
        hadSymptom: true,
      },
    ];

    postTrackSymptom({
      patientId: patient.patientId,
      data: processedData,
    })
      .then(() => {
        reset();
        saveTrackingStep();
        loadTrackedData();
      })
      .catch((err) => {
        history.push("/oops");
      });
  };

  if (!symptom) {
    return (
      <div>
        You will need to select symptoms to track in order to access the symptom
        tracker.{" "}
        <Link
          to={{
            pathname: "RecentTBI1",
            state: { fromPatientControl: false },
          }}
        >
          Click here to select symptoms
        </Link>
      </div>
    );
  }

  return (
    <>
      <h6 className="d-flex justify-content-between">
        <span>
          Track{" "}
          {patient.userPatientRelationshipType === "self"
            ? "your"
            : patient.firstName + "'s"}{" "}
          symptoms
        </span>
        <span>{format(date, "LLL d, yyyy")}</span>
      </h6>
      <div
        className="p-3 m-3"
        style={{
          borderRadius: "5px",
          boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.75)",
        }}
      >
        <div className="d-flex justify-content-center">
          <h6
            className="pt-2 pb-2 pl-3 pr-3"
            style={{
              width: "50%",
              borderRadius: "20px",
              backgroundColor: dark ? theme.darkModePrimary : theme.primary,
              color: dark ? theme.darkModeText : "white",
              textAlign: "center",
              overflowWrap: "break-word",
            }}
          >
            {symptom.factor}
          </h6>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Label style={{ width: "100%" }}>
            Intensity Level: {watch("severitySymptom")}
            <Controller
              as={Form.Control}
              type="range"
              name="severitySymptom"
              control={control}
            />
            <div
              style={{ fontSize: "10px" }}
              className="d-flex justify-content-between"
            >
              <span>No {symptom.factor}</span>
              <span style={{ textAlign: "right" }}>
                Absolute worst {symptom.factor}
              </span>
            </div>
          </Form.Label>
          {(showNotes || showImprove || showBigImprove || showWarning) && (
            <>
              {showWarning ? (
                <div
                  className="p-3"
                  style={{ border: "1px solid red", borderRadius: "5px" }}
                >
                  Since your last log, we noticed an increase in the severity of
                  your {symptom.factor} of at least 30%.{" "}
                  <Link
                    to={{
                      pathname: "/warning",
                      state: { symtom: symptom.factor },
                    }}
                  >
                    Learn more
                  </Link>
                </div>
              ) : showBigImprove ? (
                <div
                  className="p-3"
                  style={{ border: "1px solid blue", borderRadius: "5px" }}
                >
                  This is AWESOME! Tell me what you did to get even better?
                </div>
              ) : showImprove ? (
                <h6>This is great. Tell me what you did to get better.</h6>
              ) : (
                <h6>
                  We noticed a 10% increase since your last log. Please provide
                  a note:
                </h6>
              )}

              <LabelledInput
                required
                label="Notes and/or Questions"
                name="describeSymptom"
                errors={errors}
                inputRef={register}
                inputAs="textarea"
              />
            </>
          )}

          <Form.Row
            style={{ flexGrow: "1", alignItems: "flex-end" }}
            className="d-flex justify-content-between"
          >
            <SubmitButton
              onClick={() => {
                reset();
                skipTrackingStep();
              }}
              style={{ height: "max-content" }}
              type="button"
            >
              Skip
            </SubmitButton>
            <SubmitButton
              style={{ height: "max-content" }}
              type="submit"
              disabled={submitting}
            >
              Save
            </SubmitButton>
          </Form.Row>
        </Form>
      </div>
    </>
  );
};

export default SymptomTracker;
