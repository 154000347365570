import React, { useState, useContext, useEffect } from "react";
import { PatientContext } from "../../lib/main-context";

import { useHistory, Redirect, Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import Sallie from "../Sallie";
import PillButton from "../StyledComponents/PillButton";
import ComponentWrapper from "./ComponentWrapper";
import Check from "../../images/checkWithinCircle.svg";
import SubmitButton from "../StyledComponents/SubmitButton";
import SmLinkButton from "../StyledComponents/smLinkButton";

const PatientControl = ({ user, patientList, caregiverList, setPatient }) => {
  const history = useHistory();

  // const [selectedPatient, setSelectedPatient] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [noPatientError, setNoPatientError] = useState(false);

  useEffect(() => {
    if (!(user.userType == "caregiver" || user.userType == "provider")) history.push("/");
    // if (user.userType !== "caregiver") history.push("/");
  }, []);

  const handleSubmit = (patient) => {
    setSubmitting(true);
    if (!patient) {
      setNoPatientError(true);
    } else {
      setPatient(patient);
      history.push("/");
    }
    setSubmitting(false);
  };

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/signIn",
        }}
      />
    );
  }

  return (
    <div>
      <Sallie
        text={
          noPatientError
            ? "Please select a patient first."
            : "Hi! Which patient would you like to track?"
        }
      />
      <div className="w-75 p-3 mx-auto">
        <ComponentWrapper>
          <h6 className="d-block">
            {patientList && patientList.length === 0
              ? "You have no patient registered."
              : "Please select the patient you want to track for."}
          </h6>
          <h5>Patient List</h5>
          {!patientList ? (
            // <Spinner animation="border" role="status">
            //   <span className="sr-only">Loading...</span>
            // </Spinner>
            null
          ) : (
              patientList.map((patient, index) => (
                <PillButton
                  autoMargin
                  key={patient.patientId}
                  onClick={() => {
                    // setSelectedPatient(patient)
                    handleSubmit(patient);
                  }}
                  // onClick={() => setSelectedPatient(patient)}
                  variant={
                    patient === patient.patientId ? "info" : "secondary"
                  }
                >
                  {patient === patient.patientId ? (
                    <img alt="Checked" src={Check} />
                  ) : (
                      ""
                    )}
                  (
                  {patient.firstName},{patient.gender === "female"
                    ? " F"
                    : "male"
                      ? " M"
                      : " Other"}
                  {patient.externalId && `, ID: ${patient.externalId}`}
                  )
                </PillButton>
              ))
            )}
          {/* <h5>Caregiver's Patient List</h5> */}
          {!caregiverList ?
            (
              null
            ) : (
              caregiverList.map((patient, index) => (
                <PillButton
                  autoMargin
                  key={patient.patientId}
                  onClick={() => {
                    // setSelectedPatient(patient)
                    handleSubmit(patient);
                  }}
                  // onClick={() => setSelectedPatient(patient)}
                  variant={
                    patient === patient.patientId ? "info" : "secondary"
                  }
                >
                  {patient === patient.patientId ? (
                    <img alt="Checked" src={Check} />
                  ) : (
                      ""
                    )}
                  (
                  {patient.firstName},{patient.gender === "female"
                    ? " F"
                    : "male"
                      ? " M"
                      : " Other"}
                  {patient.externalId && `, ID: ${patient.externalId}`}
                  )
                </PillButton>
              ))
            )}
        </ComponentWrapper>
        {user.userType == "caregiver" ?
          <div>
            Would you like to add a new patient to the list? click{" "}
            <SmLinkButton>
              <Link
                to={{
                  pathname: "/RegisterPatient",
                  state: { fromPatientControl: true },
                }}
              >
                HERE
            </Link>
            </SmLinkButton>
          </div>
          : null
        }
      </div>
      {/* <SubmitButton
          disabled={submitting}
          onClick={handleSubmit}
          centered
      >
        Continue
      </SubmitButton> */}
    </div>
  );
};

export default PatientControl;
